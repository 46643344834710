import { useMemo } from "react";
import useUnpostedSessionsMatchPath from "./use_unposted_sessions_match_path";
import useUnpostedSessionsContext from "./use_unposted_sessions_context";
import { ROUTES_XLOGS } from "../../../constants/URLs";

type Props = {
  viewParamId: ReturnType<typeof useUnpostedSessionsMatchPath>["customId"];
  context: ReturnType<typeof useUnpostedSessionsContext>;
  dynapicProps?: {
    mySessions: any;
    decSessions: any;
    assistantSessions: any;
  };
};

const useUnpostedSessionsViewState = (props: Props) => {
  const { viewParamId, context } = props;
  return useMemo(() => {
    switch (viewParamId) {
      case "dec-sessions":
        return {
          state: context.decUnpostedSessionsViewState,
          dispatch: context.decUnpostedSessionsViewDispatch,
          route: ROUTES_XLOGS.unposted_sessions.decSessions,
          headerTitle: "DEC Unposted Sessions",
          dynamicProps: props.dynapicProps?.decSessions,
          apiQueryClientManager: context.decUnpostedSessionsApiQueryClientManager,
        };
      case "assistant-sessions":
        return {
          state: context.assistantsUnpostedSessionsViewState,
          dispatch: context.assistantsUnpostedSessionsViewDispatch,
          route: ROUTES_XLOGS.unposted_sessions.assistantSessions,
          headerTitle: "Assistant Unposted Sessions",
          dynamicProps: props.dynapicProps?.assistantSessions,
          apiQueryClientManager: context.assistantsUnpostedSessionsApiQueryClientManager,
        };
      default:
        return {
          state: context.myUnpostedSessionsViewState,
          dispatch: context.myUnpostedSessionsViewDispatch,
          route: ROUTES_XLOGS.unposted_sessions.mySessions,
          headerTitle: "My Unposted Sessions",
          dynamicProps: props.dynapicProps?.mySessions,
          apiQueryClientManager: context.myUnpostedSessionsApiQueryClientManager,
        };
    }
  }, [
    context.assistantsUnpostedSessionsApiQueryClientManager,
    context.assistantsUnpostedSessionsViewDispatch,
    context.assistantsUnpostedSessionsViewState,
    context.decUnpostedSessionsApiQueryClientManager,
    context.decUnpostedSessionsViewDispatch,
    context.decUnpostedSessionsViewState,
    context.myUnpostedSessionsApiQueryClientManager,
    context.myUnpostedSessionsViewDispatch,
    context.myUnpostedSessionsViewState,
    props.dynapicProps?.assistantSessions,
    props.dynapicProps?.decSessions,
    props.dynapicProps?.mySessions,
    viewParamId,
  ]);
};

export default useUnpostedSessionsViewState;
