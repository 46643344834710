import { useRef, useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";

/**
 * -- Core Singular Purpose --
 * To represent a distinct category and its value.
 *
 * -- Possible Optional Extensions to Add --
 * * Color
 * * Tooltip
 * * Description
 */
type PieChartDataItem = {
  category: string;
  value: number;
};

/**
 * -- Core Singular Purpose --
 * To present data in the form of categorized values (the PieChartDataItem[]).
 *
 * -- Possible Optional Extensions to Add --
 * * Legend
 * * Animation
 */
interface MSBPieChartProps {
  data: PieChartDataItem[];
  innerRadius?: number;
}

function MSBPieChart(props: Readonly<MSBPieChartProps>) {
  const { data } = props;
  const innerRadius = props.innerRadius ?? 0;
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!chartRef.current) return;

    const root = am5.Root.new(chartRef.current);
    root._licenseApplied(); // Hides watermark

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
      }),
    );

    if (innerRadius > 0) {
      chart.set("innerRadius", am5.percent(innerRadius));
    }

    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
      }),
    );

    series.data.setAll(data);

    return () => root.dispose();
  }, [data, innerRadius]);

  return <div ref={chartRef} style={{ width: "100%", height: "500px" }} />;
}

export default MSBPieChart;
