import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import React from "react";
import { msalInstance } from "./msalInstance";

/**
 * Provides the MSAL (Microsoft Authentication Library) context for authentication.
 * It wraps the entire application.
 * 
 * @param children - The child components to be rendered within the MSALProvider.
 * @returns The MSALProvider component.
 */
export const MSALProvider = ({ children }: { children: React.ReactElement }) => {
  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        {children}
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};
