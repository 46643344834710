import { useEffect, useMemo, useState } from "react";
import { API_STUDENTS } from "../../../../api/api";
import { selectClientID } from "../../../../context/slices/loggedInClientSlice";
import { selectStateInUS } from "../../../../context/slices/stateInUsSlice";
import { useXNGSelector } from "../../../../context/store";
import { SessionResponse } from "../../../../session-sdk";
import { StudentProfile } from "../../../../profile-sdk";

/**
 * Houses cached API requests associated with a student.
 *
 * Currently we only house one request cache, but it's here for extension if needed.
 */
interface NotatorStudentApiCache {
  studentProfile: StudentProfile;
}

export type StudentIndexToApiCacheRecord = Record<number, NotatorStudentApiCache>;

function useStudentAPICaches(props: {
  draftSession: SessionResponse;
}): StudentIndexToApiCacheRecord {
  const studentJournals = useMemo(
    () => props.draftSession.studentJournalList,
    [props.draftSession],
  );

  const clientID = useXNGSelector(selectClientID)!;
  const stateInUS = useXNGSelector(selectStateInUS);

  const [studentApiData, setStudentApiData] = useState<StudentIndexToApiCacheRecord>([]);

  useEffect(() => {
    populateStudentApiCaches();

    // TODO: Handle errors
    async function populateStudentApiCaches() {
      if (!studentJournals) return;
      if (Object.keys(studentApiData).length === 0) {
        setStudentApiData(getBlankRecord(props.draftSession));
      }

      const notatorStudentApiCache = await Promise.all(
        studentJournals.map(async (s) => {
          const studentProfile = await API_STUDENTS.v1StudentsIdGet(
            s.student!.id!,
            clientID,
            stateInUS,
          );

          const studentCache: NotatorStudentApiCache = {
            studentProfile,
          };

          return studentCache;
        }),
      );

      return setStudentApiData(notatorStudentApiCache);
    }
  }, [studentJournals]);

  return studentApiData;
}

function getBlankRecord(draftSession: SessionResponse): StudentIndexToApiCacheRecord {
  return (
    draftSession.studentJournalList?.map(
      () =>
        ({
          studentProfile: {},
        }) as NotatorStudentApiCache,
    ) ?? []
  );
}

export default useStudentAPICaches;
