import { MSBInputErrorWrapper, MSBSearchMultiselect } from "../../../../../fortitude";
import {
  DistrictAccessRequestRef,
  ServiceProviderRef,
  UserManagementCard,
} from "../../../../../profile-sdk";
import { GridColDef, GridRenderCellParams, GridTreeNodeWithRender } from "@mui/x-data-grid";
import msbMUIAutoCompleteFilterOptions from "../../../../../utils/msb_mui_auto_complete_filter_options";
import { MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { XLogsRoleStrings } from "../../../../../context/types/xlogsrole";
import statusRemap from "../utils/approvals_card_status_remap";
import useApiQueryGetServiceProviderTypesByDate from "../../../../../api/hooks/state_snapshots/use_api_query_get_service_provider_types_by_date";
import { xlogsRoleOptions } from "../../../../../utils/xlogs_role_mapper";

type Props = {
  serviceProviderApiOptionsManager: ReturnType<typeof useApiQueryGetServiceProviderTypesByDate>;
  districtsComponentsProps: {
    value: (
      params: GridRenderCellParams<UserManagementCard, any, any, GridTreeNodeWithRender>,
    ) => DistrictAccessRequestRef[];
    onChange: (
      params: GridRenderCellParams<UserManagementCard, any, any, GridTreeNodeWithRender>,
    ) => (newDistricts: DistrictAccessRequestRef[]) => void;
  };
  serviceProvidersComponentsProps: {
    value: (
      params: GridRenderCellParams<UserManagementCard, any, any, GridTreeNodeWithRender>,
    ) => ServiceProviderRef[];
    onChange: (
      params: GridRenderCellParams<UserManagementCard, any, any, GridTreeNodeWithRender>,
    ) => (newServiceProviders: ServiceProviderRef[]) => void;
  };
  xlogsRoleAssignedComponentsProps: {
    value: (
      params: GridRenderCellParams<UserManagementCard, any, any, GridTreeNodeWithRender>,
    ) => XLogsRoleStrings;
    onChange: (
      params: GridRenderCellParams<UserManagementCard, any, any, GridTreeNodeWithRender>,
    ) => (e: SelectChangeEvent<any>) => void;
  };
};

/**
 * Custom hook that defines the column grid definition for the user approvals table.
 *
 * @param {Props} props - The props object containing the necessary data and functions.
 * @returns {readonly GridColDef<UserManagementCard>[]} - The array of column definitions for the grid.
 */
const useUserApprovalsColumnGridDef = (props: Props) => {
  const {
    data: serviceProviderTypesOptionsResponse,
    isError: serviceProviderTypesOptionsIsError,
    isPending: serviceProviderTypesOptionsIsPending,
    refetch: refetchMutlipleServiceProviderTypesOptions,
  } = props.serviceProviderApiOptionsManager;

  const defaultColumnProps = {
    flex: 1,
    minWidth: 120,
  };

  const columns: readonly GridColDef<UserManagementCard>[] = [
    {
      ...defaultColumnProps,
      field: "student Name",
      valueGetter(params) {
        return params.row.user?.firstName + " " + params.row.user?.lastName;
      },
    },
    {
      ...defaultColumnProps,
      field: "Client",
      valueGetter(params) {
        return params.row.client?.name ?? "N/A";
      },
    },
    {
      ...defaultColumnProps,
      minWidth: 250,
      field: "District",
      sortable: false,
      renderCell(params) {
        return (
          <MSBSearchMultiselect
            selectedOptions={props.districtsComponentsProps.value(params)}
            options={params.row.districtAccessRequests || []}
            getOptionLabel={(option) => `${option?.district?.name}` || "N/A"}
            onChange={(newDistricts, e) => {
              e?.preventDefault();
              e?.stopPropagation();
              props.districtsComponentsProps.onChange(params)(newDistricts);
            }}
            renderOptionVariant="checkbox"
            variant="no overflow"
            isOptionEqualToValue={(option, value) => option?.district?.id === value?.district?.id}
            label={"Districts"}
            autocompleteProps={{
              disableCloseOnSelect: true,
              filterOptions: msbMUIAutoCompleteFilterOptions(),
              onClick: (e) => {
                e.stopPropagation();
                e.preventDefault();
              },
              size: "small",
              sx: {
                fontSize: "10px",
                my: "10px",
                flex: 1,
              },
            }}
            textFieldProps={{
              onClick: (e) => {
                e.stopPropagation();
                e.preventDefault();
              },
              size: "small",
              fullWidth: true,
            }}
            sx={{
              flex: 1,
              my: 1,
            }}
          />
        );
      },
    },
    {
      ...defaultColumnProps,
      minWidth: 100,
      field: "Status",
      sortable: false,
      renderCell(params) {
        return (
          <Typography fontWeight={600} color={statusRemap[params.row.status ?? 0].color}>
            {statusRemap[params.row.status ?? 0].label}
          </Typography>
        );
      },
    },
    {
      ...defaultColumnProps,
      field: "Plan Of Care",
      valueGetter(params) {
        return params.row.user?.email ?? "N/A";
      },
    },
    {
      ...defaultColumnProps,
      minWidth: 200,
      field: "Service Provider Type",
      sortable: false,
      renderCell(params) {
        return (
          <MSBInputErrorWrapper
            isError={serviceProviderTypesOptionsIsError}
            displayRefreshButton={serviceProviderTypesOptionsIsError}
            errorText={"Error fetching service provider types Options"}
            refetch={refetchMutlipleServiceProviderTypesOptions}
            rootProps={{
              width: "100%",
            }}
          >
            <MSBSearchMultiselect
              selectedOptions={props.serviceProvidersComponentsProps.value(params)}
              options={serviceProviderTypesOptionsResponse?.serviceProviderTypes || []}
              getOptionLabel={(option) => `${option?.name}`}
              onChange={(newServiceProviderTypes, e) => {
                e?.preventDefault();
                e?.stopPropagation();
                props.serviceProvidersComponentsProps.onChange(params)(newServiceProviderTypes);
              }}
              renderOptionVariant="checkbox"
              variant="no overflow"
              isOptionEqualToValue={(option, value) => option.id === value.id}
              label={(() => {
                if (serviceProviderTypesOptionsIsPending) {
                  return "Loading Service Provider Types Options...";
                }
                if (serviceProviderTypesOptionsIsError) {
                  return "Failed to load Service Provider Types Options";
                }
                return "Service Provider Type(s)";
              })()}
              disabled={serviceProviderTypesOptionsIsPending || serviceProviderTypesOptionsIsError}
              autocompleteProps={{
                disableCloseOnSelect: true,
                filterOptions: msbMUIAutoCompleteFilterOptions(),
                onClick: (e) => {
                  e.stopPropagation();
                  e.preventDefault();
                },
              }}
              textFieldProps={{
                onClick: (e) => {
                  e.stopPropagation();
                  e.preventDefault();
                },
                size: "small",
                fullWidth: true,
              }}
              sx={{
                flex: 1,
              }}
            />
          </MSBInputErrorWrapper>
        );
      },
    },
    {
      ...defaultColumnProps,
      minWidth: 200,
      sortable: false,
      field: "Xlogs Role Assigned",
      renderCell(params) {
        return (
          <Select
            size="small"
            fullWidth
            value={props.xlogsRoleAssignedComponentsProps.value(params)}
            onChange={(e) => {
              e.preventDefault();
              e.stopPropagation();
              props.xlogsRoleAssignedComponentsProps.onChange(params)(e);
            }}
          >
            {xlogsRoleOptions.map((role, i) => {
              return (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              );
            })}
          </Select>
        );
      },
    },
  ];

  return columns;
};

export default useUserApprovalsColumnGridDef;
