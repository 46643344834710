import { GridColDef } from "@mui/x-data-grid";
import { ProviderParticipationReport, RemittanceScheduleReport } from "@xng/reporting";
import { formatDate, getFormattedDateTime } from "./date_utils";
import {
//   PROVIDER_PARTICIPATION_GRID_LOOKUP,
  ProviderParticipationServiceCategoryHeaderName,
  ProviderParticipationServiceCategoryFieldName,
  ProviderParticipationRowType,
} from "./provider_participation_service_category_helper";
import { convertToCommaSeparatedValue } from "./convert_util";

export enum RemittanceDataFieldName {
  DISTRICT_NAME = "district name",
  RA_NUMBER = "ra number",
  RA_DATE = "ra date",
  CHECK_DATE = "checkdate",
  CHECK_AMOUNT = "check amount",
  INVOICE_AMOUNT = "invoice amount",
}

export enum RemittanceDataHeaderName {
  DISTRICT_NAME = "District Name",
  RA_NUMBER = "RA Number",
  RA_DATE = "RA Date",
  CHECK_DATE = "CheckDate",
  CHECK_AMOUNT = "Check Amount",
  INVOICE_AMOUNT = "Invoice Amount",
}

const REMITTANCE_GRID_LOOKUP: Record<RemittanceDataFieldName, RemittanceDataHeaderName> = {
  [RemittanceDataFieldName.DISTRICT_NAME]: RemittanceDataHeaderName.DISTRICT_NAME,
  [RemittanceDataFieldName.RA_NUMBER]: RemittanceDataHeaderName.RA_NUMBER,
  [RemittanceDataFieldName.RA_DATE]: RemittanceDataHeaderName.RA_DATE,
  [RemittanceDataFieldName.CHECK_DATE]: RemittanceDataHeaderName.CHECK_DATE,
  [RemittanceDataFieldName.CHECK_AMOUNT]: RemittanceDataHeaderName.CHECK_AMOUNT,
  [RemittanceDataFieldName.INVOICE_AMOUNT]: RemittanceDataHeaderName.INVOICE_AMOUNT,
};

interface RowType {
  id: number;
  [RemittanceDataFieldName.DISTRICT_NAME]: string;
  [RemittanceDataFieldName.RA_NUMBER]: number;
  [RemittanceDataFieldName.RA_DATE]: Date;
  [RemittanceDataFieldName.CHECK_DATE]: Date;
  [RemittanceDataFieldName.CHECK_AMOUNT]: string;
  [RemittanceDataFieldName.INVOICE_AMOUNT]: string;
}
interface ColumnSettings {
  width?: number;
  sortable: boolean;
  filterable: boolean;
  hideable: boolean;
  align: "center";
  disableColumnMenu: boolean;
}

class DataGridHelper {
  // remittance data grid
  private static instance: DataGridHelper;
  public remittanceDataCol: GridColDef[] = [];
  public remittanceDataRow: RowType[] = [];
  public providerParticipationServiceCategoryDataCol: GridColDef[] = [];
  public providerParticipationServiceCategoryDataRow: ProviderParticipationRowType[] = [];

  //
  private readonly columnSettings: ColumnSettings = {
    sortable: false,
    filterable: false,
    hideable: false,
    align: "center",
    disableColumnMenu: true,
  };
  private constructor() {}
  public static getInstance(): DataGridHelper {
    if (!DataGridHelper.instance) {
      DataGridHelper.instance = new DataGridHelper();
    }
    return DataGridHelper.instance;
  }

  public mountColumns(): DataGridHelper {
    const columns: GridColDef<any>[] = [
      {
        field: RemittanceDataFieldName.DISTRICT_NAME,
        headerName: REMITTANCE_GRID_LOOKUP[RemittanceDataFieldName.DISTRICT_NAME],
        ...this.columnSettings,
        width: 131,
      },
      {
        field: RemittanceDataFieldName.RA_NUMBER,
        headerName: REMITTANCE_GRID_LOOKUP[RemittanceDataFieldName.RA_NUMBER],
        ...this.columnSettings,
        headerClassName: "header",
        width: 131,
      },
      {
        field: RemittanceDataFieldName.RA_DATE,
        headerName: REMITTANCE_GRID_LOOKUP[RemittanceDataFieldName.RA_DATE],
        ...this.columnSettings,
        width: 250,
      },
      {
        field: RemittanceDataFieldName.CHECK_DATE,
        headerName: REMITTANCE_GRID_LOOKUP[RemittanceDataFieldName.CHECK_DATE],
        ...this.columnSettings,
        width: 250,
      },
      {
        field: RemittanceDataFieldName.CHECK_AMOUNT,
        headerName: REMITTANCE_GRID_LOOKUP[RemittanceDataFieldName.CHECK_AMOUNT],
        // description: 'This column has a value getter and is not sortable.',
        ...this.columnSettings,
        width: 142,
      },
      {
        field: RemittanceDataFieldName.INVOICE_AMOUNT,
        headerName: REMITTANCE_GRID_LOOKUP[RemittanceDataFieldName.INVOICE_AMOUNT],
        // description: 'This column has a value getter and is not sortable.',
        ...this.columnSettings,
        width: 141,
      },
    ];
    this.remittanceDataCol = columns;

    return this;
  }

  public sanitizeRows(remittanceData: RemittanceScheduleReport[],districtName:string): DataGridHelper {
 
    remittanceData = this.removeDuplicatesInRemittanceData(remittanceData);
 
    const rows: RowType[] = [];
    for (let i = 0; i < remittanceData.length; i++) {
      if (remittanceData[i]) {
        rows.push({
          id: i + 1,
          [RemittanceDataFieldName.DISTRICT_NAME]: districtName,
          [RemittanceDataFieldName.RA_NUMBER]: remittanceData[i].raNum as number,
          [RemittanceDataFieldName.RA_DATE]: getFormattedDateTime(
            remittanceData[i].raDate as unknown as string,
          ).date as unknown as Date,
          [RemittanceDataFieldName.CHECK_DATE]: getFormattedDateTime(
            remittanceData[i].checkDate as unknown as string,
          ).date as unknown as Date,
          [RemittanceDataFieldName.CHECK_AMOUNT]: `$${convertToCommaSeparatedValue(remittanceData[i].checkAmount as number)}` ,
          [RemittanceDataFieldName.INVOICE_AMOUNT]: `$${convertToCommaSeparatedValue(remittanceData[i].invoiceAmount as number)}` ,
        });
      }
    }
    this.remittanceDataRow = rows;
    // console.log(this.remittanceDataRow);
    return this;
  }

  public mountProviderParticipationServiceCategoryColumns(): DataGridHelper {
    const columns = [
      {
        field: ProviderParticipationServiceCategoryFieldName.SERVICETYPE as string,
        headerName: ProviderParticipationServiceCategoryHeaderName.SERVICETYPE,
        minWidth: 170,
      },
      {
        field: ProviderParticipationServiceCategoryFieldName.UNPOSTEDSESSIONS as string,
        headerName: ProviderParticipationServiceCategoryHeaderName.UNPOSTEDSESSIONS,
        minWidth: 90,
      },
      {
        field: ProviderParticipationServiceCategoryFieldName.POSTEDSESSIONS as string,
        headerName: ProviderParticipationServiceCategoryHeaderName.POSTEDSESSIONS,
        minWidth: 68,
      },
      {
        field:
          ProviderParticipationServiceCategoryFieldName.POSTEDSESSIONS_THIS_TIME_LAST_YEAR as string,
        headerName:
          ProviderParticipationServiceCategoryHeaderName.POSTEDSESSIONS_THIS_TIME_LAST_YEAR,
        minWidth: 301,
      },
      {
        field: ProviderParticipationServiceCategoryFieldName.PROVIDERSWITHOUTSESSIONS as string,
        headerName: ProviderParticipationServiceCategoryHeaderName.PROVIDERSWITHOUTSESSIONS,
        minWidth: 221,
      },
      {
        field: ProviderParticipationServiceCategoryFieldName.PROVIDERSWITHSESSIONS as string,
        headerName: ProviderParticipationServiceCategoryHeaderName.PROVIDERSWITHSESSIONS,
        minWidth: 193,
      },
      {
        field: ProviderParticipationServiceCategoryFieldName.VALUEOFUNPOSTEDSESSIONS as string,
        headerName: ProviderParticipationServiceCategoryHeaderName.VALUEOFUNPOSTEDSESSIONS,
        minWidth: 224,
      },
      {
        field: ProviderParticipationServiceCategoryFieldName.PAIDAMOUNT as string,
        headerName: ProviderParticipationServiceCategoryHeaderName.PAIDAMOUNT,
        minWidth: 117,
      },
    ];
    this.providerParticipationServiceCategoryDataCol = columns.map((item) => ({
      ...item,
      ...this.columnSettings,
    }));
    return this;
  }

  public sanitizeProviderParticipationServiceCategoryRows(
    participationServiceData: ProviderParticipationReport[],
  ): DataGridHelper { 
    participationServiceData = this.removeDuplicatesInProviderParticipationServiceCategoryReport(
      participationServiceData
    ) 
    const rows: ProviderParticipationRowType[] = [];
    for (let index = 0; index < participationServiceData.length; index++) {
      rows.push({
        id: index + 100,
        [ProviderParticipationServiceCategoryFieldName.SERVICETYPE]: participationServiceData[index]
          .serviceType as string,
        [ProviderParticipationServiceCategoryFieldName.UNPOSTEDSESSIONS]: 
        convertToCommaSeparatedValue( participationServiceData[index].unpostedSessions as number),
        [ProviderParticipationServiceCategoryFieldName.POSTEDSESSIONS]: 
        convertToCommaSeparatedValue(participationServiceData[index].postedSessions as number) ,
        [ProviderParticipationServiceCategoryFieldName.POSTEDSESSIONS_THIS_TIME_LAST_YEAR]:
        convertToCommaSeparatedValue(participationServiceData[index].postedSessionsThisTimeLastYear as number) ,
        [ProviderParticipationServiceCategoryFieldName.PROVIDERSWITHOUTSESSIONS]:
        convertToCommaSeparatedValue(participationServiceData[index].providersWithoutSessions as number) ,
        [ProviderParticipationServiceCategoryFieldName.PROVIDERSWITHSESSIONS]:
        convertToCommaSeparatedValue(participationServiceData[index].providersWithSessions as number),
        [ProviderParticipationServiceCategoryFieldName.VALUEOFUNPOSTEDSESSIONS]:
        convertToCommaSeparatedValue(participationServiceData[index].valueOfUnpostedSessions as number),
        [ProviderParticipationServiceCategoryFieldName.PAIDAMOUNT]: `$${ convertToCommaSeparatedValue(participationServiceData[index]?.paidAmount as number)}`   ,
      });
    }

    this.providerParticipationServiceCategoryDataRow = rows; 
    return this;
  }

  // remove duplications

  public removeDuplicatesInProviderParticipationServiceCategoryReport(data: ProviderParticipationReport[]) {
      return data.filter((v, i, a) => a.findIndex(t => (t.serviceType === v.serviceType)) === i);
  }

  public removeDuplicatesInRemittanceData(data: RemittanceScheduleReport[]) {
    return data.filter((v, i, a) => a.findIndex(t => (t.raNum === v.raNum)) === i);
  }
}

export const dataGridHelper = DataGridHelper.getInstance();

 
