 
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
  } from "chart.js";
import { chartDataTransformationManager } from '../../../utils/chart_helper';
import useFinancialDashboard from '../../../hooks/useFinancialDashboard';
import SkeletonLoading from '../../skeletonLoading/SkeletonLoading';
import classes from './index.module.css'
import { Box, Typography } from '@mui/material';
import { ProgressBar } from '../../progressBar';
import { convertToPercentage } from '../../../utils/conversion';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  );
 
const ReimbursmentGoalTrackingWidget = () => {
  const data = useFinancialDashboard()?.state?.data?.reimbursementGoalReports
  const isLoading = useFinancialDashboard()?.state?.loading
  const SCALE = 12000;
  const options = chartDataTransformationManager.getBarChartOptions(SCALE,false,true)   
  return (
    <Box className={classes.reimbursementGoalTrackerRoot}>
      <Typography sx={{ fontWeight: "bold", fontSize: "20px",textAlign:"center" }}>
            Reimbursement Goal Tracking
      </Typography>
      <Box className={classes.chartWrapper}>
          {  data && !isLoading ? 
              <Box>
                  <Typography sx={{ fontWeight: "bold", fontSize: "20px",textAlign:"center" }}>
                      This Year
                  </Typography>
                  <Box className={classes.chartContainer}>
                    <Bar options={options} data={chartDataTransformationManager.transformDataForReimbursementReportForGoalTrackingThisYear(data)} />
                  </Box>
              </Box>
          :<SkeletonLoading height='250px' width='240px' />
        }
        {  data && !isLoading ? 
              <Box>
                  <Typography sx={{ fontWeight: "bold", fontSize: "20px",textAlign:"center" }}>
                      Last Year
                  </Typography>
                  <Box className={classes.chartContainer}>
                    <Bar options={options} data={chartDataTransformationManager.transformDataForReimbursementReportForGoalTrackingLastYear(data)} />
                  </Box>
              </Box>
          :<SkeletonLoading height='250px' width='240px' />
        }
      </Box>
{  data && !isLoading ? (
     <Box className={classes.progressBarWrapper}>
           <ProgressBar value={convertToPercentage(chartDataTransformationManager?.cumilativeReimbursementDataReports?.percentOfGoalComplete as number)?.toFixed(2) as unknown as number} label="Goal Achieved" />
           <ProgressBar value={(convertToPercentage(chartDataTransformationManager?.cumilativeReimbursementDataReports?.percentOfYearComplete as number)?.toFixed(2)) as unknown as number} label="Year Complete" />
       </Box>):
         <Box className={classes.progressBarWrapper}>
            <SkeletonLoading />
            <SkeletonLoading />
          </Box>
       }
    </Box>
  );
};

export default ReimbursmentGoalTrackingWidget;
