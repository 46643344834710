import useApiMutateData from '../use_api_mutate_data';
import XNGApiMutateParamObject from '../../../types/xng_api_mutate_param_object';
import { API_CLIENTS } from '../../api';
import { UpdateClientRequest } from '../../../profile-sdk';

type Data = Awaited<ReturnType<typeof API_CLIENTS.v1ClientsIdPatch>>; // generic parameter T is used to define data the type of the useApiMutateData hook
type Body = UpdateClientRequest;  // generic parameter B is used to define body the type of the useApiMutateData hook
type QueryParams = {
  id: string;
  state: string;
}

const useClientsIdPatch = (paramObject: XNGApiMutateParamObject<QueryParams, Data, Body>) => {
    const { id,  state } = paramObject.queryParams;
    return useApiMutateData({
        mutationFn: async (body: Body) =>
          await API_CLIENTS.v1ClientsIdPatch(id, state, body),
        mutationKey: ["v1ClientsIdPatch", id, state],
        ...(paramObject.options ?? {})
      });
};

export default useClientsIdPatch;