import { Paper, Divider, Box, Avatar, Typography } from "@mui/material";
import React from "react";
import { motion } from "framer-motion";
import { UserManagementStatus, HistoricalStatus } from "../../../../../../profile-sdk";
import statusRemap from "../../utils/approvals_card_status_remap";

type Props = {
  useCheckBoxSection?: React.ReactNode;
  useAvatarNameSection?: {
    firstName: string | undefined;
    lastName: string | undefined;
  };
  useClientDistrictStatusSection?: {
    clientName: string | undefined;
    status: UserManagementStatus | undefined;
  };
  useDistrictsSection?: React.ReactNode;
  useEmailSection?: {
    email: string | undefined;
  };
  useServiceProviderTypeSection?: React.ReactNode;
  useXlogsRoleDropDownSection?: React.ReactNode;
  useHistory?: {
    historicalStatuses: HistoricalStatus[] | undefined;
    initialRegisterationDate: Date | undefined;
    viewHistory?: boolean;
  };
  onCardClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const UserApprovalsCardShape = (props: Props) => {
  const partitionProps = {
    width: "50%",
    maxWidth: "50%",
    overflow: "hidden",
  };
  // SECTIONS FOR THE CARD
  const checkboxSection = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        transform: "translateX(10px)",
      }}
    >
      {props?.useCheckBoxSection}
    </Box>
  );

  const avatarNameSection = (
    <Box display={"flex"} alignItems={"center"} justifyContent={"flex-start"} gap={2}>
      <Avatar sx={{ bgcolor: "primary.main", width: 75, height: 75 }}>
        {(props.useAvatarNameSection?.firstName?.[0] ?? "N/A") +
          props.useAvatarNameSection?.lastName?.[0] || ""}
      </Avatar>
      <Typography variant="h5" color={"primary.main"}>
        {props.useAvatarNameSection?.firstName} {props.useAvatarNameSection?.lastName}
      </Typography>
    </Box>
  );

  const clientDistrictStatusSection = (
    <Box
      sx={{
        display: "flex",
        gap: 1,
      }}
    >
      <Box {...partitionProps}>
        <CardSectionTitle title={"Client"} />
        <Typography sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
          {props.useClientDistrictStatusSection?.clientName}
        </Typography>
      </Box>

      <Box {...partitionProps}>
        <CardSectionTitle title={"Status"} />
        <Typography
          fontWeight={600}
          color={statusRemap[props.useClientDistrictStatusSection?.status ?? 0].color}
        >
          {statusRemap[props.useClientDistrictStatusSection?.status ?? 0].label}
        </Typography>
      </Box>
    </Box>
  );

  const districtsSection = (
    <Box position={"relative"}>
      <CardSectionTitle title={"District(s)"} />
      {props.useDistrictsSection}
    </Box>
  );

  const emailSection = (
    <Box>
      <CardSectionTitle title={"Email"} />
      <Typography sx={{ wordWrap: "break-word" }}>{props.useEmailSection?.email}</Typography>
    </Box>
  );

  const serviceProviderTypeSection = (
    <Box>
      <CardSectionTitle title={"Service Provider Type(s)"} />
      {props.useServiceProviderTypeSection}
    </Box>
  );

  const xlogsRoleDropDownSection = (
    <Box>
      <CardSectionTitle title={"X Logs Role Assigned"} />
      {props.useXlogsRoleDropDownSection}
    </Box>
  );

  const historicalStatusesSection = (
    <Box
      component={"ul"}
      sx={{ listStyle: "none", pl: 0, maxHeight: "220px", overflowY: "scroll" }}
    >
      {props.useHistory?.historicalStatuses?.map((status, i) => (
        <Box component={"li"} key={crypto.randomUUID()} sx={{ display: "flex", mb: "5px" }}>
          <Box width={"50%"} maxWidth={"50%"} overflow={"hidden"}>
            <CardSectionTitle title={"Status"} disableGutter />
            <Typography fontWeight={600} color={statusRemap[status.status ?? 0].color}>
              {statusRemap[status?.status ?? 0].label}
            </Typography>
          </Box>
          <Box width={"50%"} maxWidth={"50%"} overflow={"hidden"}>
            <CardSectionTitle
              title={`Date ${status.status === 1 ? "Approved" : "Denied"}`}
              disableGutter
            />
            <Typography>{new Date(status?.date || Date.now()).toLocaleDateString()}</Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );

  // VIEWS FOR THE CARD
  const mainCardView = (
    <>
      <Box>
        {props.useCheckBoxSection && checkboxSection}
        {props.useAvatarNameSection && avatarNameSection}
      </Box>
      {props.useClientDistrictStatusSection && clientDistrictStatusSection}
      {props.useEmailSection && emailSection}
      {props.useClientDistrictStatusSection && districtsSection}
      {serviceProviderTypeSection}
      <Divider sx={{ borderBottomWidth: 2 }} />
      {props.useXlogsRoleDropDownSection && xlogsRoleDropDownSection}
    </>
  );

  const historyCardView = (
    <>
      <Box>
        {props.useCheckBoxSection && checkboxSection}
        {props.useAvatarNameSection && avatarNameSection}
      </Box>
      {historicalStatusesSection}
      <Box>
        <CardSectionTitle title={"Initial Registration Date"} disableGutter />
        <Typography>
          {new Date(props.useHistory?.initialRegisterationDate || Date.now()).toLocaleDateString()}
        </Typography>
      </Box>
    </>
  );

  return (
    <Paper
      component={motion.div}
      sx={{
        maxWidth: 320,
        minWidth: 320,
        minHeight: 395,
        overflow: "hidden",
        pt: 1,
        pb: 4,
        px: 2.6,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        "&:hover": {
          cursor: "pointer",
          boxShadow: "0px 0px 8px rgba(0,0,0,0.2)",
        },
      }}
      variant="outlined"
      onClick={props.onCardClick}
      whileHover={{
        scale: 1.02,
        transition: {
          duration: 0.03,
        },
      }}
      whileTap={{
        scale: 0.98,
      }}
    >
      {!props.useHistory?.viewHistory ? mainCardView : historyCardView}
    </Paper>
  );
};

// Title for the card sections
const CardSectionTitle = (props: { title: string; disableGutter?: boolean }) => {
  return (
    <Typography fontWeight={700} color={"primary.main"} mb={props.disableGutter ? "1px" : "5px"}>
      {props.title}
    </Typography>
  );
};

export default UserApprovalsCardShape;
