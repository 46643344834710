import { MenuItem, MenuProps, Select, SelectProps, useTheme } from "@mui/material";
import { useEffect, useState } from "react";

const leftAlignedMenuProp: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
};

/**
 * Closed to modification. These are the props required for this component's minimum
 * functionality.
 *
 * If you need to modify, instead create a unique component local to your feature.
 */
interface MSBTypedSelectBaseProps<T> {
  options: T[];
  getDisplayValue: (v: T) => string;
  onChange: (v: T) => void;
}

/**
 * Open to extension. Try not to extend with props that can be provided via `selectProps`
 */
interface MSBTypedSelectOptionalProps<T> {
  selectProps?: SelectProps;
  label?: string;
  defaultOption?: T;
}

type MSBTypedSelectProps<T> = MSBTypedSelectBaseProps<T> & MSBTypedSelectOptionalProps<T>;

/**
 * Known issue: It seems that labels aren't rendering, at least on the notator's edit goal list.
 *
 * Fix this during next use.
 */
export default function MSBTypedSelect<T>(props: Readonly<MSBTypedSelectProps<T>>) {
  const defaultValueIfAny = props.defaultOption ? props.getDisplayValue(props.defaultOption) : null;
  const [selectedString, setSelectedString] = useState<string>(defaultValueIfAny ?? "");

  useEffect(() => {
    const typedSelectedOption = props.options.find(
      (o) => props.getDisplayValue(o) === selectedString,
    );
    if (typedSelectedOption) {
      props.onChange(typedSelectedOption);
    }
  }, [selectedString]);

  const optionsToStrings = props.options.map((typedOption) => {
    return props.getDisplayValue(typedOption);
  });

  const { palette } = useTheme();

  return (
    <Select
      fullWidth
      value={selectedString}
      onChange={(e) => setSelectedString(e.target.value as string)}
      inputProps={{ "aria-label": "Without label" }}
      size="small"
      sx={{ bgcolor: palette.background.default }}
      MenuProps={leftAlignedMenuProp}
      label={props.label ?? ""}
      {...props.selectProps}
    >
      {optionsToStrings.map((option, i) => (
        <MenuItem key={i} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
}

export function MSBTypedSelectControlled<T>(
  props: Readonly<{
    options: T[];
    value: T | "";
    getOptionLabel: (v: T) => string;
    onChange: (v: T) => void;
    selectProps?: SelectProps;
  }>,
) {
  const { options, value, getOptionLabel, onChange, selectProps } = props;

  const labelToValueMap = new Map<string, T>();
  options.forEach((option) => {
    const label = getOptionLabel(option);
    labelToValueMap.set(label, option);
  });

  return (
    <Select
      value={getOptionLabel(value as T) ?? ""} // Display label in the select
      onChange={(e) => {
        const selectedOption = labelToValueMap.get(e.target.value as string);
        if (selectedOption !== undefined) {
          onChange(selectedOption);
        }
      }}
      size="small"
      {...selectProps}
    >
      {options.map((option, index) => (
        <MenuItem key={index} value={getOptionLabel(option)}>
          {getOptionLabel(option)}
        </MenuItem>
      ))}
    </Select>
  );
}
