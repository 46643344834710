import { Box, Typography, Button, Chip } from "@mui/material";
import UnpostedSessionsFilter from "./unposted_sessions_filter";
import { MSBIconRenderer, MSBICONS } from "../../../../fortitude";
import useUnpostedSessionsViewState from "../../hooks/use_unposted_sessions_view_state";

type Props = {
  headerTitle: string;
  onBatchPostingButtonClick?: () => void;
  unpostedSessionsCount?: number;
  viewState: ReturnType<typeof useUnpostedSessionsViewState>;
  renderBackBtn?: boolean;
  onBackBtnClick?: () => void;
  disableFilterComponents?: boolean;
};

const UnpostedSessionsHeader = (props: Props) => {
  const { CaretOutline } = MSBICONS;

  return (
    <Box mb={3}>
      {props.renderBackBtn && (
        <Button
          startIcon={<MSBIconRenderer i={<CaretOutline />} size="xs" left color="white" />}
          onClick={props.onBackBtnClick}
        >
          Back To Session List
        </Button>
      )}
      <Box
        sx={{
          display: "flex",
          py: 2,
          mb: 1,
          justifyContent: {
            xs: "center",
            sm: "space-between",
          },
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          alignItems: {
            xs: "center",
            sm: "flex-start",
          },
          gap: {
            xs: 2,
            md: 0,
          },
          //   bgcolor: "wheat",
        }}
      >
        <Typography
          variant="h2"
          fontSize={"24px"}
          fontWeight={500}
          display={"flex"}
          alignItems={"center"}
          gap={1}
        >
          {props.headerTitle}
          <Chip
            label={props.unpostedSessionsCount ?? 0}
            size="small"
            sx={{
              backgroundColor: "error.main",
              color: "white",
              fontSize: "0.75rem",
              padding: "1px",
            }}
          />
        </Typography>
        <Button color="primary" onClick={props.onBatchPostingButtonClick}>
          Batch Posting
        </Button>
      </Box>
      <Box>
        <UnpostedSessionsFilter
          viewState={props.viewState}
          disable={props.disableFilterComponents}
        />
      </Box>
    </Box>
  );
};

export default UnpostedSessionsHeader;
