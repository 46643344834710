import { Box,  Modal, Typography } from "@mui/material";
import React from "react"; 
import { FaCheckCircle } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { PrescribingProviderActionButton } from "../../Buttons";

const styles = {
  thankYouText: {
    color: 'rgba(75, 75, 75, 1)',
    fontWeight: 500,
    fontSize: '14px',
    textAlign: 'center'
  },
  thankYouDescription: {
    color: 'rgba(117, 117, 117, 1)',
    fontSize: '12px',
    fontWeight: 500,
    width: '50%',
    textAlign: 'center'
  },
  closeBtn: {
    background: 'rgba(32, 106, 126, 1) !important',
    color: 'white',
    fontSize: '14px',
    fontWeight: 400
  },
  deleteSuccessModalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '20px',
    outline: 'none',
    border: 'none',
    position: 'relative'
  }  
};


const index: React.FC<{ isOpened: boolean; onClose: () => void }> = ({
  isOpened = false,
  onClose,
}) => {
  return (
    <Modal sx={{ display: "grid", placeContent: "center" }} open={isOpened} onClose={onClose}>
      <Box
        height={"256px"}
        // className={classes.deleteSuccessModalWrapper}
        sx={styles.deleteSuccessModalWrapper}
        width={"322px"}
        bgcolor={"rgba(255, 255, 255, 1)"}
      >
        <FaCheckCircle color="rgba(63, 166, 94, 1)" size={24} />
        <Typography sx={styles.thankYouText}>Thank You !</Typography>
        <Typography sx={styles.thankYouDescription}>
          The Provider(s) has been deleted from list.
        </Typography>
        <PrescribingProviderActionButton variant="contained" onClick={onClose} >
             Close
        </PrescribingProviderActionButton>

        <RxCross2 onClick={onClose} size={18} style={{ position: 'absolute', top: '20px',  right: '20px',  cursor: 'pointer'  }} />
      </Box>
    </Modal>
  );
};

export default index;
