import Box from "./BoxExtended";

/**
 * TODO: Move this to `/components`
 */
function ShowHideBox(props: { if: boolean; show: JSX.Element }): JSX.Element {
  return <Box sx={{ display: props.if ? "block" : "none" }}>{props.show}</Box>;
}

export default ShowHideBox;
