// usePrescribingProvider.ts
import { useContext } from "react";  
import PrescribingProviderContextType from "../types/prescribing_provider_context_types";
import PrescribingProviderContext from "../context/prescribing_provider_context";

const usePrescribingProvider = (): PrescribingProviderContextType => {
    const context = useContext(PrescribingProviderContext);
    if (context === undefined) {
        throw new Error("usePrescribingProvider must be used within a FinancialDashboardProvider");
    }
    return context;
};

export default usePrescribingProvider;
