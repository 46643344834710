import dayjs, { Dayjs } from "dayjs";
import { ColumnHeader } from "../../../../../hooks/use_datagrid_base";
import StudentPlanOfCareProgressCell, {
  StudentPlanOfCareProgress,
} from "../components/student_plan_of_care_progress_cell";
import { Avatar, Box, Chip, Tooltip, Typography, useTheme } from "@mui/material";
import {
  STUDENT_MANAGER_DATE_FORMAT,
  STUDENT_MANAGER_DATE_FORMAT_SHORT,
} from "./student_manager_date_format";
import { MSBIconRenderer } from "../../../../../fortitude";
import { PiWarningCircle } from "react-icons/pi";

const COL_WIDTH_SQUARE = 70; // The smallest column size, tries to have an approximate equal width and length.
const COL_WIDTH_SM = 100;
const COL_WIDTH_MD = 120;
const COL_WIDTH_LG = 200;

/**
 * Backing type for the Student Manager list view's DataGrid rows.
 *
 * Each field can be null, meaning the underlying data was undefined / null. We expect the DataGrid to
 * read these nulls and return the appropriate no-content cell for these instances
 */
export type ProviderCaseloadListRow = {
  isFailedImport: boolean;
  avatarInitials: string | null;
  xlogsStudentID: string | null;
  studentIdGivenBySchoolDistrict: string | null;
  firstName: string | null;
  lastName: string | null;
  dob: Dayjs | null;
  grade: string | null;
  school: string | null;
  planOfCareIfAny: string | null;
  planOfCareDurationIfAny: string | null;
  planOfCareProgressIfAny: StudentPlanOfCareProgress | null;
};

export const PROVIDER_STUDENT_CASELOAD_COLUMNS: ColumnHeader<ProviderCaseloadListRow>[] = [
  {
    key: "avatarInitials",
    title: "Profile",
    columnProps: {
      colSpan: (v) => ((v.row as ProviderCaseloadListRow).isFailedImport ? 10 : 1),
      renderCell: (v) => {
        const row: Readonly<ProviderCaseloadListRow> = v.row;
        if (row.isFailedImport) {
          return <FailedImportFullWidth student={row} />;
        }

        return <ListViewAvatar student={row} />;
      },
      minWidth: COL_WIDTH_SQUARE,
    },
  },
  {
    key: "studentIdGivenBySchoolDistrict",
    title: "Student ID",
    columnProps: {
      renderCell: (v) => {
        if (v.value) return v.value;
        return <NoContentCell />;
      },
      minWidth: COL_WIDTH_SM,
    },
  },
  {
    key: "firstName",
    title: "First Name",
    columnProps: {
      renderCell: (v) => {
        if (v.value) return v.value;
        return <NoContentCell />;
      },
      minWidth: COL_WIDTH_SM,
    },
  },
  {
    key: "lastName",
    title: "Last Name",
    columnProps: {
      renderCell: (v) => {
        if (v.value) return v.value;

        return <NoContentCell />;
      },
      minWidth: COL_WIDTH_SM,
    },
  },
  {
    key: "dob",
    title: "Date of Birth",
    columnProps: {
      renderCell: (v) => {
        if (v.value) return <>{dayjs(v.value).format(STUDENT_MANAGER_DATE_FORMAT)}</>;

        return <NoContentCell />;
      },
      sortComparator: (a, b) => dayjs(a).diff(dayjs(b)),
      minWidth: COL_WIDTH_MD,
    },
  },
  {
    key: "grade",
    title: "Grade",
    columnProps: {
      renderCell: (v) => {
        if (v.value) return v.value;
        return <NoContentCell />;
      },
      minWidth: COL_WIDTH_SQUARE,
    },
  },
  {
    key: "school",
    title: "School",
    columnProps: {
      minWidth: COL_WIDTH_LG,
      renderCell: (v) => {
        if (v.value) return v.value;
        return <NoContentCell />;
      },
    },
  },
  {
    key: "planOfCareIfAny",
    title: "Plan of Care",
    columnProps: {
      minWidth: COL_WIDTH_LG,
      colSpan: (v) => (v.value === null ? 3 : 1),
      renderCell: (v) => {
        if (v.value) return v.value;

        return (
          <CellTooltipChip tooltip="Click and add info under the 'Plan of Care' tab to visualize your student's progress!" />
        );
      },
    },
  },
  {
    key: "planOfCareDurationIfAny",
    title: "Duration",
    columnProps: {
      minWidth: COL_WIDTH_MD,
      colSpan: (v) => (v.value === null ? 2 : 1),
      renderCell: (v) => {
        if (v.value) return v.value;

        return (
          <CellTooltipChip tooltip="Click and add dates under the 'Plan of Care' tab to visualize your student's progress!" />
        );
      },
    },
  },
  {
    key: "planOfCareProgressIfAny",
    title: "Progress",
    columnProps: {
      minWidth: COL_WIDTH_MD,
      renderCell: (v) => {
        if (v.value) {
          return <StudentPlanOfCareProgressCell progress={v.value} />;
        }

        return <NoContentCell />;
      },
    },
  },
];

function CellTooltipChip(props: Readonly<{ tooltip: string; label?: string }>) {
  const label = props.label ?? "No data currently";

  return (
    <Tooltip
      title={props.tooltip}
      placement="top"
      arrow
      disableInteractive
      PopperProps={{ style: { textAlign: "center" } }}
    >
      <Chip size="small" label={label} sx={{ width: "100%" }} />
    </Tooltip>
  );
}

function NoContentCell() {
  const { palette } = useTheme();
  const disabledColor = palette.text.disabled;
  return (
    <Typography variant="inherit" fontStyle="italic" color={disabledColor}>
      None found
    </Typography>
  );
}

function FailedImportFullWidth(props: Readonly<{ student: ProviderCaseloadListRow }>) {
  const { palette } = useTheme();
  const error = palette.error.main;
  const { student } = props;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: ".5rem",
        height: "100%",
        width: "100%",
        cursor: "default",
      }}
    >
      <MSBIconRenderer i={<PiWarningCircle />} size="md" color={error} />
      <Typography variant="inherit" color={error}>
        We ran into an issue trying to get data for imported student:{" "}
        <strong>
          {student.firstName +
            " " +
            student.lastName +
            `, born ${dayjs(student.dob).format(STUDENT_MANAGER_DATE_FORMAT_SHORT)}` +
            `, Internal ID: ${student.studentIdGivenBySchoolDistrict}`}
        </strong>
        . Please reach out to MSB support so we can help.
      </Typography>
    </Box>
  );
}

function ListViewAvatar(props: Readonly<{ student: ProviderCaseloadListRow }>) {
  const { palette } = useTheme();
  const bgcolor = palette.primary.main;

  return (
    // TODO: Add tooltip
    <Tooltip title="View profile" placement="top" arrow disableInteractive>
      <Avatar sx={{ bgcolor, cursor: "pointer" }}>
        <Typography color={palette.getContrastText(bgcolor)}>
          {props.student.avatarInitials}
        </Typography>
      </Avatar>
    </Tooltip>
  );
}
