import dayjs, { Dayjs } from "dayjs";
import { ScheduleOfServicesInterval } from "../../../../../profile-sdk";
import { NonSchoolDaysContainer } from "../../../../../browser/non_school_days/district_non_school_days";
import isBetween from "dayjs/plugin/isBetween";
import getTotalSessionOccurrencesBetweenDates from "./get_total_session_occurrences_between_dates";
dayjs.extend(isBetween);

function getTotalScheduledMinutes(
  duration: number,
  startDate: Dayjs,
  endDate: Dayjs,
  interval: ScheduleOfServicesInterval,
  nonSchoolDays: NonSchoolDaysContainer,
) {
  // Scheduled minutes = duration * total occurrences
  const totalOccurrences = getTotalSessionOccurrencesBetweenDates(startDate, endDate, interval, nonSchoolDays);

  return duration * totalOccurrences;
}

export default getTotalScheduledMinutes;
