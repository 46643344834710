import useApiQueryData from "../use_api_query_data";
import { API_STATESNAPSHOTS } from "../../api";
import XNGApiQueryParamObject from "../../../types/xng_api_query_param_object";

type Data = Awaited<ReturnType<typeof API_STATESNAPSHOTS.v1StateSnapshotsByDateServiceAreasGet>>;
type QueryParams = {
  state: string;
  date?: Date;
  filterForMedicaidInfoOnly?: boolean,
};

const useApiQueryGetServiceAreasByDate = (paramObject:XNGApiQueryParamObject<QueryParams,Data>) => {
  const { state, date, filterForMedicaidInfoOnly } = paramObject.queryParams;
  return useApiQueryData(
    {
      queryFn: async () => await API_STATESNAPSHOTS.v1StateSnapshotsByDateServiceAreasGet(state, date, filterForMedicaidInfoOnly),
      queryKey: ["v1StateSnapshotsByDateServiceAreasGet", state, date, filterForMedicaidInfoOnly],
      staleTime: 1000 * 60 * 60, // 1 hours
      ...(paramObject.options ?? {})
    }
  );
};

export default useApiQueryGetServiceAreasByDate;
