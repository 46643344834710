export enum NotatorTab {
  "Attendance",
  "Session Times",
  "Activities",
  "Accommodations",
  "Modifications",
  "Goals/Objectives",
  "Observations",
}

export interface NotatorTabSelection {
  current: NotatorTab;
  previous: NotatorTab;
}

// ---- VIEWPORT BEHAVIOR ----

export type DictGoalObjectiveStateByStudentIndex = {
  [i: number]: boolean[];
};

export interface IViewportBehavior {
  goalObjectiveDropdownStateByStudentIndex: DictGoalObjectiveStateByStudentIndex;
}

export type FutureTabs = {
  section: number;
  include: boolean;
};
