import { AccountInfo, AuthenticationResult, EventMessage } from "@azure/msal-browser";
import { placeholderForFutureLogErrorText } from "../temp/errorText";
import { apiConfig } from "./authConfig"; // Assuming you have an apiConfig file with scopes
import { msalInstance } from "./msalInstance";

/**
 * Sets the active account and retrieves the access token for the specified account.
 * @param event - The event message containing the account information.
 * @returns An object containing the active account and the access token.
 * @throws An error if token acquisition fails.
 */
export async function setAccountAndGetTokens(event: EventMessage) {
  const account = (event.payload as any).account as AccountInfo;
  msalInstance.setActiveAccount(account);
  if (!account.idTokenClaims) throw new Error(placeholderForFutureLogErrorText);
  if (account.idTokenClaims.oid === undefined) throw new Error(placeholderForFutureLogErrorText);
  let msalAccount = account;

  // Request auth bearer token silently
  const tokenRequest = {
    scopes: apiConfig.scopes,
    account: msalAccount,
  };

  try {
    const authResult: AuthenticationResult = await msalInstance.acquireTokenSilent(tokenRequest);
    return {
      msalAccount,
      accessToken: authResult.accessToken,
    };
  } catch (error) {
    throw new Error("Token acquisition failed");
  }
}
