import { FormControl, FormLabel, Typography } from "@mui/material";

export function LabelValue(props: Readonly<{ value: string; label: string }>) {
  return (
    <FormControl sx={{ display: "flex", gap: ".5rem", flexDirection: "row" }}>
      <FormLabel>{props.label}</FormLabel>
      <Typography>
        <b>{props.value}</b>
      </Typography>
    </FormControl>
  );
}
