import { AccountInfo } from "@azure/msal-browser";
import { useEffect, useState } from "react";
import { API_CLIENTS, API_USERS, API_SERVICEPROVIDERS } from "../api/api";
import { setLoggedInClient } from "../context/slices/loggedInClientSlice";
import { setStateInUs } from "../context/slices/stateInUsSlice";
import { setUserResponse } from "../context/slices/userProfileSlice";
import { useXNGDispatch } from "../context/store";
import { placeholderForFutureLogErrorText } from "../temp/errorText";
import dayjs from "dayjs";
import { CreateUserRequest, UserResponse } from "../profile-sdk";
import { cookieExists, extractCookieValue } from "../utils/cookies";

/**
 * Custom hook that populates the Redux store with user data.
 *
 * @param account - The account information of the user.
 * @param setShowSignatoryVeiw - A function to set the visibility of the signatory view.
 * @returns An object containing the state variables `reduxPopulated`, `userInactive`, and `disable_flow`.
 */
export function usePopulateReduxStore(
  account: Readonly<AccountInfo>,
  setShowSignatoryVeiw: React.Dispatch<React.SetStateAction<boolean>>,
) {
  const [reduxPopulated, setReduxPopulated] = useState<boolean>(false);
  const [userInactive, setUserInactive] = useState(false);
  const [disable_flow, setDisable_flow] = useState<boolean>(false);
  const dispatch = useXNGDispatch();
  useEffect(() => {
    if (account === null) return;

    async function populateReduxStore(account: Readonly<AccountInfo>) {
      setReduxPopulated(false);
      // HANDLE POPULATE REDUX STORE

      if (account.idTokenClaims === undefined) throw new Error(placeholderForFutureLogErrorText);

      const _state = account.idTokenClaims.state as string;
      const _oid = account.idTokenClaims.oid;
      const _email = account.idTokenClaims.emails![0];
      const _given_name = account.idTokenClaims.given_name as string;
      const _family_name = account.idTokenClaims.family_name as string;
      const _extension_DoB = dayjs(account.idTokenClaims.extension_DoB as string).toDate();
      if (_oid === undefined) throw new Error(placeholderForFutureLogErrorText);

      // populate state in US
      dispatch(setStateInUs(_state));
      let userResponse: UserResponse | null = null;
      try {
        if (cookieExists("loggedInAsUserID")) {
          const providerID = extractCookieValue("loggedInUserProviderID");
          const clientID = extractCookieValue("loggedInUserClientID");
          userResponse = await API_USERS.v1UsersByServiceProviderGet(providerID, clientID, _state);
          // prevents users from getting stuck on the electronic signature onboarding view
          // until a better method for logging in as another user is implemented
          setDisable_flow(true);
          console.log("DISABLE FLOW");
        } else {
          userResponse = await API_USERS.v1UsersIdGet(_oid, _state, _email);
        }
      } catch {
        const newUser: CreateUserRequest = {
          objectId: _oid,
          firstName: _given_name,
          lastName: _family_name,
          email: _email,
          dateOfBirth: _extension_DoB,
        };
        userResponse = await API_USERS.v1UsersPost(_state, newUser);
      }
      // populate setUserResponse
      dispatch(setUserResponse(userResponse));

      if (userResponse.clientAssignments !== null) {
        if (userResponse.clientAssignments!.length > 0) {
          // set to last logged in or zeroth by default
          const authorizedClientIDs =
            userResponse.clientAssignments
              ?.filter((ca) => (ca.authorizedDistricts?.length ?? 0) > 0)
              .map((ca) => ca.client!.id) ?? [];
          const fallbackClientID =
            authorizedClientIDs[0] ?? userResponse.clientAssignments![0].client!.id;
          const loggedInClientID = userResponse.loggedInClientId ?? fallbackClientID;
          if (!loggedInClientID) throw new Error(placeholderForFutureLogErrorText);
          if (loggedInClientID === undefined) throw new Error(placeholderForFutureLogErrorText);
          const loggedInClient = await API_CLIENTS.v1ClientsIdGet(loggedInClientID, _state);

          // populate setLoggedInClient
          dispatch(setLoggedInClient(loggedInClient));

          const clientAssignment = userResponse?.clientAssignments?.find((ca) => {
            return ca.client?.id === loggedInClientID;
          });

          const serviceProvider = clientAssignment?.serviceProviderProfile;

          if (serviceProvider) {
            const serviceProviderProfile = await API_SERVICEPROVIDERS.v1ServiceProvidersIdGet(
              serviceProvider.id!,
              loggedInClientID,
              _state
            );

            if (serviceProviderProfile.clientAssignmentStatus === 1) {
              setUserInactive(true);
            }
          }

          if (
            userResponse.electronicSignatures &&
            (userResponse.electronicSignatures?.length < 3 ||
              !!userResponse.electronicSignatures.find((signature) => !signature.isSigned)) &&
            !!serviceProvider
          ) {
            setShowSignatoryVeiw(true);
          }
        }
      }
      // We thought that this state for Scheduler screen operation  would have to be cached on page load
      // const dropdownOptions: Service[] = [];
      // const ServicesByServiceProviderTypeResponse =
      //   API_STATESNAPSHOTS.v1StateSnapshotsByDateServicesByServiceProviderTypeGet(_state, )
      // dispatch(setServiceDropdownOptions(dropdownOptions));
      setReduxPopulated(true);
    }

    populateReduxStore(account);
  }, [account]);
  return { reduxPopulated, userInactive, disable_flow };
}