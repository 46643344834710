import React from "react";
import classes from "./class.module.css";
import { Box, Typography } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { BarChartDataFormat, chartDataTransformationManager } from "../../../utils/chart_helper";

import { CircularProgressbar } from "react-circular-progressbar";
import SkeletonLoading from "../../skeletonLoading/SkeletonLoading";
import useFinancialDashboard from "../../../hooks/useFinancialDashboard";
import { convertToPercentage } from "../../../utils/conversion";

const circularProgressBarStyles = {
  path: {
    stroke: "#2C5545",
    strokeWidth: 10,
  },
  trail: {
    stroke: "#EDEDED",
    strokeWidth: 10,
  },
  text: {
    fill: "#909090",
    transform: "translateX(-10px) translateY(4px)",
  },
};

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

const ReimbursementByService: React.FC = () => {
  const data = useFinancialDashboard()?.state?.data?.serviceAreaReimbursementReports;
  const isLoading = useFinancialDashboard()?.state?.loading;
  const SCALE = 12000;

  return (
    <Box
      style={{
        width: "100%",
        padding: "20px 0px 20px 22px",
        margin: "0px",
        overflow: "scroll",
        flexShrink: 0,
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: "bold", textAlign: "center", mb: 3 }}>
        Reimbursement by Service Area
      </Typography>
      <Box style={{ width: "1508px", height: "fit-content" }}>
        {!isLoading &&
        data &&
        chartDataTransformationManager.transformDataForServiceAreaReimbursementReport(data) &&
        chartDataTransformationManager.sanitizedReimbursementServiceData ? (
          <Bar
            height={50}
            width={300}
            options={chartDataTransformationManager.getBarChartOptionsForReimburementByServiceAreaWidget(
              SCALE,
            )}
            data={
              chartDataTransformationManager.sanitizedReimbursementServiceData as BarChartDataFormat
            }
          />
        ) : (
          <SkeletonLoading width="100%" height="300px" />
        )}
      </Box>

      {/* Doughnut Charts */}
      <Box className={classes.donut_Wrapper}>
        {!isLoading && data
          ? chartDataTransformationManager
              .removeDuplicatesInServiceAreaReimbursementReport(data)
              ?.map((item, index) => (
                <Box key={index} className={classes.donut_container}>
                  <Box className={classes.donut_header}>
                    {/* labels */}
                    <Typography
                      variant="caption"
                      sx={{ fontWeight: "bold", textAlign: "center", mb: 2 }}
                    >
                      {/* {Object.values(SERVICE_CATEGORTY_LOOKUP)[index]}   */}
                      {item.serviceCategory}
                    </Typography>
                  </Box>
                  <Box className={classes.donut_chart}>
                    <span className={classes.centered_element}>
                      {Math.abs(
                        convertToPercentage(item.progress as number).toFixed(
                          2,
                        ) as unknown as number,
                      )}
                      %
                    </span>
                    <CircularProgressbar
                      value={Math.abs(
                        convertToPercentage(item.progress as number) as unknown as number,
                      )}
                      strokeWidth={5}
                      styles={circularProgressBarStyles}
                    />
                    {/* {  <Doughnut options={{ cutout:"80%"   }} ref={doughnutRef} data={generateDoughnutChartData(item.serviceCategory)} />} } */}
                    <Typography className={classes.goal_Achieved}>goal achieved</Typography>
                  </Box>
                </Box>
              ))
          : Array.from({ length: 14 }).map((_, index) => (
              <Box key={index} className={classes.donut_container}>
                <Box className={classes.donut_header}>
                  {/* labels */}
                  <Typography
                    variant="caption"
                    sx={{ fontWeight: "bold", textAlign: "center", mb: 2 }}
                  >
                    {/* {Object.values(SERVICE_CATEGORTY_LOOKUP)[index]}   */}
                    <SkeletonLoading width="80px" height="30px" />
                  </Typography>
                </Box>
                <Box className={classes.donut_chart}>
                  <span className={classes.centered_element}>NA</span>
                  <CircularProgressbar
                    value={0}
                    strokeWidth={5}
                    styles={circularProgressBarStyles}
                  />
                  {/* {  <Doughnut options={{ cutout:"80%"   }} ref={doughnutRef} data={generateDoughnutChartData(item.serviceCategory)} />} } */}
                  <Typography className={classes.goal_Achieved}>goal achieved</Typography>
                </Box>
              </Box>
            ))}
      </Box>
    </Box>
  );
};

export default ReimbursementByService;
