import { PathMatch, useMatch } from "react-router";
import { ROUTES_XLOGS } from "../../../constants/URLs";

function useUnpostedSessionsMatchPath(): {
  customId: "my-sessions" | "dec-sessions" | "assistant-sessions" | "index" | "notator" | "none";
} & PathMatch<string> {
  const matchPathIndex = useMatch(ROUTES_XLOGS.unposted_sessions.index);
  const matchPathMySessions = useMatch(ROUTES_XLOGS.unposted_sessions.mySessions);
  const matchPathDecSessions = useMatch(ROUTES_XLOGS.unposted_sessions.decSessions);
  const matchPathAssistantsSessions = useMatch(ROUTES_XLOGS.unposted_sessions.assistantSessions);
  const matchPathNotator = useMatch(ROUTES_XLOGS.unposted_sessions.notator);

  if (matchPathMySessions) {
    return {
      customId: "my-sessions",
      ...matchPathMySessions,
    };
  }

  if (matchPathDecSessions) {
    return {
      customId: "dec-sessions",
      ...matchPathDecSessions,
    };
  }

  if (matchPathAssistantsSessions) {
    return {
      customId: "assistant-sessions",
      ...matchPathAssistantsSessions,
    };
  }

  if (matchPathIndex) {
    return {
      customId: "index",
      ...matchPathIndex,
    };
  }

  if (matchPathNotator) {
    return {
      customId: "notator",
      ...matchPathNotator,
    };
  }

  return {
    customId: "none",
    ...({} as PathMatch<string>),
  };
}

export default useUnpostedSessionsMatchPath;
