import { Stack, Typography } from "@mui/material";
import { useMemo } from "react";

export function ProviderCaseloadHeader(
  props: Readonly<{ totalFilteredStudents: number; totalStudentsInCaseload: number }>,
) {
  const { totalFilteredStudents, totalStudentsInCaseload } = props;

  const subheaderContent = useMemo(() => {
    if (totalFilteredStudents === totalStudentsInCaseload) {
      return <>Viewing all students in your caseload</>;
    }

    return (
      <>
        Viewing <b>{totalFilteredStudents}</b> out of {totalStudentsInCaseload} students in your
        caseload
      </>
    );
  }, [totalFilteredStudents, totalStudentsInCaseload]);

  return (
    <Stack>
      <Typography variant="h6">My Caseload</Typography>
      <Typography>{subheaderContent}</Typography>
    </Stack>
  );
}
