import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import XNGButton from "../../batch-post/components/common/xng-button";
import XNGClose from "../../../../design/low-level/button_close";
import { getSizing } from "../../../../design/sizing";
import { MSBIconRenderer, MSBICONS } from "../../../../fortitude";

interface DlgBatchPostAlertProps {
  open: boolean;
  onContinueBtnClick: () => void;
  onClose: () => void;
}

export default function DlgBatchPostAlert({ open, onClose, onContinueBtnClick }: Readonly<DlgBatchPostAlertProps>) {
  const { Information } = MSBICONS;
  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth={"xs"}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: getSizing(3),
          width: "100%",
          padding: "16px 24px 16px 24px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography />
          <XNGClose onClick={() => onClose()} size="modal" />
        </Box>
        <Box justifyContent={"center"} display={"flex"}>
          <MSBIconRenderer i={<Information />} size="lg" />
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: getSizing(1) }}
        >
          <Typography sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: 700 }}>
            Important Information
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              lineHeight: "20px",
              fontWeight: 400,
              width: "250px",
              textAlign: "center",
            }}
          >
            Batch posting pulls all sessions within the time frame identified that meet all the
            required posting criteria.
          </Typography>
        </Box>
        <XNGButton onClick={onContinueBtnClick}>
          Continue
        </XNGButton>
      </Box>
    </Dialog>
  );
}
