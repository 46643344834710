import { Box, Typography } from "@mui/material"
import classes from './index.module.css'
import { ProgressBarWithHover } from "../../progressBar"
import useFinancialDashboard from "../../../hooks/useFinancialDashboard"
import { Bar } from "react-chartjs-2"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { BarChartDataFormat, chartDataTransformationManager } from "../../../utils/chart_helper"
import SkeletonLoading from "../../skeletonLoading/SkeletonLoading"
import { convertToPercentage } from "../../../utils/conversion"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

 

const BillingSummaryWidget = () => {
  const data = useFinancialDashboard()?.state?.data?.fiveYearSummaryReports
  const isLoading = useFinancialDashboard()?.state?.loading 
  
  return (
    <Box className={classes.billingSummaryRoot}>
      <Typography sx={{ fontWeight: "bold", fontSize: "20px",textAlign:"center",paddingTop:"20px" }}>
      5 Year Billing Summary
      </Typography>
      <Box className={classes.graphContainer}>
              <Box sx={{width:"fit-content",display:"flex",flexDirection:"column"}}>
                      {
                        data && chartDataTransformationManager.removeDuplicatesInFiveYearSummaryReport(data).reverse().map((item,i)=>(
                        <ProgressBarWithHover 
                        year={item.year as number} 
                        earliestRNS={item.earliestRNS as Date} 
                        latestRNS={item.latestRNS as Date} earliest835={item.earliest835 as Date} latest835={item.latest835 as Date}
                         key={i} 
                        value={convertToPercentage(item?.percentComplete as number)    }  />))
                      } 
              </Box>
              {
                data && !isLoading &&chartDataTransformationManager.transformDataForFiveYearBillingSummary(data).sanitizedFiveYearBillingSummaryData ? 
                <Box sx={{width:"450px",height:"450px"}}>
                   <Bar data={chartDataTransformationManager.sanitizedFiveYearBillingSummaryData as BarChartDataFormat} options={chartDataTransformationManager.getBarChartOptionsForFiveYearBillingSummary(100000)} />
               </Box>
                : <SkeletonLoading width='450px' height='450px' />
              }

      </Box>
    </Box>
  )
}

export default BillingSummaryWidget