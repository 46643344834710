import { API_USERS } from "../../../../../../api/api";
import useApiMutateData from "../../../../../../api/hooks/use_api_mutate_data";
import { RespondToManyRequestsForDistrictAccessRequest } from "../../../../../../profile-sdk";
import XNGApiMutateParamObject from "../../../../../../types/xng_api_mutate_param_object";

type Data = Awaited<ReturnType<typeof API_USERS.v1UsersRequestAccessToDistrictRespondManyPost>>; // generic parameter T is used to define data the type of the useApiMutateData hook
type Body = RespondToManyRequestsForDistrictAccessRequest; // generic parameter B is used to define body the type of the useApiMutateData hook
type QueryParams = {
  state: string;
};

const useApiMutatePatchUsersRequestingAccessToDistrict = (
  paramObject: XNGApiMutateParamObject<QueryParams, Data, Body>,
) => {
  const { state } = paramObject.queryParams;

  return useApiMutateData({
    mutationFn: async (body: Body) =>
      await API_USERS.v1UsersRequestAccessToDistrictRespondManyPost(state, body),
    mutationKey: ["v1UsersRequestAccessToDistrictRespondManyPost", state],
    ...(paramObject.options ?? {}),
  });
};

export default useApiMutatePatchUsersRequestingAccessToDistrict;
