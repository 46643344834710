import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, useXNGSelector } from "../store";
import {
  ServiceProviderRef,
  ServiceProviderResponse,
  ServiceProviderType,
} from "../../profile-sdk";
import { selectLoggedInClientAssignment } from "./userProfileSlice";

type DataEntryProvider = ServiceProviderResponse | null;

/**
 * NOTE: We used to, at times, refer to the proxied user as the 'acting service provider'.
 * The team doesn't use this terminology anymore, so reflecting 'proxied' terminology in
 * the code instead is recommended.
 *
 * Reference updated definitions here in our Common Data Points article:
 * https://msb-tlm.atlassian.net/wiki/spaces/XL/pages/edit-v2/190906371
 */
export const dataEntryProviderSlice = createSlice({
  name: "data entry provider",
  initialState: null as DataEntryProvider,
  reducers: {
    setDataEntryProvider: (state, action: PayloadAction<DataEntryProvider>) => {
      return action.payload;
    },
  },
});

// SELECTORS, used to read state
export const selectDataEntryProvider = (state: RootState) => {
  return state.dataEntryProvider;
};

export const selectUserIsCurrentlyProxying = (state: RootState) => {
  return Boolean(state.dataEntryProvider);
};

// TODO: Phase out
export const selectUserIsSignedInAsDEP = (state: RootState) => {
  return Boolean(state.dataEntryProvider);
};

export function selectActingServiceProvider(state: RootState): ServiceProviderRef | undefined {
  const clientAssignment = selectLoggedInClientAssignment(state);

  const actingProvider = state.dataEntryProvider ?? clientAssignment.serviceProviderProfile;

  return actingProvider;
}

export function selectActingServiceProviderType(state: RootState): ServiceProviderType[] {
  const serviceProviderType =
  state.dataEntryProvider?.serviceProviderTypes ?? state.userResponse?.serviceProviderTypes;
  return serviceProviderType || [];
}
// ACTIONS, used to execute logic
export const { setDataEntryProvider } = dataEntryProviderSlice.actions;

export default dataEntryProviderSlice.reducer;
