import { useNavigate } from "react-router";
import {
  CaseloadStudentDisplay,
  PlanOfCare,
  SchoolCampusAssignment,
  StudentRef,
} from "../../../../../profile-sdk";
import usePalette from "../../../../../hooks/usePalette";
import { FadeIn, XNGAvatar, XNGIconRenderer, XNGICONS } from "../../../../../design";
import { Avatar, Box, ButtonBase, Typography } from "@mui/material";
import XNGCard from "../../../../../design/low-level/card";
import { getSizing } from "../../../../../design/sizing";
import dayjs from "dayjs";
import { studentProfileGradeToString } from "../../../../../utils/add_student_grade_suffix";
import getProgressPercentage from "../../../utils/get_progress_percentage";
import XNGProgress from "../../../../../design/low-level/progress";

const DATE_FORMAT = "MM/DD/YYYY";

/**
 * TODO: Not easily reusable. When we need to reuse this student card, instead create a new component
 * that **doesn't** rely on domain models for flexibility. Also separate error cards components from
 * regular card components.
 */
function StudentCard(
  props: Readonly<{
    s?: Readonly<CaseloadStudentDisplay>;
    animIndex: number;
    errorStudent?: StudentRef;
    errorMessage?: string;
  }>,
) {
  const s = props.s;
  const palette = usePalette();

  // TODO: Investigate why this was directly muting readonly data
  // if (s && s.activeSchoolCampuses === null) {
  //   s.activeSchoolCampuses = [] as SchoolCampusAssignment[];
  // }
  // if (s && s.activePlanOfCare === null) {
  //   s.activePlanOfCare = {} as PlanOfCare;
  //   s.activePlanOfCare.type = 0;
  // }
  // if (s && s.activePlanOfCare?.startDate === null) {
  //   s.activePlanOfCare.startDate = undefined;
  //   s.activePlanOfCare.endDate = undefined;
  // }

  const planType = ["IEP", "Referal", "504", "RtiMtss", "Ell", "Other"];
  const navigate = useNavigate();
  const path = `/xlogs/students/manager/${s?.id}`;
  const fInitial = s?.firstName
    ? s.firstName[0]
    : props.errorStudent?.firstName
    ? props.errorStudent.firstName[0]
    : "S";
  const lInitial = s?.lastName
    ? s.lastName[0]
    : props.errorStudent?.lastName
    ? props.errorStudent.lastName[0]
    : "N";

  return (
    <FadeIn i={props.animIndex}>
      <ButtonBase>
        {/* TODO: Replace with MUI card */}
        <XNGCard
          sx={{
            display: "flex",
            maxWidth: getSizing(34),
            paddingX: getSizing(2),
            paddingTop: getSizing(3),
            paddingBottom: getSizing(4),
            width: "269px",
            height: "345px",
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "column" }}
            onClick={() => {
              if (s) navigate(path);
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: getSizing(1.5),
                alignItems: "center",
              }}
            >
              {s ? (
                /* TODO: Replace with MUI avatar */
                <XNGAvatar size="lg" text={fInitial + lInitial} />
              ) : (
                <Avatar
                  alt="Broken student date Icon"
                  sx={{
                    width: "75px",
                    height: "75px",
                    bgcolor: palette.danger[1],
                  }}
                >
                  {/* TODO: Replace with MSBIconRenderer and associated react-icons / MSB icon */}
                  <XNGIconRenderer size={"65%"} i={<XNGICONS.Alert />} color="white" />
                </Avatar>
              )}
              <Typography variant="h6" color={s ? palette.primary[2] : palette.danger[1]}>
                {s?.firstName || props.errorStudent?.firstName}{" "}
                {s?.lastName || props.errorStudent?.lastName}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: getSizing(4),
                paddingTop: getSizing(2),
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <Typography variant="body2" color={s ? palette.primary[2] : palette.danger[1]}>
                  DOB
                </Typography>
                <Typography variant="body2">{dayjs(s?.dateOfBirth).format(DATE_FORMAT)}</Typography>
              </Box>
              {s && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <Typography variant="body2" color={palette.primary[2]}>
                    Grade
                  </Typography>
                  <Typography variant="body2">
                    {studentProfileGradeToString(s?.grade!.valueOf())}
                  </Typography>
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <Typography variant="body2" color={s ? palette.primary[2] : palette.danger[1]}>
                  {s ? "Student ID" : "Medicaid ID"}
                </Typography>
                <Typography variant="body2">
                  {s?.studentIdGivenBySchoolDistrict || props.errorStudent?.medicaidId}
                </Typography>
              </Box>
            </Box>
            {s && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: getSizing(2),
                  alignItems: "start",
                }}
              >
                <Typography variant="body2" color={palette.primary[2]}>
                  Campus
                </Typography>
                <Typography variant="body2">
                  {s.activeSchoolCampuses ? s.activeSchoolCampuses[0]?.name : "School campus"}
                </Typography>
              </Box>
            )}
            <Box sx={{ display: "flex", paddingY: getSizing(2), width: "100%" }}>
              <Box
                sx={{
                  width: "100%",
                  bgcolor: palette.contrasts[3],
                  height: "1px",
                  marginY: getSizing(s ? 1 : 0.5),
                }}
              />
            </Box>
            {s && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <Typography variant="body2" color={palette.primary[2]}>
                    {planType[s?.activePlanOfCare?.type!]} Duration
                  </Typography>
                  <Typography variant="body2">
                    {dayjs(s?.activePlanOfCare?.startDate).format(DATE_FORMAT)}-
                    {dayjs(s?.activePlanOfCare?.endDate).format(DATE_FORMAT)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    paddingTop: getSizing(2),
                    flexDirection: "column",
                  }}
                >
                  {/* TODO: Replace with MUI progress */}
                  <XNGProgress progress={getProgressPercentage(s)} />
                </Box>
              </>
            )}
            {!s && (
              <Box textAlign={"left"}>
                <Typography fontWeight={"600"} color={palette.danger[1]}>
                  Error Message
                </Typography>
                <Typography variant="body2" fontSize={"13px"}>
                  {props.errorMessage}
                </Typography>
              </Box>
            )}
          </Box>
        </XNGCard>
      </ButtonBase>
    </FadeIn>
  );
}

export default StudentCard;
