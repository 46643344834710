import {
  Card,
  CardActions,
  Checkbox,
  CardActionArea,
  Typography,
  Divider,
  Box,
  ButtonBase,
} from "@mui/material";
import XNGProgress from "../../../design/low-level/progress";
import { GoalDisplay } from "../../../profile-sdk";
import getProgressBetweenDates from "../../../utils/get_progress_between_dates";
import dayjs from "dayjs";

type Props = {
  goal: GoalDisplay;
  isChecked: boolean;
  onClick: () => void;
};

export const GoalCard = ({ goal, isChecked, onClick }: Props) => {
  const cardTitleProps = {
    component: "h4",
    fontWeight: "700",
    color: "primary",
  };
  return (
    <Box
      sx={{
        width: "18.5rem",
        // marginInline: "auto",
        border: "0.8px solid rgba(0, 0, 0, 0.2)",
        maxHeight: "28rem",
        borderRadius: ".5rem",
        overflow: "hidden",
      }}
      onClick={() => onClick()}
    >
      <ButtonBase sx={{ display: "flex", flexDirection: "column", width: "100%", p: ".5rem" }}>
        <Checkbox
          size="small"
          sx={{ marginLeft: "auto", marginBottom: "-10px" }}
          checked={isChecked}
        />
        {/* SECTION 1 */}
        <Box display={"flex"} mb={"1rem"}>
          <Box flexGrow={1}>
            <Typography {...cardTitleProps}>Goal ID</Typography>
            <Typography>{goal.number}</Typography>
          </Box>
          <Box flexGrow={1}>
            <Typography {...cardTitleProps}>Objectives</Typography>
            <Typography>{goal.objectives?.length}</Typography>
          </Box>
        </Box>
        {/* SECTION 2  */}
        <Box
          height={"14rem"}
          mb={"2px"}
          sx={{
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Typography variant="body1">{goal.description}</Typography>
        </Box>
        {/* SECTION 3 */}
        <Box sx={{ mb: "1rem", width: "16rem" }}>
          <Divider sx={{ mb: "1.5rem" }} />

          <Typography {...cardTitleProps}>Goal Duration</Typography>
          <Typography fontSize={"0.8rem"} mb={"1rem"}>
            {dayjs(goal.startDate).format("M/D/YY")} - {dayjs(goal.endDate).format("M/D/YY")}
          </Typography>

          {goal.startDate && goal.endDate && (
            <XNGProgress
              progress={getProgressBetweenDates(dayjs(goal.startDate), dayjs(goal.endDate))}
            />
          )}
        </Box>
      </ButtonBase>
    </Box>
  );
};
