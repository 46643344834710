import dayjs, { Dayjs } from "dayjs";
import { PlanOfCare } from "../../../../../profile-sdk";
import { StudentPlanOfCareProgress } from "../components/student_plan_of_care_progress_cell";

/**
 * Accepts a Profile API schema PlanOfCare and returns an object the StudentPlanOfCareProgressCell
 * component understands for presenting data and errors.
 */
export function createPlanOfCareProgress(poc: PlanOfCare): StudentPlanOfCareProgress {
  if (!poc) {
    return new StudentPlanOfCareProgress({
      isError: { text: "Cannot determine plan of care" },
    });
  }
  if (!poc.startDate && !poc.endDate) {
    return new StudentPlanOfCareProgress({
      isError: { text: "Cannot determine duration of plan of care" },
    });
  }
  if (!poc.startDate)
    return new StudentPlanOfCareProgress({
      isError: { text: "Cannot determine start date of plan of care" },
    });
  if (!poc.endDate)
    return new StudentPlanOfCareProgress({
      isError: { text: "Cannot determine end date of plan of care" },
    });

  const endDate: Readonly<Dayjs> = dayjs(poc.endDate);
  const startDate: Readonly<Dayjs> = dayjs(poc.startDate);
  const today: Readonly<Dayjs> = dayjs();

  if (startDate.isAfter(today))
    return new StudentPlanOfCareProgress({ hasNotBeganYet: true, percentage: 0 });
  if (endDate.isBefore(today))
    return new StudentPlanOfCareProgress({ hasAlreadyCompleted: true, percentage: 1 });

  const totalDuration = endDate.diff(startDate, "days");

  const elapsedDuration = today.diff(startDate, "days");

  const progress = (elapsedDuration / totalDuration) * 100;

  return new StudentPlanOfCareProgress({ percentage: progress });
}
