import {
  UserResponse,
  DistrictAccessRequestRef,
  UserManagementCard as UserManagementCardType,
  ServiceProviderRef,
} from "../../../../../../profile-sdk";
import { XLogsRoleStrings } from "../../../../../../context/types/xlogsrole";
import useApiQueryGetServiceProviderTypesByDate from "../../../../../../api/hooks/state_snapshots/use_api_query_get_service_provider_types_by_date";
import { XNGCheckbox } from "../../../../../../design";
import { MSBInputErrorWrapper, MSBSearchMultiselect } from "../../../../../../fortitude";
import msbMUIAutoCompleteFilterOptions from "../../../../../../utils/msb_mui_auto_complete_filter_options";
import { MenuItem, Select } from "@mui/material";
import UserApprovalsCardShape from "./user_approvals_card_shape";

type Props = {
  userCard: UserManagementCardType;
  user: UserResponse | null;
  selectedDistricts: DistrictAccessRequestRef[];
  districtOptions: DistrictAccessRequestRef[];
  setSelectedDistricts: (districts: DistrictAccessRequestRef[]) => void;
  selectedServiceProviderTypes: ServiceProviderRef[];
  setSelectedServiceProviderTypes: (serviceProviderTypes: ServiceProviderRef[]) => void;
  serviceProviderApiOptionsManager: ReturnType<typeof useApiQueryGetServiceProviderTypesByDate>;
  selectedXlogsRole: XLogsRoleStrings;
  setSelectedXlogsRole: (role: XLogsRoleStrings) => void;
  xlogsRoleOptions: XLogsRoleStrings[];
  isSelected: () => boolean;
  onAddCardData: () => void;
  onRemoveCardData: () => void;
};

const UserApprovalsCard = (props: Props) => {
  const { user, client, status } = props.userCard;

  const {
    data: serviceProviderTypesOptionsResponse,
    isError: serviceProviderTypesOptionsIsError,
    isPending: serviceProviderTypesOptionsIsPending,
    refetch: refetchMutlipleServiceProviderTypesOptions,
  } = props.serviceProviderApiOptionsManager;
  return (
    <UserApprovalsCardShape
      useCheckBoxSection={
        <XNGCheckbox
          checked={props.isSelected()}
          onToggle={(e) => {
            e.stopPropagation();
            if (props.isSelected()) {
              props.onRemoveCardData();
            } else {
              props.onAddCardData();
            }
          }}
        />
      }
      useAvatarNameSection={{
        firstName: user?.firstName,
        lastName: user?.lastName,
      }}
      useClientDistrictStatusSection={{
        clientName: client?.name,
        status: status,
      }}
      useDistrictsSection={
        <MSBSearchMultiselect
          options={props.districtOptions || []}
          selectedOptions={props.selectedDistricts || []}
          renderOptionVariant="checkbox"
          getOptionLabel={(option) => `${option?.district?.name}`}
          variant="no overflow"
          label=""
          onChange={(newDistricts, e) => {
            e?.preventDefault();
            e?.stopPropagation();
            props.setSelectedDistricts(newDistricts);
          }}
          autocompleteProps={{
            size: "small",
            fullWidth: true,
            onClick: (e) => {
              e.stopPropagation();
              e.preventDefault();
            },
          }}
          textFieldProps={{
            onClick: (e) => {
              e.stopPropagation();
              e.preventDefault();
            },
          }}
        />
      }
      useEmailSection={{
        email: user?.email,
      }}
      useServiceProviderTypeSection={
        <MSBInputErrorWrapper
          isError={serviceProviderTypesOptionsIsError}
          displayRefreshButton={serviceProviderTypesOptionsIsError}
          errorText={"Error fetching service provider types Options"}
          refetch={refetchMutlipleServiceProviderTypesOptions}
        >
          <MSBSearchMultiselect
            selectedOptions={props.selectedServiceProviderTypes}
            options={serviceProviderTypesOptionsResponse?.serviceProviderTypes || []}
            getOptionLabel={(option) => `${option?.name}`}
            onChange={(newServiceProviderTypes, e) => {
              e?.preventDefault();
              e?.stopPropagation();
              props.setSelectedServiceProviderTypes(newServiceProviderTypes);
            }}
            renderOptionVariant="checkbox"
            variant="no overflow after 1"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            label={(() => {
              if (serviceProviderTypesOptionsIsPending) {
                return "Loading Service Provider Types Options...";
              }
              if (serviceProviderTypesOptionsIsError) {
                return "Failed to load Service Provider Types Options";
              }
              return "Service Provider Type(s)";
            })()}
            disabled={serviceProviderTypesOptionsIsPending || serviceProviderTypesOptionsIsError}
            autocompleteProps={{
              disableCloseOnSelect: true,
              filterOptions: msbMUIAutoCompleteFilterOptions(),
              onClick: (e) => {
                e.stopPropagation();
                e.preventDefault();
              },
            }}
            textFieldProps={{
              onClick: (e) => {
                e.stopPropagation();
                e.preventDefault();
              },
            }}
            sx={{
              flex: 1,
              maxWidth: "100%",
              mt: "4px",
            }}
          />
        </MSBInputErrorWrapper>
      }
      useXlogsRoleDropDownSection={
        <Select
          size="small"
          fullWidth
          value={props.selectedXlogsRole}
          onChange={(e) => props.setSelectedXlogsRole?.(e.target.value as XLogsRoleStrings)}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          {props.xlogsRoleOptions.map((role, i) => {
            return (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            );
          })}
        </Select>
      }
      onCardClick={() => {
        if (props.isSelected()) {
          props.onRemoveCardData();
        } else {
          props.onAddCardData();
        }
      }}
    />
  );
};

export default UserApprovalsCard;
