import { Box, BoxProps, FormHelperText, FormHelperTextProps, IconButton, IconButtonProps } from "@mui/material";
import { XNGIconRenderer } from "../../design";
import MSBICONS from "../iconography/icons";

type RequiredProps = {
  isError: boolean;
  errorText: string;
  displayRefreshButton: boolean;
  refetch: () => void;
};

type OptionalProps = {
  children?: React.ReactNode;
  formHelperTextProps?: FormHelperTextProps;
  rootProps?: BoxProps;
  iconButtonProps?: IconButtonProps;
};

type Props = RequiredProps & OptionalProps;

const MSBInputErrorWrapper = (props: Props) => {
  return (
    <Box position={"relative"} {...props.rootProps}>
      <Box display={"flex"} alignItems={"center"}>
        {props.children}
        <IconButton
          sx={{display: props.displayRefreshButton ? "block" : "none"}}
          onClick={() => props.refetch()}
          {...props.iconButtonProps}
        >
          <XNGIconRenderer size={"xs"} i={<MSBICONS.Refresh />} />
        </IconButton>
      </Box>
      {props.isError && (
        <FormHelperText
        error={props.isError}
        sx={{ position: "absolute", bottom: "0", transform: "translateY(100%)"}}
        {...props.formHelperTextProps}
        >
          {props.errorText}
        </FormHelperText>
      )}
    </Box>
  );
};

export default MSBInputErrorWrapper;
