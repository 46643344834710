// useFinancialDashboard.ts
import { useContext } from "react"; 
import FinancialDashboardContextType from "../types/financial_dashboard_context_type";
import FinancialDashboardContext from "../context/financial_dashboard_context";

const useFinancialDashboard = (): FinancialDashboardContextType => {
    const context = useContext(FinancialDashboardContext);
    if (context === undefined) {
        throw new Error("useFinancialDashboard must be used within a FinancialDashboardProvider");
    }
    return context;
};

export default useFinancialDashboard;
