import { useEffect, useState } from "react";
import { CreateStudentRequest } from "../../../../../profile-sdk";
import { Box, Dialog, Stack, Typography } from "@mui/material";
import MSBClose from "../../../../../fortitude/components/button_close";
import { XNGCheckbox } from "../../../../../design";
import XNGCard from "../../../../../design/low-level/card";
import { getSizing } from "../../../../../design/sizing";
import XNGButton from "../../../../../design/low-level/button";

function RemoveStudentModal(
  props: Readonly<{
    open: boolean;
    onClose: () => void;
    deleteStudent: Function;
    studentCaseLoad: CreateStudentRequest[];
  }>,
) {
  const [deleteStudentList, setDeleteStudentList] = useState<CreateStudentRequest[]>(
    [] as CreateStudentRequest[],
  );

  const handleRemove = () => {
    props.deleteStudent(deleteStudentList);
    props.onClose();
  };

  const handleSelectAll = () => {
    if (deleteStudentList.length === props.studentCaseLoad.length) {
      setDeleteStudentList([]);
    } else {
      setDeleteStudentList([...props.studentCaseLoad]);
    }
  };

  useEffect(() => {
    console.log(props.open);
  }, [props.open]);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <Stack p={"1rem"}>
        <Box sx={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
          <MSBClose onClick={props.onClose} />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            Remove Student from Caseload
          </Typography>
        </Box>
        <Typography id="modal-modal-description" sx={{ mt: 2, paddingBottom: getSizing(2) }}>
          Select the student(s) you would like to remove from your caseload. This action will not
          delete any student data.
        </Typography>
        {props.studentCaseLoad.length > 1 && (
          <Box sx={{ display: "flex", alignItems: "center", gap: getSizing(1) }}>
            <XNGCheckbox
              checked={deleteStudentList.length === props.studentCaseLoad.length}
              onToggle={() => {
                handleSelectAll();
              }}
            />
            <Typography variant="body1">Select All</Typography>
          </Box>
        )}
        <XNGCard
          sx={{
            maxHeight: getSizing(36),
            overflowY: "scroll",
            paddingBottom: 1,
          }}
        >
          {props.studentCaseLoad.map((s: any, i) => {
            const handleCheck = () => {
              let temp = deleteStudentList;
              if (temp.includes(s)) {
                temp.splice(temp.indexOf(s), 1);
                setDeleteStudentList([...temp]);
              } else {
                temp.push(s);
                setDeleteStudentList([...temp]);
              }
            };

            return (
              <Box
                key={i}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: getSizing(1),
                  paddingTop: getSizing(1),
                }}
              >
                <XNGCheckbox
                  onToggle={() => {
                    handleCheck();
                  }}
                  checked={deleteStudentList.indexOf(s) !== -1}
                />
                <Typography variant="body1">
                  {s.firstName} {s.lastName}
                </Typography>
              </Box>
            );
          })}
        </XNGCard>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: getSizing(2.5),
            paddingTop: getSizing(5),
          }}
        >
          <Box sx={{ paddingBottom: getSizing(1) }}>
            <XNGButton onClick={props.onClose}>Back to Caseload</XNGButton>
          </Box>
          <Box sx={{ paddingBottom: getSizing(1) }}>
            <XNGButton
              onClick={() => {
                handleRemove();
              }}
            >
              Remove
            </XNGButton>
          </Box>
        </Box>
      </Stack>
    </Dialog>
  );
}

export default RemoveStudentModal;
