import { Stack } from "@mui/material";
import UserApprovalsSkeletonCard from "../cards/user_approvals_skeleton_card";

type Props = {
  cardsCount?: number;
};

const UserApprovalSkeletonView = (props: Props) => {
  return (
    <Stack
      direction={"row"}
      gap={2}
      flexWrap={"wrap"}
      sx={{
        justifyContent: {
          xs: "center",
          md: "flex-start",
        },
      }}
    >
      {Array.from({ length: props.cardsCount ?? 8 }).map(() => (
        <UserApprovalsSkeletonCard key={crypto.randomUUID()} />
      ))}
    </Stack>
  );
};

export default UserApprovalSkeletonView;
