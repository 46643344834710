import { FiveYearSummaryReport, ReimbursementGoalReport, ServiceAreaReimbursementReport,BillingBlockSummaryReport, BillingBlockSummary } from "@xng/reporting";
import { ServiceUiLabel } from "./chart_dummy";


 
export interface BarChartDataFormat {
    labels: string[];
    datasets: {
        label: string;
        backgroundColor: string;
        data: number[];
        barThickness: number;
        categorySpacing?: number;
        categoryPercentage?: number;
        barPercentage?: number
    }[]}

interface ChartData {
      labels: string[];
      datasets: {
          data: number[];
          backgroundColor: string[];
      }[];
  }
  

export class ChartDataTransformationManager {
       private static instance: ChartDataTransformationManager;
       sanitizedReimbursementServiceData:BarChartDataFormat|undefined = undefined
       sanitizedFiveYearBillingSummaryData:BarChartDataFormat|undefined = undefined
       isBarDataTransformed: boolean = false
       cumilativeReimbursementDataReports: ReimbursementGoalReport = {}
       //  data for doghnut chart in billing blocks
       sanitizedBillingBlockSummaryData: ChartData|undefined = undefined
       //  detailed doghnut chart data in billing blocks
       sanitizedDetailedBillingBlockSummaryData: ChartData|undefined = undefined
       //colors used in graphs 
       private readonly greenDark = '#2C5545'
       private readonly blueLight = '#6FC8D6'
       private readonly greenLight = '#3cb48c'
       private readonly brickRed = "#B85741" 
       private readonly darkBlue = "#3E4C59"
       private readonly paletteXlogsSand: '#F0E9E3';
       private readonly paletteXlogsBeige: '#AD8463'; 
       private constructor() { 
        }
      

        public static getInstance(): ChartDataTransformationManager {
            if (!ChartDataTransformationManager.instance) {
              ChartDataTransformationManager.instance = new ChartDataTransformationManager();
            }
            return ChartDataTransformationManager.instance;
          }
         
         public transformDataForServiceAreaReimbursementReport(data: ServiceAreaReimbursementReport[]):ChartDataTransformationManager {
                const labels = Object.values(ServiceUiLabel)
                // remove duplicates
                data = this.removeDuplicatesInServiceAreaReimbursementReport(data) 
                const billed = data.map(item => item.billed || 0);
                const paid = data.map(item => item.paid || 0);
                const goals = data.map(item => item.goal || 0); 
                const goalDataSet = {
                    label: "Goal",
                    backgroundColor: this.greenDark,
                    data: goals,
                    barThickness: 24,
                    categorySpacing: 50}

                const billedDataSet =  {
                    label: "Billed",
                    backgroundColor: this.blueLight,
                    data: billed,
                    barThickness: 24,
                    categorySpacing: 50

                  }
                const paidDataSet = { 
                    label: "Paid",
                    backgroundColor: this.greenLight,
                    data: paid,
                    barThickness: 24,
                    categorySpacing: 50 

                  }
                
                
                this.sanitizedReimbursementServiceData = {
                    labels,
                    datasets: [goalDataSet,billedDataSet,paidDataSet]
                    } 

                this.isBarDataTransformed = true
                return this
         } 
         public transformDataForReimbursementReportForGoalTrackingThisYear(data: ReimbursementGoalReport[]):any {
            // remove duplicates in reimbursement goal report
            data = this.removeDuplicatesInReimbursementGoalReport(data)
            const goals = data.map(item => item.goal || 0);
            const paid = data.map(item => item.paid || 0);
            const totalGoal = goals.reduce((a, b) => a + b, 0);
            const totalPaid = paid.reduce((a, b) => a + b, 0);
            const paidDataSet = this.getDataSetForGoalTracking(totalPaid,totalGoal)
            return {
                labels: ["Paid", "Goal"],
                datasets: [paidDataSet]
              }
         }
         public transformDataForReimbursementReportForGoalTrackingLastYear(data: ReimbursementGoalReport[]):any {
            // remove duplicates in reimbursement goal report
            data = this.removeDuplicatesInReimbursementGoalReport(data)
            const goals = data.map(item => item.lastYearGoal || 0);
            const paid = data.map(item => item.lastYearPaidTotal || 0);
            const totalGoal = goals.reduce((a, b) => a + b, 0);
            const totalPaid = paid.reduce((a, b) => a + b, 0);
            const paidDataSet = this.getDataSetForGoalTracking(totalPaid,totalGoal)
            this.mountCalculatedCumilativeReimbursementDataReport(data)
            return {
                labels: ["Paid", "Goal"],
                datasets: [paidDataSet]
              }
         }
         public transformDataForFiveYearBillingSummary(data: FiveYearSummaryReport[]){
            data = this.removeDuplicatesInFiveYearSummaryReport(data) 
            const goalDataSet = {
                label: "Goal",
                backgroundColor: '#2C5545',
                data: data.map(item => item.goal || 0),
                barThickness:18,
                categorySpacing:.8 
                // barPercentage:1,
                // categoryPercentage:0.8
          
              };
              const billedDataSet = {
                label: "Billed",
                backgroundColor: '#6FC8D6',
                data: data.map(item => item.billed || 0),
                barThickness:14.5,  
                categorySpacing:.8
                // barPercentage:1,
                // categoryPercentage:0.8
          
              };
              const paidDataSet = {
                label: "Paid",
                backgroundColor: '#3cb48c',
                data: data.map(item => item.paid || 0),
                barThickness:18,  
                categorySpacing:20
                // barPercentage:1,
                // categoryPercentage:0.8
              };
            this.sanitizedFiveYearBillingSummaryData = {
                labels: data.map(item => item?.year?.toString() as string),
                datasets: [goalDataSet, billedDataSet, paidDataSet]
              };
            return this
         }
         public transformBillingBlocksData(data:BillingBlockSummaryReport[]){
        
            this.sanitizedBillingBlockSummaryData = {
                labels: data.map(item => item?.name?.toString() as string),
                datasets: [
                    { 
                        data: data.map(item => item?.totalDollarAmount || 0),
                        backgroundColor: [this.brickRed,this.blueLight,this.darkBlue,this.greenLight] 
                    }
                ]
            }
            return this
         }
         public transformDetailedBillingBlocksData(data:BillingBlockSummary[]){
            this.sanitizedDetailedBillingBlockSummaryData = {
                labels: data.map(item => item?.blockType?.toString() as string),
                datasets: [
                    {
                        data: data.map(item => item?.dollarAmount || 0),
                        backgroundColor: [this.brickRed,this.blueLight,this.greenDark,this.greenLight,this.paletteXlogsSand,this.paletteXlogsBeige]
                    }
                ]
            }
            return this
         }
         public getBillingBlocksDoughnutOptions(){
          const options = {
            responsive: true, // Maintain aspect ratio on resize
            maintainAspectRatio: false, // Don't constrain chart to canvas size
            plugins: {
              legend: {
                display: true, // Show the legend
                position: 'top' as any, // Place the legend at the bottom
              },
              tooltip: {
                callbacks: {
                    label: function(context: any) {
                        const label = context.label || '';
                        const value = context.formattedValue || context.raw;
                        return `${label}: $${value}`;
                    }
                }
            }
            },
          };
            return options
         }


         public getBarChartOptionsForFiveYearBillingSummary(scale:number){ 
            const DollarOptionsForFiveYearBillingSummary = {
                responsive: true,
                scales: {
                    x: {
                        ticks: {
                            autoSkip: false,
                            autoTicks: false,
                    
                            // Define the number of increments (excluding 0)
                            // count: 11,
                    
                            // Calculate and set specific y-axis values based on increment
                            beginAtZero: true,  // Include 0 as the first value
                            callback: function (value: any, index: any, values: any) {
                              return '$' + (value).toLocaleString("en-US");
                            },
                            color: 'black',
                            font: {
                              size: 14
                            },
                            
                        }
                    },
                   
                },
                plugins: {
                    tooltip: {
                      callbacks: {
                        label: function(context: any) {
                          let label = context.dataset.label || '';
                  
                          if (label) {
                            label += ': ';
                          }
                          if (context.parsed.y !== null) {
                            // Format the y-axis value based on your data range
                            const formattedValue = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.x);
                            label += formattedValue;
                          }
                          return label;
                        }
                      }
                    },
                    legend:{display:true}
                  }
              }
                return { 
                    maintainAspectRatio: false,
                    indexAxis: "y" as any, 
                    scales: {
                      y: { // Define y-axis for each dataset
                        stacked: false, // Stack the bars on the y-axis
                        ticks: { display: false },
                        grid: { display: true, offset: true }, 
                      }, 
                      x: {
                        stacked: false,
                        ticks: {
                          display: true,
                          callback: function (value: any) {
                            return '$' + value.toLocaleString('en-US');
                          },
                        },
                        grid: { display: true, offset: true },
                      },
                    },
                    plugins: DollarOptionsForFiveYearBillingSummary.plugins,
                    // barPercentage: 1,
                    // categoryPercentage: 0.8,
                    // borderSkipped: false,
                  }
     
         }


         private getDataSetForGoalTracking(paid:number,goal:number){
            const dataSet = {
                label: "Paid",
                backgroundColor: [this.greenLight,this.greenDark],
                data: [paid,goal], // Array of data points
                barPercentage: 1, // Adjust bar width as a percentage of space available
                categoryPercentage: .8, // Adjust category width as a percentage of space available
              };
            return dataSet

         }

        getDollarOptions(SCALE:number,isLegend:boolean = true){
            const dollarOptions = {
                responsive: true,
                scales: {
                    y: {
                        ticks: {
                            autoSkip: false,
                            autoTicks: false,
                    
                            // Define the number of increments (excluding 0)
                            // count: 11,
                    
                            // Calculate and set specific y-axis values based on increment
                            beginAtZero: true,  // Include 0 as the first value
                            callback: function (value: any, index: any, values: any) {
                              return '$' + (value).toLocaleString("en-US");
                            },
                            color: 'black',
                            font: {
                              size: 14
                            },
                            
                        }
                    },
                   
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function(context: any) {
                                var label = context.dataset.label || '';
            
                                if (label) {
                                    label += ': ';
                                }
                                if (context.parsed.y !== null) {
                                    label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                                }
                                return label;
                            }
                        }
                    },
                    legend:{display:isLegend}
                }
            }

            return dollarOptions
         }
         
        getBarChartOptionsForReimburementByServiceAreaWidget(scale:number,isLegend:boolean = true,isTicks:boolean = false):any{
          
        const DollarOptions = {
            responsive: false,
            scales: {
                y: {
                    ticks: {
                        autoSkip: false, 
                        beginAtZero: true,  // Include 0 as the first value
                        // scale,
                        callback: function (value: any, index: any, values: any) {
                          return '$' + (value ).toLocaleString("en-US");
                        },
                        color: 'black',
                        font: {
                          size: 14
                        },
                        
                    }
                },
               
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function(context: any) {
                            var label = context.dataset.label || '';
        
                            if (label) {
                                label += ': ';
                            }
                            if (context.parsed.y !== null) {
                                label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                            }
                            return label;
                        }
                    }
                },
                legend:{display:isLegend}
            }
          }
          
          return {
            responsive:true,
            maintainAspectRatio: true,
            scales: { 
                x: { 
                    ticks: { display:isTicks },
                    grid: { display: true, offset: true   }, 
                },
                y: DollarOptions.scales.y, 
              },
              plugins: DollarOptions.plugins
            }
        }
         getBarChartOptions(scale:number,isLegend:boolean = true,isTicks:boolean = false):any{
            return {
                responsive:true,
                maintainAspectRatio: false,
                scales: { 
                    x: { 
                        ticks: { display:isTicks },
                        grid: { display: true, offset: true   }, 
                    },
                    y: this.getDollarOptions(scale,isLegend).scales.y, 
                  },
                  plugins: this.getDollarOptions(scale,isLegend).plugins
                }
         }
        mountCalculatedCumilativeReimbursementDataReport(data: ReimbursementGoalReport[]){
            const goals = data.map(item => item.lastYearGoal || 0);
            const paid = data.map(item => item.lastYearPaidTotal || 0);
            const percentOfGoalComplete = data.map(item => item.percentOfGoalComplete || 0);
            const percentOfYearComplete = data.map(item => item.percentOfYearComplete || 0);
            const totalGoal = goals.reduce((a, b) => a + b, 0);
            const totalPaid = paid.reduce((a, b) => a + b, 0);
            const toatalPercentOfGoalComplete = percentOfGoalComplete.reduce((a, b) => a + b, 0);
            const toatalPercentOfYearComplete = percentOfYearComplete.reduce((a, b) => a + b, 0);

             const cumilativeData:ReimbursementGoalReport = {
                  goal: totalGoal,
                  paid:totalPaid,
                  percentOfGoalComplete: toatalPercentOfGoalComplete,
                  percentOfYearComplete: toatalPercentOfYearComplete
             }
             this.cumilativeReimbursementDataReports = cumilativeData
             return cumilativeData
        }

        //  remove duplication 
        removeDuplicatesInServiceAreaReimbursementReport(array: ServiceAreaReimbursementReport[]) {
            return array.filter((v, i, a) => a.findIndex(t => (t.serviceCategory === v.serviceCategory)) === i)
        }

        removeDuplicatesInFiveYearSummaryReport(array: FiveYearSummaryReport[]) {
            return array.filter((v, i, a) => a.findIndex(t => (t.year === v.year)) === i)
        }

        removeDuplicatesInReimbursementGoalReport(array: ReimbursementGoalReport[]) {
            return array.filter((v, i, a) => a.findIndex(t => (t.accountDistrictID === v.accountDistrictID)) === i)
        }

        removeDuplicatesInBillingBlockSummaryReport(array: BillingBlockSummaryReport[]) {
            return array.filter((v, i, a) => a.findIndex(t => (t.name === v.name)) === i)
        }

        removeDuplicatesInBillingBlockSummary(array: BillingBlockSummary[]) {
            return array.filter((v, i, a) => a.findIndex(t => (t.blockType === v.blockType)) === i)
        }

}

export const chartDataTransformationManager = ChartDataTransformationManager.getInstance()