import { Box, Button, Table, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import classes from "./index.module.css";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { chartDataTransformationManager } from "../../../utils/chart_helper";
import useFinancialDashboard from "../../../hooks/useFinancialDashboard";
import { motion } from "framer-motion";
import React, { useEffect } from "react";
import SkeletonLoading from "../../skeletonLoading/SkeletonLoading";
import { BillingBlockSummary } from "@xng/reporting";
import { convertToCommaSeparatedValue } from "../../../utils/convert_util";

ChartJS.register(ArcElement, Tooltip, Legend);
const BillingBlocks = () => {
  const data = useFinancialDashboard()?.state?.data?.billingBlockSummaryReports;
  const isLoading = useFinancialDashboard()?.state?.loading;
  const [open, setOpen] = React.useState(false);
  const [billingBlockSummaryTitle,setBillingBlockSummaryTitle] = React.useState<string>("")
  const [billingBlockSummary,setBillingBlockSummary] = React.useState<BillingBlockSummary[]>([] as BillingBlockSummary[])
  // console.log(data);
  useEffect(()=>{
    if(data){ 
      setOpen(false)
    }
  },[data])
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        padding:"20px",
        overflow:"scroll"
      }}
    >
      <Wrapper open={open}>
        <FirstPage  open={open} styles={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Typography sx={{ textAlign: "center", fontWeight: "bold", fontSize: "20px" }}>
            Billing Blocks
          </Typography>
          <Box sx={{ width: "300px", height: "300px", marginTop: "50px" }}>
            {data &&
              chartDataTransformationManager.transformBillingBlocksData(data)
                .sanitizedBillingBlockSummaryData &&
              !isLoading && (
                <Doughnut
                  options={chartDataTransformationManager.getBillingBlocksDoughnutOptions()}
                  data={chartDataTransformationManager?.sanitizedBillingBlockSummaryData as any}
                />
              )}
          </Box>
          <TableContainer className={classes.tableContainer}>
            <Table>
              {data?.map((data, index) => (
                <TableRow
                  key={index} 
                  style={{ background: index % 2 !== 0 ? "#f8f8f8" : "white", height: "58px" }}
                >
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHover}
                    style={{ borderRight: "1px solid #f8f8f8", textAlign: "center" }}
                    onClick={()=>{
                      setBillingBlockSummaryTitle(data?.name as string)
                      setOpen(!open)
                      setBillingBlockSummary(data?.billingBlockSummaries as BillingBlockSummary[])}}
                  >
                    {data.name}
                  </TableCell>
                  <TableCell className={classes.tableCell} style={{ textAlign: "center" }}>
                    ${convertToCommaSeparatedValue(data.totalDollarAmount as number)}
                  </TableCell>
                </TableRow>
              ))}
            </Table>
            {/* Add more TableContainer items for remaining rows if needed */}
          </TableContainer>
        </FirstPage>
        {/* summary goes here */}
        <SecondPage  open={open} styles={{ display: "flex", flexDirection: "column", alignItems: "center"  }}>
      
          <Box sx={{width:"100%",display:"flex",flexDirection:"row",gap:"40px",marginTop:"10px"}}>
                <Button onClick={() => setOpen(!open)} className={classes.goBack} > 
                       ⬅️ 
                        Back 
                </Button>
                <Typography sx={{ textAlign: "center", fontWeight: "bold", fontSize: "20px" }}>
        Billing Blocks <br/>
            {billingBlockSummaryTitle}
          </Typography>
          </Box> 
          {isLoading?<SkeletonLoading width={"300px"} height={"300px"} styles={{marginTop:"20px"}}  />: 
             <Box sx={{ width: "300px", height: "300px", marginTop: "20px" }}>
               <Doughnut options={chartDataTransformationManager.getBillingBlocksDoughnutOptions()}  data={chartDataTransformationManager.transformDetailedBillingBlocksData(billingBlockSummary)?.sanitizedDetailedBillingBlockSummaryData as any}/>
             </Box>
             }
          <TableContainer   style={{ marginTop: "30px",maxHeight:"230px" }}>
            {isLoading ? (<Table>
              {Array.from({length:4})?.map((data, index) => (
                <TableRow
                  key={index}
                  style={{ background: index % 2 !== 0 ? "#f8f8f8" : "white", height: "58px" }}
                >
                  <TableCell className={classes.tableCell + " " + classes.tableHover} style={{ borderRight: "1px solid #f8f8f8", textAlign: "center" }} >
                      <SkeletonLoading width="150px" height="20px" />
                  </TableCell>
                  <TableCell className={classes.tableCell} style={{ textAlign: "center" }}>
                      <SkeletonLoading width="150px" height="20px" />
                  </TableCell>
                </TableRow>
              ))}
            </Table>):(
                          <Table style={{maxHeight:"230px"}}>
                          {billingBlockSummary?.map((summary, index) => (
                            <TableRow
                              key={index} 
                              style={{ background: index % 2 !== 0 ? "#f8f8f8" : "white"  }}
                            >
                              <TableCell
                                className={classes.tableCell}
                                style={{ borderRight: "1px solid #f8f8f8", textAlign: "center" }}
                          
                              >
                                {summary.blockType}
                              </TableCell>
                              <TableCell className={classes.tableCell} style={{ textAlign: "center"  }}>
                                ${convertToCommaSeparatedValue(summary?.dollarAmount as number)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </Table>
            )}
            {/* Add more TableContainer items for remaining rows if needed */}
          </TableContainer>
        </SecondPage>
      </Wrapper>
    </Box>
  );
};

const FirstPage: React.FC<{ children?: React.ReactNode; styles?: React.CSSProperties,open?:boolean,onClick?:() => void }> = ({
  children,
  styles,
  open,
  onClick
}) => {
  return (
    <motion.div onClick={onClick}  style={{ width: "400px",   flexShrink: 0, ...styles }}>
      {children}
    </motion.div>
  );
};
const SecondPage: React.FC<{ children?: React.ReactNode ,open?:boolean,onClick?:() => void, styles?: React.CSSProperties}> = ({ children,open,onClick,styles }) => {
  return (
    <motion.div  onClick={onClick}   style={{ width: "400px",  flexShrink: 0, ...styles }}>
      {children}
    </motion.div>
  );
};
const Wrapper: React.FC<{ children?: React.ReactNode ,open?:boolean}> = ({ children,open }) => (
  <Box
    sx={{
      width: "400px", 
      overflow: "hidden",
      display: "flex",
      flexDirection: "row",
      postion:"realtive"
    }}
  >
    <motion.div transition={{duration:0.5,}} animate={{x: open?"-100%":"0"}} style={{width:"100%",height: "666px",display:"flex",flexDirection:"row"}}>

           {children}
    </motion.div>
  </Box>
);

export default BillingBlocks;
