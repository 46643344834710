 

export enum ProviderParticipationServiceCategoryFieldName {
    SERVICETYPE = "Service Type",
    UNPOSTEDSESSIONS = "Unposted Sessions",
    POSTEDSESSIONS = "Posted Sessions",
     POSTEDSESSIONS_THIS_TIME_LAST_YEAR = "Posted Sessions This Year",
    PROVIDERSWITHOUTSESSIONS = "Providers w/out Sessions",
    PROVIDERSWITHSESSIONS = "Providers w/ Sessions",
    VALUEOFUNPOSTEDSESSIONS = "Value of Unposted Sessions",
    PAIDAMOUNT = "Paid Amount",
}

export enum ProviderParticipationServiceCategoryHeaderName{
    SERVICETYPE = "Service Type",
    UNPOSTEDSESSIONS = "Unposted",
    POSTEDSESSIONS = "Posted",
     POSTEDSESSIONS_THIS_TIME_LAST_YEAR = " Sessions this time last year",
    PROVIDERSWITHOUTSESSIONS = "Providers w/out sessions	",
    PROVIDERSWITHSESSIONS = "Providers w/ sessions	",
    VALUEOFUNPOSTEDSESSIONS = "Value of Unposted Sessions",
    PAIDAMOUNT = "Paid Amount",
}

export const PROVIDER_PARTICIPATION_GRID_LOOKUP: Record<ProviderParticipationServiceCategoryFieldName,  ProviderParticipationServiceCategoryHeaderName> = {
    [ProviderParticipationServiceCategoryFieldName.SERVICETYPE]: ProviderParticipationServiceCategoryHeaderName.SERVICETYPE,
    [ProviderParticipationServiceCategoryFieldName.UNPOSTEDSESSIONS]: ProviderParticipationServiceCategoryHeaderName.UNPOSTEDSESSIONS,
    [ProviderParticipationServiceCategoryFieldName.POSTEDSESSIONS]: ProviderParticipationServiceCategoryHeaderName.POSTEDSESSIONS,
    [ProviderParticipationServiceCategoryFieldName.POSTEDSESSIONS_THIS_TIME_LAST_YEAR]: ProviderParticipationServiceCategoryHeaderName.POSTEDSESSIONS_THIS_TIME_LAST_YEAR,
    [ProviderParticipationServiceCategoryFieldName.PROVIDERSWITHOUTSESSIONS]: ProviderParticipationServiceCategoryHeaderName.PROVIDERSWITHOUTSESSIONS,
    [ProviderParticipationServiceCategoryFieldName.PROVIDERSWITHSESSIONS]: ProviderParticipationServiceCategoryHeaderName.PROVIDERSWITHSESSIONS,
    [ProviderParticipationServiceCategoryFieldName.VALUEOFUNPOSTEDSESSIONS]: ProviderParticipationServiceCategoryHeaderName.VALUEOFUNPOSTEDSESSIONS,
    [ProviderParticipationServiceCategoryFieldName.PAIDAMOUNT]: ProviderParticipationServiceCategoryHeaderName.PAIDAMOUNT,
}

export interface ProviderParticipationRowType {
    id:number
    [ProviderParticipationServiceCategoryFieldName.SERVICETYPE]: string
    [ProviderParticipationServiceCategoryFieldName.UNPOSTEDSESSIONS]: string
    [ProviderParticipationServiceCategoryFieldName.POSTEDSESSIONS]: string
    [ProviderParticipationServiceCategoryFieldName.POSTEDSESSIONS_THIS_TIME_LAST_YEAR]: string
    [ProviderParticipationServiceCategoryFieldName.PROVIDERSWITHOUTSESSIONS]: string
    [ProviderParticipationServiceCategoryFieldName.PROVIDERSWITHSESSIONS]: string
    [ProviderParticipationServiceCategoryFieldName.VALUEOFUNPOSTEDSESSIONS]: string
    [ProviderParticipationServiceCategoryFieldName.PAIDAMOUNT]: string
  }

  