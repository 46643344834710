import { FinancialDashboardReport  } from "@xng/reporting";
import FinancialDashboardContext from "../financial_dashboard_context";
import {  useReducer } from "react"; 
import {   FinancialDashboardAction } from "../financial_dashboard_actions";
import { financialDashboardReducer } from "../reducer/financial_dashboard_reducer";
import React from "react";

// types.ts
export interface FinancialDashboardState {
    data: FinancialDashboardReport;
    error: string | undefined;
    loading: boolean;
}


export const initialState = {
    loading: false,
    error: undefined,
    data: {} as FinancialDashboardReport
};

 


export const FinancialDashboardProvider = ({ children }:{children: React.ReactNode}) => { 
 
  const [state, dispatch] = useReducer<React.Reducer<FinancialDashboardState, FinancialDashboardAction>>(financialDashboardReducer, initialState);
  const memoisedState = React.useMemo(()=>state,[state])
  const memoisedDispatch = React.useMemo(()=>dispatch,[])
    return (
      <FinancialDashboardContext.Provider value={{state:memoisedState,dispatch:memoisedDispatch}}>
        {children}
      </FinancialDashboardContext.Provider>
    );
  };