import { AccountInfo, AuthenticationResult, EventMessage, EventType } from "@azure/msal-browser";
import { initializeAPIs } from "../api/api";
import { setAccountAndGetTokens } from "./setAccountAndGetTokens";
import { msalInstance } from "./msalInstance";

/**
 * This file contains the event callback that is triggered when a LoginSuccess, AcquireTokenSuccess, or SsoSilentSuccess event is received.
 * It is responsible for populating the `msalAccount` variable and initializing the APIs with the access token acquired from the event.
 */

// The account information of the user
export let msalAccount: AccountInfo | null = null;


// Check if the MSAL instance is initialized
if (!msalInstance) {
  console.error("MSAL instance is not initialized");
}

msalInstance.addEventCallback((event: EventMessage) => {
  switch (event.eventType) {
    case EventType.ACQUIRE_TOKEN_SUCCESS:
    case EventType.SSO_SILENT_SUCCESS: 
    case EventType.LOGIN_SUCCESS: {
      // Set the account and get the tokens
      setAccountAndGetTokens(event)
        .then(({ msalAccount: account, accessToken }) => {
          msalAccount = account;
          // Initialize the APIs with the access token
          initializeAPIs(accessToken);
        })
        .catch((error) => {
          console.error("Error handling LOGIN_SUCCESS event: ", error);
        });
      break;
    }
    default: {
      return;
    }
  }
});
