import { Box, Typography } from '@mui/material'
import classes from './index.module.css'
import React from 'react'
import { motion } from 'framer-motion'
import { getFormattedDateTime } from '../../utils/date_utils'
import { BillingSummaryLabelLookup } from '../../utils/billing_summary_helper'

const PROGREEBAR_CLR = "#3cb48c"
export const ProgressBar:React.FC<{value:number,label:string}> = ({value,label}) => {
  return (
    <Box>
        <span className={classes.progressBarLabel}>{label} : {value}%</span>
        <Box style={{ backgroundColor: '#f5f5f5', borderRadius: '20px', height: '16px' ,width:"100%",overflow:"hidden"}}>
           <Box  style={{ backgroundColor: PROGREEBAR_CLR,height:"100%" ,width:`${value}%`,borderRadius: '20px'}} />
        </Box>
    </Box>
  )
}

const slashMotion = {
  rest: { 
    opacity:1,
    x: "67.4%", // Initial position off-screen to the left
    ease: "easeOut", 
    duration: 0.3, 
    type: "tween" ,
    
 
  },
  hover: {
    opacity: 1,
   
    x: 0, // Final position on hover
    transition: {
      duration: 0.4,
      type: "tween",
      ease: "easeIn"
    },

  }
};

interface ProgressBarWithHoverProps { 
  value:number
  earliestRNS:Date
  latestRNS:Date
  earliest835:Date
  latest835:Date
  year:number
}


export const ProgressBarWithHover:React.FC<ProgressBarWithHoverProps> = ({ value,earliestRNS,latestRNS,earliest835,latest835,year}) => {
  const [open,setOpen] = React.useState(false)
  const detailedData = [earliestRNS,latestRNS,earliest835,latest835]
  return (   
  <motion.div style={{width:"399px",minHeight:"78px",overflow:"hidden" ,position:"relative",flexShrink:0}}>
   <motion.div onClick={()=>setOpen(!open)}  style={{ height:"72.5px",position:"absolute",top:0,left:0,display:"flex"}} animate={{left: open?"-100%":0}}>      
      <motion.div className={classes.labelledProressBarRoot}  initial="rest" whileHover="hover" animate="rest">
        <motion.div className={classes.progressBarLabel + " "+ classes.labelledProgressbarLabelWrapper}>
            <Typography className={classes.progressBarLabel}>{year} </Typography>
            {/* <Box sx={{display:"flex",gap:"5px",alignItems:"center",justifyItems:"center"}}> */}
              <motion.span       
                variants={slashMotion} style={{whiteSpace:"nowrap",display:"flex",gap:"5px",alignItems:"center",justifyItems:"center"}}>
                  <span style={{width:"60px",flexShrink:0,textAlign:"start"}}>
                            {Math.abs(value)?.toFixed(2)}% 
                  </span>
                  <span>
                       goal achieved
                  </span>
              </motion.span>
            {/* </Box> */}
        </motion.div>

        <motion.div style={{ backgroundColor: '#f5f5f5', borderRadius: '20px', height: '16px' ,width:"100%",overflow:"hidden"}}>
          <motion.div  style={{ backgroundColor: PROGREEBAR_CLR,height:"16px" ,width:`${value}%`,borderRadius: '20px'}} />
        </motion.div>
      </motion.div>
      
      <motion.div className={classes.detailedDataPageRoot}>
           <Typography className={classes.detailedDataPageTitle}>{year}</Typography>
            <Box sx={{display:"flex" ,alignItems:"center",justifyContent:"space-between",width:"100%"}}>
                {Array.from({length:4}).map((item,i)=><Box key={i} className={classes.detailedDataPageLabelWrapper}>
                     <Typography className={classes.detailedDataPageLabel}>{Object.values(BillingSummaryLabelLookup)[i]}</Typography>
                     <Typography sx={{fontSize:12}}>{getFormattedDateTime(detailedData[i] as unknown as string ).date }</Typography>
                     <Typography sx={{fontSize:12}}>{getFormattedDateTime(detailedData[i] as unknown as string ).time}</Typography>
                </Box>)}
            </Box>
      </motion.div>
   </motion.div>
  </motion.div>
)
}

 