import { Box, Modal, Typography } from "@mui/material";
import PrescribingProviderModalRoot from "../ModalRoot/PrescribingProviderModalRoot"; 
import React, { useEffect, useState } from "react";
import { API_PRESCRIBING_PROVIDER } from "../../../../../../../api/api";
import useUserManagementContext from "../../../../../../user_management/hooks/context/use_user_management_context";
import usePrescribingProvider from "../../../hooks/usePrescribingProvider";
import { GetPrescribingProvidersResponse } from "../../../../../../../profile-sdk";
import { PrescribingProviderActionType } from "../../../context/prescribing_provider_actions";
import FullPageLoadingScreen from "../../../../../../../design/high-level/common/full_page_loading_screen";
import { commonModalStyles } from "../common_styles";

enum InputNames {
  PROVIDER_FIRST_NAME = "firstName",
  PROVIDER_LAST_NAME = "lastName",
  NPI = "npi",
  MEDICAID = "stateMedicaidNumber",
}

const Add: React.FC<{ isOpened: boolean; onClose: () => void }> = ({ isOpened, onClose }) => {
  const [providerInfromation, setProviderInformation] = React.useState({
    [InputNames.PROVIDER_FIRST_NAME]: "",
    [InputNames.NPI]: "",
    [InputNames.MEDICAID]: "",
    [InputNames.PROVIDER_LAST_NAME]: "",
  });
  const [error, setError] = useState<{ firstName: string; lastName: string; npi: string; stateMedicaidNumber: string; }>({ 
    firstName: "",
    lastName: "",
    npi: "",
    stateMedicaidNumber: ""
  });
  const state_in_us = useUserManagementContext().store.userManagementData.stateInUs;
  const client = useUserManagementContext().store.userManagementData.client;
  const { dispatch } = usePrescribingProvider();
  const [isLoading, setLoading] = useState(false);


  useEffect(() => {
    if(error.firstName && providerInfromation?.firstName){ 
      setError({ ...error, firstName: "" })
    }
    if(error.lastName && providerInfromation?.lastName){
      setError({ ...error, lastName: "" });
    }
    if(error.npi && providerInfromation?.npi){
      setError({ ...error, npi: "" });
    }
    if(error.stateMedicaidNumber && providerInfromation?.stateMedicaidNumber){
      setError({ ...error, stateMedicaidNumber: "" });
    }
  },[providerInfromation])
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProviderInformation({ ...providerInfromation, [e.target.name]: e.target.value.trim() });
  };

  const createNPIProvider = async () => {
    try {
      if (!client) {
        throw new Error("Client not found");
      }
      setLoading(true);
      const prescribingProviderRequest = {
        ...providerInfromation,
        client: {
          id: client.id,
          name: client.name,
          startDate: client.startDate,
          endDate: client.endDate,
        },
      };
      const response = await API_PRESCRIBING_PROVIDER.v1PrescribingProvidersPost(
        state_in_us,
        prescribingProviderRequest,
      );
      // update prescribing providers list
      if (response) {
        const response: GetPrescribingProvidersResponse =
          await API_PRESCRIBING_PROVIDER.v1PrescribingProvidersGet(
            client.id as string,
            state_in_us,
          );
        dispatch({
          type: PrescribingProviderActionType.SET_PRESCRIBING_PROVIDERS_LIST,
          payload: response.prescribingProviders,
        });
        setProviderInformation({
          [InputNames.PROVIDER_FIRST_NAME]: "",
          [InputNames.NPI]: "",
          [InputNames.MEDICAID]: "",
          [InputNames.PROVIDER_LAST_NAME]: "",
        });
        onClose();
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setProviderInformation({
      [InputNames.PROVIDER_FIRST_NAME]: "",
      [InputNames.NPI]: "",
      [InputNames.MEDICAID]: "",
      [InputNames.PROVIDER_LAST_NAME]: "",
    });
    onClose();
  };

  const onSave = () => {
    let isEmpty = false 
    for (const [key, value] of Object.entries(providerInfromation)) {
      if(key === InputNames.MEDICAID || key === InputNames.NPI){ continue }
      if (value === "") {
        isEmpty = true
        setError(prev=>({ ...prev, [key]: "Required" }))
      }
    }
    if(isEmpty) return
    // console.log(providerInfromation)
    createNPIProvider();
  };

  return (
    <>
      {!isLoading ? (
        <Modal
          open={isOpened}
          onClose={onClose}
          sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <PrescribingProviderModalRoot>
            <PrescribingProviderModalRoot.Title>
              <Typography sx={{ fontSize: "18px", fontWeight: "500", color: "black" }}>
                Add Provider Information
              </Typography>
            </PrescribingProviderModalRoot.Title>
            <Box display={"flex"} gap={"20px"} flexDirection={"row"}>
              <PrescribingProviderModalRoot.InputMember
                name={InputNames.PROVIDER_FIRST_NAME}
                value={providerInfromation[InputNames.PROVIDER_FIRST_NAME]}
                onChange={handleChange}
                style={commonModalStyles.providerName}
                label="Provider First Name"
                placeHolder="Type First Name"
                error={error?.firstName}
              />
              <PrescribingProviderModalRoot.InputMember
                name={InputNames.PROVIDER_LAST_NAME}
                value={providerInfromation[InputNames.PROVIDER_LAST_NAME]}
                onChange={handleChange}
                style={commonModalStyles.providerName}
                label="Provider Last Name"
                placeHolder="Type Last Name"
                error={error?.lastName}
              />
            </Box>
            <Box display={"flex"} mt={4} flexDirection={"row"} gap={"20px"}>
              <Box display={"flex"} width={"100%"} flexDirection={"column"} gap={"20px"}>
                <PrescribingProviderModalRoot.InputMember
                  name={InputNames.NPI}
                  value={providerInfromation[InputNames.NPI]}
                  onChange={handleChange}
                  label="NPI"
                  placeHolder="Type NPI Number"
                  error={error?.npi}
                />
                <PrescribingProviderModalRoot.ButtonOutlined onClick={handleClose}>
                  Cancel
                </PrescribingProviderModalRoot.ButtonOutlined>
              </Box>
              <Box display={"flex"} width={"100%"} flexDirection={"column"} gap={"20px"}>
                <PrescribingProviderModalRoot.InputMember
                  name={InputNames.MEDICAID}
                  value={providerInfromation[InputNames.MEDICAID]}
                  onChange={handleChange}
                  label="State Medicaid Number"
                  placeHolder="Type Medicaid Number"
                  error={error?.stateMedicaidNumber}
                />
                <PrescribingProviderModalRoot.ButtonNormal onClick={onSave}>
                  Save
                </PrescribingProviderModalRoot.ButtonNormal>
              </Box>
            </Box>
          </PrescribingProviderModalRoot>
        </Modal>
      ) : (
        <FullPageLoadingScreen text="creating prescribing provider" />
      )}
    </>
  );
};

export default Add;
