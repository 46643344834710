import { API_SESSIONS } from "../../../../api/api";
import useApiQueryData from "../../../../api/hooks/use_api_query_data";
import XNGApiQueryParamObject from "../../../../types/xng_api_query_param_object";

type Data = Awaited<ReturnType<typeof API_SESSIONS.v1SessionsUnpostedCountGet>>;
type QueryParams = {
  state: string;
  userId: string;
  clientId: string;
};

const useApiQueryUnpostedSessionsCount = (
  paramObject: XNGApiQueryParamObject<QueryParams, Data>,
) => {
  const { state, userId, clientId } = paramObject.queryParams;
  return useApiQueryData({
    queryFn: async () => await API_SESSIONS.v1SessionsUnpostedCountGet(state, userId, clientId),
    queryKey: ["v1SessionsUnpostedCountGet", state, userId, clientId],
    staleTime: 1000 * 60 * 2,
    gcTime: 1000 * 60 * 60 * 2,
    ...(paramObject.options ?? {}),
  });
};

export default useApiQueryUnpostedSessionsCount;
