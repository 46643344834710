import * as yup from "yup";

const trainingInfoSchema = yup.object({
    certificateSent: yup.boolean().required(),
    trainingDate: yup.date().required("Training Date is required"),
    trainingType: yup.string().required("Training Type is required"),
});

const trainingInfoFormSchema = yup.object({
    trainingInfoLog: yup.array().of(trainingInfoSchema).required(),
});

type TrainingInfoFormType = yup.InferType<typeof trainingInfoFormSchema>;

export type { TrainingInfoFormType };
export { trainingInfoFormSchema };