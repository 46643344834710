import { Box, Stack, useTheme } from "@mui/material";
import { OverlayLayout } from "../low-level/overlay_layout";
import { LoadingResponseMessageTemplate } from "../templates/loading_response_message_template";

interface DataGridLoadingLayoutProps {
  isLoading: boolean;
  sizeAnchor: string;
  children: React.ReactNode;
  loadingContent?: React.ReactNode;
}

function DataGridLoadingLayout(props: Readonly<DataGridLoadingLayoutProps>) {
  const { palette } = useTheme();
  const loadingContent = props.loadingContent ?? <LoadingResponseMessageTemplate />;

  return (
    <OverlayLayout show={props.isLoading} overlayContent={loadingContent}>
      <Stack
        sx={{
          // DataGrids require absolute heights, so we'll use relative view-height.
          height: `calc(100vh - ${props.sizeAnchor})`,
          gap: "1rem",
        }}
      >
        {props.isLoading ? (
          <Box
            sx={{
              height: "inherit",
              width: "100%",
              bgcolor: palette.grey[100],
              borderRadius: ".25rem",
            }}
          />
        ) : (
          props.children
        )}
      </Stack>
    </OverlayLayout>
  );
}

export default DataGridLoadingLayout;
