import { useMemo } from "react";
import { NotatorObservation } from "../types/observation";
import { useNotatorTools } from "../../../tools";

const DEFAULT_OBSERVATIONS: string[] = [
  "Cooperative",
  "Uncooperative",
  "Responsive",
  "Unresponsive",
  "Disruptive",
];

/**
 * One-off hook that encapsulates the logic to derive easy-to-render observations in `observations.tsx`.
 */
export function useNotatorObservations(props: {selectedStudentIndex: number | undefined}) {
  const { draftSession, selectedStudentIndex } = useNotatorTools();
  const actualStudentIndex = props.selectedStudentIndex ? props.selectedStudentIndex : selectedStudentIndex;

  const checkedObservations = useMemo(() => {
    const checkedObs =
      draftSession.studentJournalList?.[actualStudentIndex].observationSection?.observations;
    if (!checkedObs) {
      throw new Error(
        "Student's observations section was undefined! See `observations.tsx` in the notator directory.",
      );
    }

    return checkedObs;
  }, [draftSession, actualStudentIndex]);

  const customObservations: NotatorObservation[] = useMemo(() => {
    return draftSession.sessionJournal!.customCareProvisionLedger!.observations!.map((label) => ({
      text: label,
      checked:
        draftSession.studentJournalList![
          actualStudentIndex
        ].observationSection!.observations?.includes(label) ?? false,
    }));
  }, [draftSession, actualStudentIndex]);

  const defaultObservations: NotatorObservation[] = useMemo(() => {
    return DEFAULT_OBSERVATIONS.map((lbl) => ({
      text: lbl,
      checked: checkedObservations.includes(lbl),
    }));
  }, [draftSession, actualStudentIndex]);

  return { default: defaultObservations, custom: customObservations };
}
