import { Stack, Typography } from "@mui/material";

export function DesignPhilosophy() {
  return (
    <>
      <Typography variant="h5">Overview</Typography>
      <Typography>
        Our design system, Fortitude, built on top of Material UI, was developed to enhance our
        wireframe-driven development process. Initially, a comprehensive design system was defined
        and is visible within Figma. While the Figma design system is thorough, the time constraints
        on our XNG project timeline, coupled with the extensive features offered by Material UI,
        guided us to focus on developing only the most essential elements. This approach shaped the
        current state of Fortitude, allowing us to optimize our resources and efficiently integrate
        the most valuable components.
      </Typography>

      <Typography variant="h5">Design Principles</Typography>
      <Typography variant="h6">
        <strong>1. Product Independence</strong>
      </Typography>
      <Typography>
        We design Fortitude components to be versatile and independent, allowing for seamless
        integration across different products. This flexibility is necessary for being able to reuse
        our design system in wireframe-driven development across a potential software suite.
      </Typography>
      <Typography variant="h6">
        <strong>2. SOLID Principles</strong>
      </Typography>
      <Stack>
        <Typography>
          SOLID principles are crucial in building a React component design system. At a broad
          glance:
        </Typography>
        <ul>
          <li>
            <b>S</b> The SRP implies that components should only do one very simple task, and larger
            components should be comprised of smaller components as per the React composition
            pattern.
          </li>
          <li>
            <b>O</b> The OCP means that the core, integral and singular usage of components should
            be closed to modification (like how a button should never lose its onClick handler), but
            open to extension (such as a styling override).
          </li>
          <li>
            <b>L</b> LSP means that components that do similar things should be interchangeable,
            particularly by having cohesive prop interfaces.
          </li>
          <li>
            <b>I</b> ISP states that interfaces should be minimal and specific to the needs of the
            client components, promoting focused and efficient dependencies. This means that all
            contracts should be crafted upfront to cater to specific, minimal requirements rather
            than being general-purpose. Consequently components should maintain specific contracts
            with core purposes that are closed to extension, ensuring stability and reducing the
            complexity of maintenance.
          </li>
          <li>
            <b>D</b> DIP states to depend on abstractions, not concretions. This is almost assumed
            in React by how much the framework implores you to have data flow from top to bottom. In
            practical terms, this means using props, context, or hooks to inject dependencies into
            components, thereby decoupling them from specific implementations.
          </li>
        </ul>
      </Stack>
      <Typography variant="h6">
        <strong>3. Further Principles</strong>
      </Typography>
      <Stack>
        <Typography>
          There are a couple more React-specific principles you will hear mentioned that are
          important to understand.
        </Typography>
        <ul>
          <li>
            <b>Standard Folder Convention</b> Visible{" "}
            <a
              target="_blank"
              href="https://msb-tlm.atlassian.net/wiki/spaces/XL/pages/399114249/MSB+s+React+Folder+Structuring+and+Naming+Convention"
            >
              here!
            </a>
          </li>
          <li>
            <b>Presentational Component Pattern</b> This pattern in React focuses on creating
            components that are solely responsible for the UI. They do not manage state or handle
            data logic but are concerned with how things look. These components receive data through
            props and trigger events upwards but do not directly interact with application logic,
            promoting reuse and separation of concerns.
          </li>
          <li>
            <b>Component Composition Pattern</b> This pattern is key to building scalable React
            applications. It involves structuring components so that they can be composed together
            like building blocks. This promotes flexibility and reusability by allowing more complex
            interfaces to be constructed from simpler, smaller components. The composition pattern
            encourages encapsulation and modular design, making code easier to manage and extend.
          </li>
          <li>
            <b>Minimal Dependency Management</b> No two libraries should do similar things. The
            quantity and size of our libraries are intentionally kept to a minimum in order to
            maintain rapid page load speeds for our users. We only introduce libraries based on
            proven necessity, weighing its benefits against performance impact and the resources
            expended to internally build the required functionality.
          </li>
        </ul>
      </Stack>
    </>
  );
}
