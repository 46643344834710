import { Box as MUIBox, BoxTypeMap } from "@mui/system";
import { DefaultComponentProps } from "@mui/types";

/**
 * ## (DEPRECATED) Please do not use!
 */
type CustomBoxProps = DefaultComponentProps<BoxTypeMap> & { name?: string };

/**
 * ## (DEPRECATED) Please do not use!
 */
export default function Box(boxProps: CustomBoxProps) {
  return <MUIBox {...boxProps}>{boxProps.children}</MUIBox>;
}
