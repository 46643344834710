import { API_SESSIONS } from "../../../../../api/api";
import { useEffect, useState } from "react";
import { useXNGSelector } from "../../../../../context/store";
import { selectStateInUS } from "../../../../../context/slices/stateInUsSlice";
import { Dayjs } from "dayjs";
import { GetMinutesSpentOnOrderedServiceAreaResponse } from "../../../../../session-sdk";

interface UseV1SessionGetMinutesSpentOnOrderedServiceAreaProps {
  studentID?: string;
  providerID?: string;
  serviceAreaID?: string;
  start?: Dayjs;
  end?: Dayjs;
}

function useV1SessionGetMinutesSpentOnOrderedServiceArea(
  props: UseV1SessionGetMinutesSpentOnOrderedServiceAreaProps,
) {
  const { studentID, providerID, serviceAreaID, start, end } = props;
  const stateInUS = useXNGSelector(selectStateInUS);

  const [res, setRes] = useState<GetMinutesSpentOnOrderedServiceAreaResponse | null>(null);

  useEffect(() => {
    fetchAndSet();

    async function fetchAndSet() {
      if (!(studentID && providerID && serviceAreaID && start && end)) return;

      const _res = await API_SESSIONS.v1SessionsGetMinutesSpentOnOrderedServiceAreaGet(
        studentID,
        providerID,
        serviceAreaID,
        start.toDate(),
        end.toDate(),
        stateInUS,
      );

      setRes(_res);
    }
  }, [props.studentID, props.providerID, props.serviceAreaID]);

  return res;
}

export default useV1SessionGetMinutesSpentOnOrderedServiceArea;
