export function formatDate(dateString: string): string {
    // Create a Date object from the date string
    const date = new Date(dateString);

    // Extract date components
    const month: number = date.getMonth() + 1; // getMonth() returns month from 0 to 11
    const day: number = date.getDate();
    const year: number = date.getFullYear();

    // Extract time components
    let hours: number = date.getHours();
    const minutes: number = date.getMinutes();
    const seconds: number = date.getSeconds();

    // Determine AM/PM and adjust hours for 12-hour format
    const ampm: string = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Pad minutes and seconds with leading zeros if necessary
    const minutesStr: string = minutes < 10 ? '0' + minutes : minutes.toString();
    const secondsStr: string = seconds < 10 ? '0' + seconds : seconds.toString();

    // Construct the formatted date string
    const formattedDate: string = `${month}/${day}/${year} ${hours}:${minutesStr}:${secondsStr} ${ampm}`;
    return formattedDate;
}

 


export function getFormattedDateTime(dateString: string): { date: string, time: string } {
    const date = new Date(dateString);
    
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() returns 0-11, so add 1
    const day = date.getDate();
  
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
  
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedDate = `${formattedMonth}/${formattedDay}/${year}`;
  
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
  
    return { date: formattedDate, time: formattedTime };
  }