import { API_USERS } from "../../../../../../api/api";
import useApiQueryData from "../../../../../../api/hooks/use_api_query_data";
import XNGApiQueryParamObject from "../../../../../../types/xng_api_query_param_object";

type Data = Awaited<ReturnType<typeof API_USERS.v1UsersUserManagementCardsGet>>;
type QueryParams = {
  clientId: string;
  state: string;
};

const useApiQueryUserManagementCards = (paramObject: XNGApiQueryParamObject<QueryParams, Data>) => {
  const { clientId, state } = paramObject.queryParams;
  return useApiQueryData({
    queryFn: async () => await API_USERS.v1UsersUserManagementCardsGet(clientId, state),
    queryKey: ["v1UsersUserManagementCardsGet", clientId, state],
    ...(paramObject.options ?? {}),
  });
};

export default useApiQueryUserManagementCards;
