import { ServiceProviderRef, StudentRef } from "../../../../profile-sdk";
import UnpostedSessionsViewState, {
  SELECT_SERVICE_PROVIDERS_ACTION,
  SessionWithProviderId,
  UnpostedSessionsViewAction,
} from "../../types/unposted_sessions_view_state";
import produce from "immer";
import useApiQueryUnpostedSessionsData from "../../hooks/api/use_api_query_unposted_sessions_data";
import { SessionSlimCard } from "../../../../session-sdk";
import removeArrayDuplicates from "../../../../utils/remove_array_duplicates";

function unpostedSessionsViewStateReducer(
  state: UnpostedSessionsViewState,
  action: UnpostedSessionsViewAction,
): UnpostedSessionsViewState {
  switch (action.type) {
    case "select_service_providers": {
      return selectServiceProvidersACTION(state, action);
    }
    case "select_students": {
      const newState = produce(state, (draftState) => {
        draftState.selectedStudents = action.payload.students;
        updateSelectedServiceProvidersFilteredSessions(
          state,
          draftState,
          action.payload.unpostedSessionsApiQueryClientManager,
        );
        updateViewFilteredSessionIndex(draftState, draftState.currentSessionIndex);
      });
      return newState;
    }
    case "update_state": {
      const newState = produce(state, (draftState) => {
        if (
          action.payload.serviceProviderOptions.length !== draftState.serviceProviderOptions.length
        ) {
          draftState.serviceProviderOptions = action.payload.serviceProviderOptions;
          //populate total provider unposted sessions
          updateServiceProviderSelectionsOnOptionsChange(state, draftState);

        }
        updateStudentOptions(
          state,
          draftState,
          action.payload.unpostedSessionsApiQueryClientManager,
        );
        updateSelectedServiceProvidersFilteredSessions(
          state,
          draftState,
          action.payload.unpostedSessionsApiQueryClientManager,
        );

      });
      return newState;
    }
    case "update_view_filtered_session_index": {
      const newState = produce(state, (draftState) => {
        const newIndex = draftState.currentSessionIndex + action.payload;
        return updateViewFilteredSessionIndex(draftState, newIndex);
      });
      return newState;
    }
    case "set_current_session_in_notator_view": {
      const newState = produce(state, (draftState) => {
        const newIndex = action.payload.index ?? draftState.allFilteredSessions.findIndex(
          (sessionRef) =>
            sessionRef.session.id === action.payload.id &&
            sessionRef.session.startTime === action.payload.startTime &&
            sessionRef.session.seriesId === action.payload.seriesId,
        );
        return updateViewFilteredSessionIndex(draftState, newIndex);
      });
      return newState;
    }
    case "initialize_state": {
      const newState = produce(state, (draftState) => {
        return unpostedSessionsViewStateInitializer(action.payload);
      });
      if (action.payload.defaultSelected && action.payload.defaultSelected.length > 0) {
        const defaultSelectedState = selectServiceProvidersACTION(newState, {
          type: "select_service_providers",
          payload: {
            serviceProviders: action.payload.defaultSelected,
            unpostedSessionsApiQueryClientManager: action.payload.unpostedSessionsApiQueryClientManager,
          }
        })
        return defaultSelectedState;
      }

      return newState;
    }
    default:
      return state;
  }
}

type ClientManager = ReturnType<typeof useApiQueryUnpostedSessionsData>;


function selectServiceProvidersACTION(state: UnpostedSessionsViewState, action: SELECT_SERVICE_PROVIDERS_ACTION): UnpostedSessionsViewState {
  const newState = produce(state, (draftState) => {
    draftState.selectedServiceProviders = action.payload.serviceProviders;
    if (draftState.selectedServiceProviders.length === 0) {
      draftState.selectedStudents = [];
    }
    else if (draftState.selectedServiceProviders.length === draftState.serviceProviderOptions.length) {
      draftState.filteredStudentOptions = draftState.studentOptions;
      draftState.selectedStudents = draftState.studentOptions;
    }
    else {
      updateStudentSelectionOnProviderselection(
        state,
        draftState,
        action.payload.unpostedSessionsApiQueryClientManager,
      );
    }
    draftState.selectedServiceProvidersFilteredSessions.clear();
    updateSelectedServiceProvidersFilteredSessions(
      state,
      draftState,
      action.payload.unpostedSessionsApiQueryClientManager,
    ); //resolve student selection before calling this
    updateViewFilteredSessionIndex(draftState, draftState.currentSessionIndex);
  });
  return newState;
}


function unpostedSessionsViewStateInitializer(props: {
  serviceProviderOptions: ServiceProviderRef[];
  unpostedSessionsApiQueryClientManager: ClientManager;
}): UnpostedSessionsViewState {
  const serviceProviderOptions = props.serviceProviderOptions;
  const studentOptions = props.unpostedSessionsApiQueryClientManager.data?.students ?? [];
  const selectedServiceProviders = serviceProviderOptions;
  const selectedStudents = studentOptions;
  const selectedServiceProvidersFilteredSessions = new Map<string, SessionSlimCard[]>();

  selectedServiceProviders.forEach((provider) => {
    const sessions =
      props.unpostedSessionsApiQueryClientManager.data?.providerUnpostedSessionsDictionary?.[
      provider.id!
      ] ?? [];
    selectedServiceProvidersFilteredSessions.set(provider.id!, sessions);
  });
  let allFilteredSessions: SessionWithProviderId[] = [];
  const totalProviderUnpostedSessions = new Map<string, number>(
    serviceProviderOptions.map((provider) => {
      const sessions =
        props.unpostedSessionsApiQueryClientManager.data?.providerUnpostedSessionsDictionary?.[
        provider.id!
        ] ?? [];
      return [provider.id!, sessions.length];
    }),
  );

  selectedServiceProvidersFilteredSessions.forEach((sessions, providerId) => {
    allFilteredSessions.push(...sessions.map((session) => ({ session, providerId })));
  });
  return {
    hasInitializedState: props.unpostedSessionsApiQueryClientManager.isSuccess || props.serviceProviderOptions.length === 0,
    filteredStudentOptions: studentOptions,
    currentSessionIndex: 0,
    currentSessionInNotatorView:
      allFilteredSessions.length > 0 ? allFilteredSessions[0] : undefined,
    totalProviderUnpostedSessions,
    allFilteredSessions,
    selectedServiceProvidersFilteredSessions,
    serviceProviderOptions,
    studentOptions,
    selectedServiceProviders,
    selectedStudents,
  };
}

function updateSelectedServiceProvidersFilteredSessions(
  prevState: UnpostedSessionsViewState,
  draftState: UnpostedSessionsViewState,
  unpostedSessionsApiQueryClientManager: ClientManager,
): UnpostedSessionsViewState {
  draftState.selectedServiceProviders.forEach((provider) => {
    const sessions =
      unpostedSessionsApiQueryClientManager.data?.providerUnpostedSessionsDictionary?.[
      provider.id!
      ] ?? [];
    const filteredSessions = sessions.filter((session) => {
      return sessionContainsSelectedStudents(session, draftState.selectedStudents);
    });
    draftState.selectedServiceProvidersFilteredSessions.set(provider.id!, filteredSessions);
  });

  draftState.allFilteredSessions = [];
  draftState.selectedServiceProviders.forEach((provider) => {
    const filteredSessions = draftState.selectedServiceProvidersFilteredSessions.get(provider.id!) ?? [];

    draftState.allFilteredSessions.push(
      ...filteredSessions.map((session) => ({ session, providerId: provider.id! })),
    );
  });
  draftState.allFilteredSessions =
    draftState.selectedServiceProvidersFilteredSessions.size > 0
      ? draftState.allFilteredSessions
      : [];

  draftState.totalProviderUnpostedSessions = new Map<string, number>(
    draftState.serviceProviderOptions.map((provider) => {
      const sessions =
        unpostedSessionsApiQueryClientManager.data
          ?.providerUnpostedSessionsDictionary?.[provider.id!] ?? [];
      return [provider.id!, sessions.length];
    }),
  );
  updateViewFilteredSessionIndex(draftState, draftState.currentSessionIndex);
  return draftState;

  function sessionContainsSelectedStudents(
    session: SessionSlimCard,
    selectedStudents: StudentRef[],
  ): boolean {
    return selectedStudents.some((student) => session.students?.some((s) => s.id === student.id));
  }
}

function updateServiceProviderSelectionsOnOptionsChange(
  prevState: UnpostedSessionsViewState,
  draftState: UnpostedSessionsViewState,
): UnpostedSessionsViewState {
  if (prevState.serviceProviderOptions.length > draftState.serviceProviderOptions.length) {
    // we are more than likely going to be removing service providers
    let newlyRemovedProviderOptions = prevState.serviceProviderOptions.filter((provider) => {
      return !draftState.serviceProviderOptions.some((p) => p.id === provider.id);
    });
    if (newlyRemovedProviderOptions.length > 0) {
      //we remove provider from removed selections & options

      //only remove newly removed provider from providers selected
      draftState.selectedServiceProviders = draftState.selectedServiceProviders.filter(
        (provider) => {
          return draftState.serviceProviderOptions.some((p) => p.id === provider.id);
        },
      );
    }
  } else if (prevState.serviceProviderOptions.length < draftState.serviceProviderOptions.length) {
    // we are more than likely going to be adding service providers
    let newlyAddedProviderOptions = draftState.serviceProviderOptions.filter((provider) => {
      return !prevState.serviceProviderOptions.some((p) => p.id === provider.id);
    });
    if (newlyAddedProviderOptions.length > 0) {
      //we add provider from added selections & options
      draftState.selectedServiceProviders =
        draftState.selectedServiceProviders.concat(newlyAddedProviderOptions);
    }
  }

  return draftState;
}

function updateStudentOptions(
  prevState: UnpostedSessionsViewState,
  draftState: UnpostedSessionsViewState,
  unpostedSessionsApiQueryClientManager: ClientManager,
): UnpostedSessionsViewState {
  if (prevState.serviceProviderOptions.length > draftState.serviceProviderOptions.length) {
    const newStudentOptions = unpostedSessionsApiQueryClientManager.data?.students ?? [];
    //remove students that are not in student options
    draftState.filteredStudentOptions = draftState.filteredStudentOptions.filter((student) => {
      return newStudentOptions.some((s) => s.id === student.id);
    });
    draftState.selectedStudents = draftState.selectedStudents.filter((student) => {
      return draftState.filteredStudentOptions.some((s) => s.id === student.id);
    });
    draftState.studentOptions = newStudentOptions;
  } else if (prevState.serviceProviderOptions.length < draftState.serviceProviderOptions.length) {
    let newlyAddedProviderOptions = draftState.serviceProviderOptions.filter((provider) => {
      return !prevState.serviceProviderOptions.some((p) => p.id === provider.id);
    });
    let unAddedProviderOptions = prevState.serviceProviderOptions.filter((provider) => {
      return draftState.serviceProviderOptions.some((p) => p.id === provider.id);
    }); //represents providers that already existed in options array
    if (newlyAddedProviderOptions.length > 0) {
      //we add students from added selections & filtered options
      const newlyAddedStudentOptionsWithDuplicates =
        unpostedSessionsApiQueryClientManager.data?.providerUnpostedSessionsDictionary?.[
          newlyAddedProviderOptions[0].id!
        ]
          ?.map((sessionRef) => sessionRef.students ?? [])
          .flat() ?? [];
      let newlyAddedProvidersStudents = removeArrayDuplicates(
        newlyAddedStudentOptionsWithDuplicates,
        () => "id",
      );
      let UnaddedProviderStudentsWithPotentialDuplicates = unAddedProviderOptions
        .map((provider) => {
          return (
            unpostedSessionsApiQueryClientManager.data?.providerUnpostedSessionsDictionary?.[
              provider.id!
            ]
              ?.map((sessionRef) => sessionRef.students ?? [])
              .flat() ?? []
          );
        })
        .flat();
      let unAddedProvidersStudents = removeArrayDuplicates(
        UnaddedProviderStudentsWithPotentialDuplicates,
        () => "id",
      );

      // students not overlapping are the ones we want to add
      let studentsNotOverlappingWithAddedProvider = newlyAddedProvidersStudents.filter(
        (student) => {
          return !unAddedProvidersStudents.some((s) => s.id === student.id);
        },
      );

      draftState.filteredStudentOptions = draftState.filteredStudentOptions.concat(
        studentsNotOverlappingWithAddedProvider,
      );
      draftState.selectedStudents = draftState.selectedStudents.concat(
        studentsNotOverlappingWithAddedProvider,
      );
      //select newly added students
    }
  }

  return draftState;
}

function updateStudentSelectionOnProviderselection(
  prevState: UnpostedSessionsViewState,
  draftState: UnpostedSessionsViewState,
  unpostedSessionsApiQueryClientManager: ClientManager,
): UnpostedSessionsViewState {
  if (prevState.selectedServiceProviders.length > draftState.selectedServiceProviders.length) {
    // we are more than likely going to be removing students
    let newlyRemovedProviderSelections = prevState.selectedServiceProviders.filter((provider) => {
      return !draftState.selectedServiceProviders.some((p) => p.id === provider.id);
    });
    let unRemovedProviderSelections = draftState.selectedServiceProviders.filter((provider) => {
      return prevState.selectedServiceProviders.some((p) => p.id === provider.id);
    });
    if (newlyRemovedProviderSelections.length > 0) {
      //we remove students from removed selections & filtered options
      const filteredOptionsWithPotentialDuplicates =
        unpostedSessionsApiQueryClientManager?.data?.providerUnpostedSessionsDictionary?.[
          newlyRemovedProviderSelections[0].id!
        ]
          ?.map((sessionRef) => sessionRef.students ?? [])
          .flat() ?? [];
      let newlyRemovedProvidersStudents = removeArrayDuplicates(
        filteredOptionsWithPotentialDuplicates,
        () => "id",
      );
      let UnremovedProviderStudentsWithPotentialDuplicates = unRemovedProviderSelections
        .map((provider) => {
          return (
            unpostedSessionsApiQueryClientManager?.data?.providerUnpostedSessionsDictionary?.[
              provider.id!
            ]
              ?.map((sessionRef) => sessionRef.students ?? [])
              .flat() ?? []
          );
        })
        .flat();
      let unRemovedProvidersStudents = removeArrayDuplicates(
        UnremovedProviderStudentsWithPotentialDuplicates,
        () => "id",
      );

      // students not overlapping are the ones we want to remove
      let studentsNotOverlappingWithRemovedProvider = newlyRemovedProvidersStudents.filter(
        (student) => {
          return !unRemovedProvidersStudents.some((s) => s.id === student.id);
        },
      );

      draftState.filteredStudentOptions = draftState.filteredStudentOptions.filter((student) => {
        return !studentsNotOverlappingWithRemovedProvider.some((s) => s.id === student.id);
      });
      draftState.selectedStudents = draftState.selectedStudents.filter((student) => {
        return draftState.filteredStudentOptions.some((s) => s.id === student.id);
      });
    }
  } else if (
    prevState.selectedServiceProviders.length < draftState.selectedServiceProviders.length
  ) {
    // we are more than likely going to be adding students
    let newlyAddedProviderSelections = draftState.selectedServiceProviders.filter((provider) => {
      return !prevState.selectedServiceProviders.some((p) => p.id === provider.id);
    });
    let unAddedProviderSelections = prevState.selectedServiceProviders.filter((provider) => {
      return draftState.selectedServiceProviders.some((p) => p.id === provider.id);
    });
    if (newlyAddedProviderSelections.length > 0) {
      //we add students from added selections & filtered options
      const filteredOptionsWithPotentialDuplicates =
        unpostedSessionsApiQueryClientManager.data?.providerUnpostedSessionsDictionary?.[
          newlyAddedProviderSelections[0].id!
        ]
          ?.map((sessionRef) => sessionRef.students ?? [])
          .flat() ?? [];
      let newlyAddedProvidersStudents = removeArrayDuplicates(
        filteredOptionsWithPotentialDuplicates,
        () => "id",
      );
      let UnaddedProviderStudentsWithPotentialDuplicates = unAddedProviderSelections
        .map((provider) => {
          return (
            unpostedSessionsApiQueryClientManager.data?.providerUnpostedSessionsDictionary?.[
              provider.id!
            ]
              ?.map((sessionRef) => sessionRef.students ?? [])
              .flat() ?? []
          );
        })
        .flat();
      let unAddedProvidersStudents = removeArrayDuplicates(
        UnaddedProviderStudentsWithPotentialDuplicates,
        () => "id",
      );

      // students not overlapping are the ones we want to add
      let studentsNotOverlappingWithAddedProvider = newlyAddedProvidersStudents.filter(
        (student) => {
          return !unAddedProvidersStudents.some((s) => s.id === student.id);
        },
      );

      draftState.filteredStudentOptions = draftState.filteredStudentOptions.concat(
        studentsNotOverlappingWithAddedProvider,
      );
      draftState.selectedStudents = draftState.selectedStudents.concat(
        studentsNotOverlappingWithAddedProvider,
      );
      //select newly added students
    }
  }

  return draftState;
}

function updateViewFilteredSessionIndex(
  draftState: UnpostedSessionsViewState,
  newIndex: number,
): UnpostedSessionsViewState {
  if (newIndex < 0) {
    draftState.currentSessionIndex = draftState.allFilteredSessions.length - 1;
  } else if (newIndex >= draftState.allFilteredSessions.length) {
    draftState.currentSessionIndex = 0;
  } else {
    draftState.currentSessionIndex = newIndex;
  }

  draftState.currentSessionInNotatorView =
    draftState.allFilteredSessions.length > 0
      ? draftState.allFilteredSessions[draftState.currentSessionIndex]
      : undefined;
  return draftState;
}

export { unpostedSessionsViewStateInitializer };
export default unpostedSessionsViewStateReducer;
