import { useEffect, useState } from "react";
import { DistrictProfile } from "../../../profile-sdk";
import sessionStorageKeys from "../../../browser/sessionStorageKeys";
import { API_DISTRICTS } from "../../../api/api";

/**
 * TODO: Move this file to a `/manager/hooks` directory
 */
export default function useDistrictsFetchAndSet(state: string) {
  const districtsKey: DistrictProfile[] | null = JSON.parse(
    sessionStorage.getItem(sessionStorageKeys.ALL_DISTRICTS)!,
  );

  const [districts, setDistricts] = useState<DistrictProfile[] | null>(null);

  useEffect(() => {
    async function fetchAndSetAllDistricts() {
      if (state === "NH" && districtsKey === null) {
        const districtsResponse = await API_DISTRICTS.v1DistrictsGet(state);
        setDistricts(districtsResponse?.districts || null);
        sessionStorage.setItem("allDistricts", JSON.stringify(districtsResponse.districts));
      } else {
        setDistricts(districtsKey);
      }
    }

    fetchAndSetAllDistricts();
  }, []);

  return { districts };
}
