import dayjs, { Dayjs } from "dayjs";
import { ScheduleOfServicesInterval } from "../../../../../profile-sdk";
import { NonSchoolDaysContainer } from "../../../../../browser/non_school_days/district_non_school_days";
import weekday from "dayjs/plugin/weekday";
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(weekday);
dayjs.extend(isBetween);

type DateRange = { start: Dayjs; end: Dayjs };

/**
 * Calculates how many times a session should have occurred between two dates using a specified frequency (AKA: interval).
 */
function getTotalSessionOccurrencesBetweenDates(
  startDate: Dayjs,
  endDate: Dayjs,
  interval: ScheduleOfServicesInterval,
  nonSchoolDaysContainer: NonSchoolDaysContainer,
): number {
  const daysToIncrementBy = scheduleOfServicesIntervalEnumToNumberRecord[interval];
  let occurrences = 0;
  let currentDate = startDate.clone();

  // Pre-process non-school days as flat list to account for ranges
  const nonSchoolPeriods: DateRange[] = [];
  nonSchoolDaysContainer.districts.forEach((district) => {
    district.nonSchoolDays.forEach((nonSchoolDay) => {
      if (nonSchoolDay.startTime && nonSchoolDay.endTime) {
        nonSchoolPeriods.push({
          start: dayjs(nonSchoolDay.startTime),
          end: dayjs(nonSchoolDay.endTime),
        });
      }
    });
  });

  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
    if (currentDate.weekday() !== 0 && currentDate.weekday() !== 6) {
      // Check if it's not a weekend
      let isNonSchoolDay = false;
      for (const period of nonSchoolPeriods) {
        if (currentDate.isBetween(period.start, period.end, null, "[]")) {
          // Check if the current day is within any non-school period
          isNonSchoolDay = true;
          break;
        }
      }

      if (!isNonSchoolDay) {
        occurrences++;
      }
    }
    currentDate = currentDate.add(daysToIncrementBy, "day");
  }

  return occurrences;
}

const scheduleOfServicesIntervalEnumToNumberRecord: Record<ScheduleOfServicesInterval, number> = {
  [ScheduleOfServicesInterval.NUMBER_0]: 1, // Daily
  [ScheduleOfServicesInterval.NUMBER_1]: 7, // Weekly
  [ScheduleOfServicesInterval.NUMBER_2]: 14, // Biweekly
  [ScheduleOfServicesInterval.NUMBER_3]: 30, // Monthly
  [ScheduleOfServicesInterval.NUMBER_4]: 60, // Bimonthly
  [ScheduleOfServicesInterval.NUMBER_5]: 1, // Once per timespan
};

export default getTotalSessionOccurrencesBetweenDates;
