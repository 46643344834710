import { MSBIconRenderer, MSBICONS, MSBSearchMultiselect } from "../../../../fortitude";
import { Box, Stack } from "@mui/material";
import msbMUIAutoCompleteFilterOptions from "../../../../utils/msb_mui_auto_complete_filter_options";
import useUnpostedSessionsViewState from "../../hooks/use_unposted_sessions_view_state";

type Props = {
  viewState: ReturnType<typeof useUnpostedSessionsViewState>;
  disable?: boolean;
};

const UnpostedSessionsFilter = (props: Props) => {
  const { Filter } = MSBICONS;
  return (
    <Box
      py={1}
      sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        justifyContent: {
          xs: "center",
          sm: "start",
        },
        alignItems: "center",
        gap: 2,
      }}
    >
      <Stack direction={"row"} alignItems={"center"} gap={1} width={"100%"} maxWidth={"350px"}>
        <MSBIconRenderer i={<Filter />} size="sm" />
        <MSBSearchMultiselect
          disabled={props.disable}
          selectedOptions={
            props.viewState.state.selectedServiceProviders.map((p) => JSON.stringify(p)) ?? []
          }
          options={props.viewState.state.serviceProviderOptions.map((p) => JSON.stringify(p)) ?? []}
          getOptionLabel={(serviceProvider) => {
            const serviceProviderObj = JSON.parse(serviceProvider);
            return `${serviceProviderObj?.firstName} ${serviceProviderObj?.lastName}`;
          }}
          onChange={(newServiceProviders) => {
            props.viewState.dispatch({
              type: "select_service_providers",
              payload: {
                serviceProviders: newServiceProviders.map((p) => JSON.parse(p)),
                unpostedSessionsApiQueryClientManager: props.viewState.apiQueryClientManager,
              },
            });
          }}
          renderOptionVariant="checkbox"
          variant="no overflow after 1"
          isOptionEqualToValue={(option, value) => option === value}
          label="Service Provider(s)"
          sx={{ backgroundColor: "white", maxWidth: "320px" }}
          autocompleteProps={{
            fullWidth: true,
            disableCloseOnSelect: true,
            filterOptions: msbMUIAutoCompleteFilterOptions(),
          }}
        />
      </Stack>
      <MSBSearchMultiselect
        selectedOptions={props.viewState.state.selectedStudents}
        options={props.viewState.state.filteredStudentOptions}
        disabled={props.disable}
        getOptionLabel={(selectedStudent) => {
          return `${selectedStudent?.firstName} ${selectedStudent?.lastName}`;
        }}
        onChange={(newStudents) => {
          props.viewState.dispatch({
            type: "select_students",
            payload: {
              students: newStudents,
              unpostedSessionsApiQueryClientManager: props.viewState.apiQueryClientManager,
            },
          });
        }}
        renderOptionVariant="checkbox"
        variant="no overflow after 1"
        isOptionEqualToValue={(option, value) => option === value}
        label="Student(s)"
        sx={{ backgroundColor: "white", maxWidth: "320px" }}
        autocompleteProps={{
          fullWidth: true,
          disableCloseOnSelect: true,
          filterOptions: msbMUIAutoCompleteFilterOptions(),
        }}
      />
    </Box>
  );
};

export default UnpostedSessionsFilter;
