import { Box, FormControl, FormHelperText, Input, Typography } from "@mui/material";
import React from "react"; 
import './index.css'
import { PrescribingProviderActionButton } from "../../Buttons";


export const prescribingProviderModalRootStyles= {
  inputLabel : {
    position: 'absolute',
    top: '-6px',
    left: '10px',
    fontSize: '10px !important',
    fontWeight: 400,
    height: '21px',
    letterSpacing: '0.02em',
    color: 'rgba(0, 0, 0, 0.6)',
    backgroundColor: 'white',
    padding: '0px 20px 0px 10px',
    userSelect: 'none'
  },
  inputWrapper:{
    width: '100%',
    height: '48px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    position: 'relative'
  }
  
}


interface InputMemberProps { 
    label: string;
    value?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    name?: string;
    className?:string 
    placeHolder?:string,
    style?: React.CSSProperties,
    error?:string;
    required?:boolean
}

interface BtnProps {
    onClick?: () => void;
    children?: React.ReactNode;
    className?:string
  }
// Define an interface that extends the default FC type
interface ProviderModalRootType extends React.FC<{ children: React.ReactNode,className?:string,height?:number,width?:number,bgcolor?:string }> {
  Title: React.FC<{ children: React.ReactNode }>;
  InputMember: React.FC<InputMemberProps>;
  ButtonOutlined: React.FC<BtnProps>;
  ButtonNormal: React.FC<BtnProps>;
}



const ProviderModalRoot: ProviderModalRootType = ({ children ,className,height=277,width=600,bgcolor="white"}) => {
  return (<Box display={"flex"} alignItems={"center"} justifyContent={"center"} minHeight={height} width={width} bgcolor={bgcolor}>
                <Box sx={{width:"calc(100% - 40px)", height:"calc(100% - 40px)",flexShrink:0 }}> {children} </Box> 
          </Box>);
};

const Title: React.FC<{ children: React.ReactNode }> = ({children }) => {
  return <Box sx={{display:"flex", alignItems:"center"}}>{children}</Box>;
};



const InputMember: React.FC<InputMemberProps> = ({ label,placeHolder,error, value, name, style, onChange }) => {
 

  return (
    <FormControl sx={{width:"100%"}}>
        <Box sx={{...prescribingProviderModalRootStyles.inputWrapper,...style}}> 
          <Typography sx={prescribingProviderModalRootStyles.inputLabel}>
            {label}
          </Typography>
          <Input sx={{width:"100%",height:"100%",paddingLeft:"10px",outline:"none",border:"none",
          '&:before': {
            display: 'none !important', 
          },
          '&:after': {
            display: 'none !important', 
          },}} value={value} placeholder={placeHolder}  onChange={onChange} name={name} />
        </Box>
        {
            error && (
              <FormHelperText  sx={{ marginInline: 0 }} error={!!error}>
                {error}
              </FormHelperText>
            )
          }
    </FormControl>
  );
};

const ButtonOutlined: React.FC<BtnProps> = ({
  onClick,
  children,
  className
}) => {
  return ( 
    <PrescribingProviderActionButton variant="outlined" sx={{height:"28px !important"}} onClick={onClick}>
      {children || "Outlined Button"}
    </PrescribingProviderActionButton>
  );
};

const  ButtonNormal: React.FC<BtnProps> = ({
  onClick,
  children,
  className
}) => {
  return     <PrescribingProviderActionButton variant="contained" sx={{height:"28px !important"}} onClick={onClick}>
  {children || "Outlined Button"}
</PrescribingProviderActionButton>
};

ProviderModalRoot.Title = Title;
ProviderModalRoot.InputMember = InputMember;
ProviderModalRoot.ButtonOutlined = ButtonOutlined;
ProviderModalRoot.ButtonNormal =  ButtonNormal;

export default ProviderModalRoot;
