import { Accordion, AccordionDetails, AccordionSummary, Box, Skeleton } from "@mui/material";
import { MSBICONS } from "../../../../fortitude";

const UnpostedSessionsCardSkeleton = () => {
  return (
    <Box
      sx={{
        maxWidth: "250px",
        minWidth: "240px",
      }}
    >
      <CardHeader />
      <CardBody />
    </Box>
  );
};

function CardHeader() {
  return (
    <Box
      sx={{
        borderRadius: "5px",
        border: "1px solid",
        borderColor: "contrasts.2",
        display: "flex",
        flexDirection: "column",
        padding: "15px",
        gap: "5px",
        mb: "3px",
      }}
    >
      <Skeleton variant="text" width={"40%"} />
      <Skeleton variant="text" height={"80px"} width={"60px"} sx={{ mx: "auto" }} />
      <Skeleton variant="text" width={"70%"} sx={{ mx: "auto" }} />
      <Skeleton variant="text" />
    </Box>
  );
}

function CardBody() {
  const { DownChevron } = MSBICONS;
  return (
    <Box>
      <Accordion disableGutters elevation={0} square expanded>
        <AccordionSummary
          expandIcon={<DownChevron />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            px: "0px",
            minHeight: "20px",
            borderBottom: "2px solid",
            borderColor: "contrasts.2",
          }}
        ></AccordionSummary>
        <AccordionDetails
          sx={{
            px: "0px",
            maxHeight: "450px",
            overflowY: "auto",
          }}
        >
          {Array.from({ length: 10 }).map((session, i) => {
            return (
              <Box
                key={crypto.randomUUID()}
                component={Skeleton}
                sx={{
                  border: "1px solid",
                  borderColor: "contrasts.2",
                  mb: "5px",
                  padding: "3px",
                }}
              ></Box>
            );
          })}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default UnpostedSessionsCardSkeleton;
