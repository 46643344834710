import { API_STUDENTS } from "../../../api/api";
import sessionStorageKeys from "../../../browser/sessionStorageKeys";
import { NonSchoolDay } from "../../../profile-sdk";
import { useState, useEffect } from "react";
import {
  DistrictNonSchoolDays,
  NonSchoolDaysContainer,
} from "../../../browser/non_school_days/district_non_school_days";
import { useNotatorTools } from "../tools";
import { useXNGSelector } from "../../../context/store";
import { selectStateInUS } from "../../../context/slices/stateInUsSlice";
import { selectClient, selectClientID } from "../../../context/slices/loggedInClientSlice";

interface NonSchoolDayList extends NonSchoolDay {
  district: string;
}

interface StudentWithNonSchoolDay {
  name: string;
  district: string;
  nonSchoolDay: NonSchoolDay | null;
}

export interface NonSchoolDaysFilter {
  nonSchoolDay: NonSchoolDay | null;
  studentsWithNonSchoolDays: StudentWithNonSchoolDay[];
}

export default function useNonSchoolDaysFilter(): NonSchoolDaysFilter {
  const { draftSession } = useNotatorTools();
  const state = useXNGSelector(selectStateInUS);
  const loggedInClientId = useXNGSelector(selectClientID);
  const loggedInClient = useXNGSelector(selectClient);

  const nonSchoolDaysKey: NonSchoolDaysContainer = JSON.parse(
    sessionStorage.getItem(sessionStorageKeys.NON_SCHOOL_DAYS)!,
  );

  const [nonSchoolDay, setNonSchoolDay] = useState<NonSchoolDay | null>(null);
  const [studentsWithNonSchoolDays, setStudentsWithNonSchoolDays] = useState<
    StudentWithNonSchoolDay[]
  >([]);

  function filterNonSchoolDays(nonSchoolDays: NonSchoolDay[]) {
    return nonSchoolDays.filter((day) => {
      // startTime is supposed to be a Date according to the api spec, but its actually a timestamp/string, so we cast it here
      let year = (day.startTime as unknown as string)?.slice(0, 10);
      return year === (draftSession.meetingDetails?.startTime as unknown as string)?.slice(0, 10);
    });
  }

  function filterDistricts(districts: DistrictNonSchoolDays[], studentDistricts: string[]) {
    return districts.filter((district: NonSchoolDayList) =>
      studentDistricts.includes(district.district),
    );
  }
  useEffect(() => {
    async function fetchAndSetStudentsWithNonSchoolDays() {
      if (loggedInClient?.additionalDistricts && loggedInClient.additionalDistricts.length > 0) {
        const studentsWithNonSchoolDays: StudentWithNonSchoolDay[] = [];
        const studentDistricts: string[] = [];
        const districtNames: string[] = [];

        // Fetch all students' districts
        for await (const studentJournal of draftSession.studentJournalList!) {
          const student = await API_STUDENTS.v1StudentsIdGet(
            studentJournal?.student?.id!,
            loggedInClientId!,
            state,
          );

          studentDistricts.push(student.districtOfLiabilityRecords?.[0].liableDistrict!.name!);

          studentsWithNonSchoolDays.push({
            name: student.firstName + " " + student.lastName,
            district: student.districtOfLiabilityRecords?.[0].liableDistrict!.name!,
            nonSchoolDay: null,
          });
        }

        // Filter out districts that don't belong to any students in the session
        const filteredDistricts = filterDistricts(nonSchoolDaysKey.districts, studentDistricts);

        // Find districts that have a non school day that matches the session's date
        filteredDistricts.forEach((district) => {
          district.nonSchoolDays = filterNonSchoolDays(district.nonSchoolDays);
          if (district.nonSchoolDays.length > 0) {
            districtNames.push(district.district);
          }
        });

        // Filter out students whose district does not have a non school day macthing the session date
        const filteredStudents = studentsWithNonSchoolDays.filter((student) =>
          districtNames.includes(student.district),
        );

        // Set the actual students with non school days
        filteredDistricts.forEach((district) => {
          filteredStudents.forEach((student) => {
            if (student.district === district.district) {
              student.nonSchoolDay = district.nonSchoolDays[0];
            }
          });
        });

        setStudentsWithNonSchoolDays(filteredStudents);
      } else {
        // This else is for regular clients with only one district
        const filteredNonSchoolDay = filterNonSchoolDays(
          nonSchoolDaysKey.districts[0].nonSchoolDays,
        );

        if (filteredNonSchoolDay && filteredNonSchoolDay.length > 0) {
          setNonSchoolDay(filteredNonSchoolDay[0]);
        }
      }
    }
    fetchAndSetStudentsWithNonSchoolDays();
  }, []);

  return { nonSchoolDay, studentsWithNonSchoolDays };
}
