import React from "react";
import { PrescribingProviderStateType } from "../../types/prescribing_provider_context_types";
import { PrescribingProviderAction } from "../prescribing_provider_actions";
import { prescribingProviderReducer } from "../reducer/prescribing_provider_reducer";
import PrescribingProviderContext from "../prescribing_provider_context";


const initialState: PrescribingProviderStateType = {
  data: [],
  error: undefined,
  loading: false,
  prescribingProvider: undefined,
  isEditing: false,
  isAdding: false,
  checkedPrescribingProviders:[]
}


const PrescribingProviderContextProvider:React.FC<{children: React.ReactNode}> = ({children}) => {
  const [state, dispatch] = React.useReducer<React.Reducer<PrescribingProviderStateType, PrescribingProviderAction>>(prescribingProviderReducer, initialState);
  const memoisedState = React.useMemo(()=>state,[state])
  const memoisedDispatch = React.useMemo(()=>dispatch,[])

  return (
    <PrescribingProviderContext.Provider value={{state:memoisedState,dispatch:memoisedDispatch}}>
        {children}
    </PrescribingProviderContext.Provider>
  )
}

export default PrescribingProviderContextProvider