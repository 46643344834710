import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import   './index.css'
import useFinancialDashboard from '../../../hooks/useFinancialDashboard'; 
import { dataGridHelper } from '../../../utils/data_grid_helper';
import SkeletonLoading from '../../skeletonLoading/SkeletonLoading'; 


export const RemitanceDataGrid:React.FC<{districtName:string}> =({districtName}) => {
  const data = useFinancialDashboard()?.state?.data?.remittanceScheduleReports 
  const isLoading = useFinancialDashboard()?.state?.loading 
  return (
    <Box width={"100%"} height={"100%"} >
      <Box sx={{display:"flex",alignItems:"center",backgroundColor:"#3E4C59",justifyContent:"center",borderRadius:"10px 10px 0 0" ,width:"100%",height:60}}>
         <Typography  sx={{ fontWeight: 'bold',fontSize:"20px",color:"white" }}>
            Remittance Data
         </Typography>
      </Box>
      {
        !isLoading && data ?  <DataGrid
        sx={{height:"fit-content"}}
        rows={ dataGridHelper.mountColumns().sanitizeRows(data,districtName as string).remittanceDataRow}
        columns={dataGridHelper.remittanceDataCol}
        hideFooterPagination
        hideFooter
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow data_grid_text_clr' : 'data_grid_text_clr'
        }  
        checkboxSelection={false}
        disableRowSelectionOnClick
        style={{padding:"0px 10px 40px 10px",fontWeight:"400",fontSize:"14px",color:"black",maxHeight:"98%",overflowY:"scroll" }}
      />:<SkeletonLoading width='100%' height='100%'/> 
      }
      
    </Box>
  );
};