import { LinearProgress, Stack, styled, Tooltip, Typography, useTheme } from "@mui/material";
import { MSBIconRenderer } from "../../../../../fortitude";
import { BiSolidMessageAltError } from "react-icons/bi";
import { FaRegClock } from "react-icons/fa";
import React from "react";

const ProgressWrapper = styled("div")({
  minWidth: "100%",
  minHeight: "min-content",
  display: "flex",
  alignItems: "center",
  gap: ".5rem",
});

export class StudentPlanOfCareProgress {
  /**
   * Interpolation of progress between 1-100
   */
  percentage: number;
  hasNotBeganYet: boolean;
  hasAlreadyCompleted: boolean;
  isError: { text: string } | null;

  constructor(data: {
    percentage?: number;
    hasNotBeganYet?: boolean;
    hasAlreadyCompleted?: boolean;
    isError?: { text: string };
  }) {
    this.percentage = data.percentage ?? 0;
    this.hasNotBeganYet = data.hasNotBeganYet ?? false;
    this.hasAlreadyCompleted = data.hasAlreadyCompleted ?? false;
    this.isError = data.isError ?? null;
  }
}

function StudentPlanOfCareProgressCell(props: Readonly<{ progress: StudentPlanOfCareProgress }>) {
  const { progress } = props;
  const { palette } = useTheme();

  if (progress.isError) {
    return (
      <CellTooltip title={progress.isError.text}>
        <ProgressWrapper>
          <MSBIconRenderer i={<BiSolidMessageAltError />} size="sm" color={palette.error.main} />
          <LinearProgress
            sx={{ display: "flex", flexGrow: 1 }}
            variant="determinate"
            color="error"
            value={100}
          />
        </ProgressWrapper>
      </CellTooltip>
    );
  }

  if (progress.hasNotBeganYet) {
    return (
      <CellTooltip title="Plan of care hasn't began yet">
        <ProgressWrapper>
          <MSBIconRenderer i={<FaRegClock />} size="sm" color={palette.primary.dark} />
          <LinearProgress
            sx={{ display: "flex", flexGrow: 1 }}
            variant="determinate"
            value={progress.percentage}
          />
        </ProgressWrapper>
      </CellTooltip>
    );
  }

  if (progress.hasAlreadyCompleted) {
    return (
      <ProgressWrapper>
        <Stack sx={{ width: "100%", gap: ".1rem" }}>
          <LinearProgress
            sx={{ display: "flex", flexGrow: 1 }}
            variant="determinate"
            color="success"
            value={100}
          />
          <Typography color={palette.success.main} variant="inherit">
            Complete!
          </Typography>
        </Stack>
      </ProgressWrapper>
    );
  }

  return (
    <ProgressWrapper>
      <LinearProgress
        sx={{ display: "flex", flexGrow: 1 }}
        variant="determinate"
        color="success"
        value={progress.percentage}
      />
    </ProgressWrapper>
  );
}

/**
 * For UX, provides a MUI Tooltip wrapper that ensures titles will not exceed 8rem as any higher width will cause the screen scroll rect will jitter when hovering.
 */
function CellTooltip(
  props: Readonly<{ title: React.ReactNode; children: React.ReactElement<any, any> }>,
) {
  return (
    <Tooltip
      title={
        <Stack
          sx={{
            maxWidth: "8rem",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          {props.title}
        </Stack>
      }
      placement="top"
      arrow
      disableInteractive
    >
      {props.children}
    </Tooltip>
  );
}

export default StudentPlanOfCareProgressCell;
