import { Typography } from "@mui/material";

/**
 * ## DEPRECATED
 *
 * Do not reuse or modify, replace references with MUI / Fortitude components in new development.
 *
 * See: https://msb-tlm.atlassian.net/wiki/spaces/XL/pages/508035073/MSB+Design+System+Fortitude#Note-for-Developers%3A-Deprecated-Design-System-Exists-in-XNG-Codebase
 */
export function Capitalize(props: { children: React.ReactNode }) {
  return (
    <Typography
      display={"inline-block"}
      variant="inherit"
      sx={{
        textTransform: "lowercase",
        ":first-letter": { textTransform: "uppercase" },
      }}
    >
      {props.children}
    </Typography>
  );
}
