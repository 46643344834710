import { useMemo } from "react";
import { CaseloadStudentDisplay, PlanOfCareType, StudentRef } from "../../../../../profile-sdk";
import { ProviderCaseloadListRow } from "../constants/columns";
import dayjs from "dayjs";
import { studentProfileGradeToString } from "../../../../../utils/add_student_grade_suffix";
import { createPlanOfCareProgress } from "../utils/get_student_plan_of_care_progress";
import { STUDENT_MANAGER_DATE_FORMAT_SHORT } from "../constants/student_manager_date_format";

const planOfCareTypeToString: Record<PlanOfCareType, string> = {
  [PlanOfCareType.NUMBER_0]: "IEP (Special Education)",
  [PlanOfCareType.NUMBER_1]: "Referral",
  [PlanOfCareType.NUMBER_2]: "504",
  [PlanOfCareType.NUMBER_3]: "RTI / MTSS",
  [PlanOfCareType.NUMBER_4]: "ELL",
  [PlanOfCareType.NUMBER_5]: "Other Plan of Care",
};

export function useGenerateStudentManagerRows(
  filteredStudents: CaseloadStudentDisplay[],
  failedStudentImports: StudentRef[],
): ProviderCaseloadListRow[] {
  const rows = useMemo(() => {
    const rowsStudents: ProviderCaseloadListRow[] = filteredStudents.map((s) => {
      return {
        isFailedImport: false,
        avatarInitials:
          s.firstName && s.lastName
            ? `${s.firstName[0]}${s.lastName[0]}`.toLocaleUpperCase()
            : null,
        xlogsStudentID: s.id ?? null,
        dob: dayjs(s.dateOfBirth) ?? null,
        firstName: s.firstName ?? null,
        lastName: s.lastName ?? null,
        grade: studentProfileGradeToString(s.grade ?? 0) ?? null,
        school: s.activeSchoolCampuses?.[0]?.name ?? null,
        studentIdGivenBySchoolDistrict: s.studentIdGivenBySchoolDistrict ?? null,
        planOfCareIfAny:
          s.activePlanOfCare?.type !== undefined
            ? planOfCareTypeToString[s.activePlanOfCare.type]
            : null,
        planOfCareDurationIfAny:
          s.activePlanOfCare?.startDate && s.activePlanOfCare?.endDate
            ? getPlanOfCareDurationText(s.activePlanOfCare?.startDate, s.activePlanOfCare?.endDate)
            : null,
        planOfCareProgressIfAny: s.activePlanOfCare
          ? createPlanOfCareProgress(s.activePlanOfCare)
          : null,
      };
    });

    const rowsFailedStudentImports: ProviderCaseloadListRow[] = failedStudentImports.map((v) => {
      return {
        isFailedImport: true,
        studentIdGivenBySchoolDistrict: v.id ?? null,
        firstName: v.firstName ?? null,
        lastName: v.lastName ?? null,
        dob: v.dateOfBirth ?? null,
      } as ProviderCaseloadListRow;
    });

    return [...rowsStudents, ...rowsFailedStudentImports];
  }, [filteredStudents, failedStudentImports]);

  return rows;
}

function getPlanOfCareDurationText(start: Date, end: Date) {
  return (
    dayjs(start).format(STUDENT_MANAGER_DATE_FORMAT_SHORT) +
    " - " +
    dayjs(end).format(STUDENT_MANAGER_DATE_FORMAT_SHORT)
  );
}
