import { Alert, Box, Snackbar } from "@mui/material";
import UnpostedSessionsCard from "../components/cards/unposted_sessions_card";
import useUnpostedSessionsContext from "../hooks/use_unposted_sessions_context";
import { useNavigate, useParams } from "react-router-dom";
import useUnpostedSessionsMatchPath from "../hooks/use_unposted_sessions_match_path";
import UnpostedSessionsCardSkeleton from "../components/cards/unposted_sessions_card_skeleton";
import { ROUTES_XLOGS } from "../../../constants/URLs";
import useUnpostedSessionsViewState from "../hooks/use_unposted_sessions_view_state";
import { useMemo, useState } from "react";
import { useXNGSelector } from "../../../context/store";
import { selectServiceProviderProfile } from "../../../context/slices/loggedInClientSlice";
import produce from "immer";

const UnpostedSessionsView = () => {
  const viewParamId = useParams().viewId as ReturnType<
    typeof useUnpostedSessionsMatchPath
  >["customId"];
  const navigate = useNavigate();
  const context = useUnpostedSessionsContext();
  const loggedServiceProvider = useXNGSelector(selectServiceProviderProfile);
  const viewState = useUnpostedSessionsViewState({
    viewParamId,
    context,
  });

  const sortedServiceProviders = useMemo(() => {
    if (viewParamId === "dec-sessions") {
      const sortedSelectedServiceProviders = produce(
        viewState.state.selectedServiceProviders,
        (draftState) => {
          draftState.sort((a, b) => {
            return a.id === loggedServiceProvider?.id ? -1 : 1;
          });
        },
      );
      return sortedSelectedServiceProviders;
    } else {
      return viewState.state.selectedServiceProviders;
    }
  }, [loggedServiceProvider?.id, viewParamId, viewState.state.selectedServiceProviders]);

  const [showSessionClickErrorMessage, setShowSessionClickErrorMessage] = useState(false);
  const clickSessionErrorMessage = "Session is either missing both an id and seriesId or missing a startTime";

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          width: "100%",
          overflowX: "auto",
          height: "100%",
        }}
      >
        {/* RENDER SKELETON IF LOADING */}
        {(viewState.apiQueryClientManager.isLoading || !viewState.state.hasInitializedState) &&
          Array.from({ length: 10 }).map(() => {
            return <UnpostedSessionsCardSkeleton key={crypto.randomUUID()} />;
          })}
        {viewState.state.selectedServiceProviders.length === 0 && (
          <Alert severity="info" sx={{ fontWeight: 600, alignSelf: "start", flex: 1 }}>
            No service providers Selected
          </Alert>
        )}
        {/* RENDER DATA ON SUCCESS */}
        {viewState.state &&
          viewState.state.hasInitializedState &&
          viewState.apiQueryClientManager.isSuccess &&
          sortedServiceProviders.map((serviceProvider) => {
            return (
              <UnpostedSessionsCard
                key={serviceProvider.id ?? crypto.randomUUID()}
                serviceProvider={serviceProvider}
                sessionsData={viewState.state.selectedServiceProvidersFilteredSessions.get(
                  serviceProvider.id ?? "",
                )}
                totalUnpostedSessions={
                  viewState.state.totalProviderUnpostedSessions.get(serviceProvider.id ?? "") ?? 0
                }
                // disable={diableCards}
                mySessions={
                  viewParamId === "my-sessions" || serviceProvider.id === loggedServiceProvider?.id
                }
                onSessionBtnClick={(session) => {
                  if ((!session.id && !session.seriesId) || !session.startTime) {
                    console.error(clickSessionErrorMessage);
                    setShowSessionClickErrorMessage(true);
                    return;
                  }
                  viewState.dispatch({
                    type: "set_current_session_in_notator_view",
                    payload: {
                      id: session.id ?? null,
                      startTime: session.startTime,
                      seriesId: session.seriesId ?? null,
                    },
                  });
                  const path = `${ROUTES_XLOGS.unposted_sessions.index}/${viewParamId}/notator/${session?.id}?serviceProviderId=${serviceProvider.id}&seriesId=${session?.seriesId}&date=${session?.startTime}`;
                  navigate(path);
                }}
              />
            );
          })}
      </Box>
      {/* AUXILIARY COMPONENTS */}
      <Snackbar anchorOrigin={{horizontal:"right", vertical:"bottom"}} open={showSessionClickErrorMessage} autoHideDuration={6000} onClose={()=> setShowSessionClickErrorMessage(false)} message={clickSessionErrorMessage} />
    </>
  );
};

export default UnpostedSessionsView;
