import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js";
import { DEPLOYMENT } from "../constants/deployment";
// https://learn.microsoft.com/en-us/azure/azure-monitor/app/javascript-framework-extensions?tabs=react

// This is the first pass at implimenting App Insights.  We may want to explore using context

const reactPlugin = new ReactPlugin();

let loggingLevelConsole, loggingLevelTelemetry;

switch (DEPLOYMENT) {
  /* 0 = off, 1 = critical errors only, 2 = Everything (errors & warnings) */
  case "local":
    loggingLevelConsole = 2;
    loggingLevelTelemetry = 0;
    break;
  case "development":
    loggingLevelConsole = 1;
    loggingLevelTelemetry = 0;
    break;
  case "staging":
  case "default":
    loggingLevelConsole = 1;
    loggingLevelTelemetry = 1;
    break;
}
let ai = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_APPINSIGHTS_CONNECTIONSTRING,
    // *** If you're adding the Click Analytics plug-in, delete the next line. ***
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    addRequestContext: true,
    disableCorrelationHeaders: false,
    disableExceptionTracking: false,
    loggingLevelConsole: loggingLevelConsole,
    loggingLevelTelemetry: loggingLevelTelemetry,
    disableAjaxTracking: true,

    // *** Add the Click Analytics plug-in. ***
    // extensions: [reactPlugin, clickPluginInstance],
    // extensionConfig: {
    //   [reactPlugin.identifier]: { history: browserHistory }
    // *** Add the Click Analytics plug-in. ***
    // [clickPluginInstance.identifier]: clickPluginConfig
    // }
  },
});
ai.loadAppInsights();

export default (Component) => withAITracking(reactPlugin, Component);
const appInsights = ai.appInsights;
export { reactPlugin, appInsights };
