
// WeekOfSchoolYear = 0,
// DateRange = 1,
// MedicalDateRange = 2,

export enum SessionDateFilter {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    // NUMBER_2 = 2,
}

 const session_date_filter_options = ["Week of School Year", "Date Range"/* , "Medical Date Range" */]

 export default session_date_filter_options;