import { Box, Stack } from "@mui/material";
import UserApprovalsHistoryCard from "../components/cards/user_approvals_history_card";

import UserApprovalsPagination from "../components/user_approval_pagination";
import useXNGPagination from "../../../../../hooks/use_xng_pagination";
import { useXNGSelector } from "../../../../../context/store";
import { selectAuthorizedDistricts } from "../../../../../context/slices/userProfileSlice";
import useApiQueryGetServiceProviderTypesByDate from "../../../../../api/hooks/state_snapshots/use_api_query_get_service_provider_types_by_date";
import { selectStateInUS } from "../../../../../context/slices/stateInUsSlice";
import { xlogsRoleOptions } from "../../../../../utils/xlogs_role_mapper";
import UsersHistoryTabState from "../types/users_history_tabs_state";

type Props = {
  tabData: UsersHistoryTabState;
  // tabDispatch: React.Dispatch<UsersHistoryTabAction>;
};

const UserHistoryTabView = (props: Props) => {
  const stateInUs = useXNGSelector(selectStateInUS);
  const districtOptions = useXNGSelector(selectAuthorizedDistricts);

  const serviceProviderApiOptionsManager = useApiQueryGetServiceProviderTypesByDate({
    queryParams: {
      state: stateInUs,
    },
  });

  const { paginatedItems, currentPage, totalPages, showPagination, setCurrentPage } =
    useXNGPagination(props.tabData.filteredUsers);

  return (
    <Box>
      <Box
        // bgcolor={"wheat"}
        pt={1}
        sx={{
          overflowY: "auto",
        }}
      >
        <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
          {paginatedItems?.map((cardUserId) => (
            <UserApprovalsHistoryCard
              key={cardUserId}
              userHistoryCard={props.tabData.usersOptions.get(cardUserId)!}
              selectData={{
                districtsOptions: districtOptions,
                serviceProviderApiOptionsManager: serviceProviderApiOptionsManager,
                xlogsRoleOptions: xlogsRoleOptions,
              }}
            />
          ))}
        </Stack>
      </Box>
      <UserApprovalsPagination
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={(v) => setCurrentPage(v)}
        showPagination={showPagination}
      />
    </Box>
  );
};

export default UserHistoryTabView;
