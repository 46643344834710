/**
 * Toggles an item in and out of an array. If the item is an object, it will attempt a deep comparison, but it
 * is recommended to use `toggleFromArrayByKey` instead for toggling from an array of complex objects.
 *
 * @param {T} item - The item to toggle.
 * @param {T[]} array - The array from which to toggle the item.
 * @returns {T[]} A new array with the item toggled.
 * @type T - The type of the elements in the array.
 *
 * @example
 * // For an array of primitives
 * const numbers = [1, 2, 3];
 * const updatedNumbers = toggleFromArray(2, numbers);
 * // updatedNumbers will be [1, 3] as '2' was found and removed.
 *
 * // For an array of objects
 * const people = [{ id: 1, name: 'John' }, { id: 2, name: 'Doe' }];
 * const newPerson = { id: 2, name: 'Doe' };
 * const updatedPeople = toggleFromArray(newPerson, people);
 * // updatedPeople will be [{ id: 1, name: 'John' }] as 'newPerson' was found and removed using deep equality.
 */
function toggleFromArray<T>(item: T, array: T[]): T[] {
  // TODO: Replace this deep compare with **modularized** lodash deep comparison function
  const deepEqual = (object1: T, object2: T) => {
    return (
      // @ts-ignore
      Object.entries(object1).sort().toString() === Object.entries(object2).sort().toString()
    );
  };

  const containsDuplicateObjects = array.filter((fitem) => deepEqual(item, fitem)).length > 0;

  if (
    array.length > 0 &&
    typeof array[0] === "object" &&
    !Array.isArray(array[0]) &&
    containsDuplicateObjects
  ) {
    return array.filter((fitem) => !deepEqual(item, fitem));
  }

  if (array.includes(item)) {
    const res = array.filter((fitem) => {
      return fitem !== item;
    });
    return res;
  } else {
    const res = [...array, item];
    return res;
  }
}

export default toggleFromArray;
