import {
  AddStudentToSessionRequest,
  SessionResponse,
  StudentJournal,
  StudentRef,
} from "../../../../session-sdk";
import { getUserTimeZone } from "../../../../utils/timeZones";
import { API_SESSIONS } from "../../../../api/api";
import LOCAL_STORAGE_KEYS from "../../../../constants/localStorageKeys";
import { useXNGSelector } from "../../../../context/store";
import { selectStateInUS } from "../../../../context/slices/stateInUsSlice";
import { selectClientID } from "../../../../context/slices/loggedInClientSlice";
import { produce } from "immer";
import { SaveSessionFunctionType } from "./use_request_save_session";

interface UseNotatorAddRemoveStudentActionsProps {
  session: SessionResponse;
  draftSession: SessionResponse;
  requestSaveSession: SaveSessionFunctionType;
  toggleNotatorRefresh: () => void;
  setSession: (v: SessionResponse) => void;
}

export interface NotatorAddRemoveStudentActions {
  add: (students: StudentRef[]) => Promise<void>;
  remove: (newStudentJournalList: StudentJournal[]) => Promise<SessionResponse>;
}

/**
 * This module is intended to encapsulate and centralize all notator logic for adding and removing students.
 */
export function useNotatorAddRemoveStudentActions(
  props: UseNotatorAddRemoveStudentActionsProps,
): NotatorAddRemoveStudentActions {
  const { requestSaveSession, draftSession, session, toggleNotatorRefresh, setSession } = props;
  const stateInUS = useXNGSelector(selectStateInUS);
  const clientId = useXNGSelector(selectClientID);

  async function addStudentsToSessionAndSave(students: StudentRef[]) {
    const requestBody: AddStudentToSessionRequest = {
      id: session.id,
      clientId,
      studentIds: students.map((student) => student.id!),
      serviceArea: session.service!.area,
      serviceProviderId: session.serviceProvider!.id,
      seriesId: session.seriesId,
      sessionDate: session.meetingDetails!.date,
      timezone: getUserTimeZone(),
    };

    const newSession = await API_SESSIONS.v1SessionsAddStudentPatch(stateInUS, requestBody);

    // TECH DEBT: After using `v1SessionsAddStudentPatch` to add students, we manually set groupSize. `v1SessionsAddStudentPatch` should likely handle this.
    newSession.groupSize = newSession.studentJournalList?.length;
    requestSaveSession(newSession);

    // This is for the unsaved changes comparison between localStorage and draftSession mechanism. // TODO: Is there an upstream way to handle this and catch all future cases?
    localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_SESSION_KEY, JSON.stringify(newSession));

    toggleNotatorRefresh();
  }

  async function removeStudentsFromSession(newStudentJournalList: StudentJournal[]) {
    const sessionWithoutStudents = produce(draftSession, (draft) => {
      draft.studentJournalList = newStudentJournalList;
      draft.groupSize = newStudentJournalList.length;
    });

    setSession(sessionWithoutStudents);

    return sessionWithoutStudents;
  }

  return { add: addStudentsToSessionAndSave, remove: removeStudentsFromSession };
}
