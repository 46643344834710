import { selectStateInUS } from "../../../../../context/slices/stateInUsSlice";
import { selectLoggedInClientAssignment } from "../../../../../context/slices/userProfileSlice";
import { useXNGSelector } from "../../../../../context/store";
import { createContext, useEffect, useMemo, useReducer, useRef, useState } from "react";
import useApiQueryUserManagementCards from "../hooks/api/use_api_query_user_management_cards";
import useApiMutatePatchUsersRequestingAccessToDistrict from "../hooks/api/use_api_mutate_patch_users_requesting_access_to_district";
import UserApprovalsContextstate from "../types/user_approvals_context_state";
import UserApprovalsTabsEnum from "../types/user_approvals_tabs_enum";
import QueryStatusModal from "../../../../../design/modal_templates/query_status_modal";
import userApprovalsOrDenialsReducer, {
  initialUserSelectState,
} from "./reducers/user_approvals_denials_reducer";
import userApprovalsHistoryReducer, {
  initialUserHistoryState,
} from "./reducers/user_approvals_history_reducer";

type Props = {
  children: React.ReactNode;
};

const UserApprovalsProvider = (props: Props) => {
  const state = useXNGSelector(selectStateInUS);
  const loggedInClient = useXNGSelector(selectLoggedInClientAssignment);
  const hasInitializedStates = useRef(false);


  const [selectedTabIndex, setSelectedTabIndex] = useState<UserApprovalsTabsEnum>(
    UserApprovalsTabsEnum.user_approvals,
  );

  const queryUserManagementCardsApiClientHandler = useApiQueryUserManagementCards({
    queryParams: {
      clientId: loggedInClient.client?.id ?? "",
      state: state,
    },
  });

  const patchUsersRequestingAccessToDistrictApiClientHandler =
    useApiMutatePatchUsersRequestingAccessToDistrict({
      queryParams: {
        state: state,
      },
    });

  const [userApprovalsUnaprovedState, userApprovalsUnaprovedDispatch] = useReducer(
    userApprovalsOrDenialsReducer,
    {
      cards: queryUserManagementCardsApiClientHandler?.data?.unapprovedUsers || [],
    },
    initialUserSelectState,
  );

  // denials reduer for the user select
  const [userApprovalsDenialState, userApprovalsDenialDispatch] = useReducer(
    userApprovalsOrDenialsReducer,
    {
      cards: queryUserManagementCardsApiClientHandler?.data?.deniedUsers || [],
    },
    initialUserSelectState,
  );

  const [userApprovalsHistoryState, userApprovalsHistoryDispatch] = useReducer(
    userApprovalsHistoryReducer,
    {
      cards: queryUserManagementCardsApiClientHandler?.data?.approvalAndDenialHistory || [],
    },
    initialUserHistoryState,
  );

  const contextValue: UserApprovalsContextstate = useMemo(
    () => ({
      selectedTabIndex: selectedTabIndex,
      setSelectedTabIndex: (tab) => {
        setSelectedTabIndex(tab);
      },
      patchUsersRequestingAccessToDistrictApiClientHandler:
        patchUsersRequestingAccessToDistrictApiClientHandler,
      queryUserManagementCardsApiClientHandler: queryUserManagementCardsApiClientHandler,
      userApprovalsUnaprovedState: userApprovalsUnaprovedState,
      userApprovalsDenialState: userApprovalsDenialState,
      userApprovalsHistoryState: userApprovalsHistoryState,
      userApprovalsDenialDispatch: userApprovalsDenialDispatch,
      userApprovalsUnapprovedDispatch: userApprovalsUnaprovedDispatch,
      userApprovalsHistoryDispatch: userApprovalsHistoryDispatch,
    }),
    [
      patchUsersRequestingAccessToDistrictApiClientHandler,
      queryUserManagementCardsApiClientHandler,
      selectedTabIndex,
      userApprovalsDenialState,
      userApprovalsHistoryState,
      userApprovalsUnaprovedState,
    ],
  );

  useEffect(() => {
    if(queryUserManagementCardsApiClientHandler.data) {
      if (!hasInitializedStates.current) {
        hasInitializedStates.current = true;
        userApprovalsUnaprovedDispatch({
          type: "initialize",
          payload: queryUserManagementCardsApiClientHandler.data.unapprovedUsers || [],
        });
        userApprovalsDenialDispatch({
          type: "initialize",
          payload: queryUserManagementCardsApiClientHandler.data.deniedUsers || [],
        });
      } else {
        userApprovalsUnaprovedDispatch({
          type: "update_users",
          payload: queryUserManagementCardsApiClientHandler.data.unapprovedUsers || [],
        });
        userApprovalsDenialDispatch({
          type: "update_users",
          payload: queryUserManagementCardsApiClientHandler.data.deniedUsers || [],
        });
      }
      userApprovalsHistoryDispatch({
        type: "initialize",
        payload: queryUserManagementCardsApiClientHandler.data.approvalAndDenialHistory || [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    queryUserManagementCardsApiClientHandler.data
  ]);

  /**
   * Populates the states based on the data received from the API client handler.
   * If the data has changed, it initializes or updates the user approvals and denial states.
   * It also initializes the approval and denial history state.
   */


  return (
    <UserApprovalContext.Provider value={contextValue}>
      {props.children}
      <QueryStatusModal
        isOpen={queryUserManagementCardsApiClientHandler.isError}
        status={queryUserManagementCardsApiClientHandler.status}
        onSettledClose={() => queryUserManagementCardsApiClientHandler.refetch()}
        content={{
          errorTitle: "Error",
          pendingTitle: "Loading...",
          errorBody: "Error Loading User Management Data",
          successTitle: "User Management Cards Loaded",
          successBody: "User Management Cards have successfully been loaded",
          cancelButtonText: "Retry",
        }}
      />
    </UserApprovalContext.Provider>
  );
};

export const UserApprovalContext = createContext<UserApprovalsContextstate>(
  {} as UserApprovalsContextstate,
);

export default UserApprovalsProvider;
