import { CaseloadStudentDisplay } from "../../../../../profile-sdk";
import StudentCardGrid from "./student_card_grid";
import StudentCard from "./student_card";
import { StudentManagerCaseloadResultsProps } from "../types/student_manager_caseload_results_props";
import { StudentManagerSectionWrapper } from "./student_manager_section_wrapper";
import { ProviderCaseloadHeader } from "./provider_caseload_header";

function ProviderCaseloadCardResults(props: Readonly<StudentManagerCaseloadResultsProps>) {
  const {
    filteredStudents,
    failedStudentImports: failedStudentFetches,
    totalStudentsInCaseload,
  } = props;

  return (
    <StudentManagerSectionWrapper>
      <ProviderCaseloadHeader
        totalFilteredStudents={filteredStudents.length}
        totalStudentsInCaseload={totalStudentsInCaseload}
      />

      <StudentCardGrid>
        {filteredStudents.map((s: CaseloadStudentDisplay, i: number) => (
          <StudentCard s={s} animIndex={i} key={s.id} />
        ))}

        {failedStudentFetches.map((student) => (
          <StudentCard
            key={student.id}
            animIndex={1}
            errorStudent={student}
            errorMessage="This student's information could not be found. 
                  If this student is not currently active on your caseload, 
                  you can remove them. Otherwise, please 
                  contact client care."
          />
        ))}
      </StudentCardGrid>
    </StudentManagerSectionWrapper>
  );
}

export default ProviderCaseloadCardResults;
