import { selectClientID } from "../../../../context/slices/loggedInClientSlice";
import { selectStateInUS } from "../../../../context/slices/stateInUsSlice";
import { thankYouModalActions } from "../../../../context/slices/thankYouModalSlice";
import { ACTION_addProviderToProxyCaseload } from "../../../../context/slices/userProfileSlice";
import { useXNGDispatch, useXNGSelector } from "../../../../context/store";
import { ServiceProviderRef } from "../../../../profile-sdk";

export function useAddProviderToProxyCaseload() {
  const dispatch = useXNGDispatch();
  const clientID = useXNGSelector(selectClientID);
  const stateInUS = useXNGSelector(selectStateInUS);

  async function addToProxyCaseload(serviceProvider: ServiceProviderRef) {
    if (!serviceProvider || !clientID) {
      console.error("Insufficient data provided to 'addProviderToProxyCaseload'!");
      return;
    }

    dispatch(
      ACTION_addProviderToProxyCaseload({
        loggedinUserId: clientID,
        provider: serviceProvider,
        state: stateInUS,
      }),
    );
    dispatch(
      thankYouModalActions.ACTION_ShowThankyouModal({
        show: true,
        text: `You can now access ${serviceProvider.firstName} ${serviceProvider.lastName}'s account 
      by navigating to “My Profile” in the 
      navigation bar. Access to post in their 
      account has been requested.`,
      }),
    );
  }

  return addToProxyCaseload;
}
