import { matchRoutes } from "react-router";
import { queryClient } from "../../..";
import { QUERY_KEYS } from "../../../api/constants/query_keys";
import { ROUTES_XLOGS } from "../../../constants/URLs";

function refetchUserAndUnpostedSessions() {
    const thisLocation = window.location;
    const inUnpostedSessionsView = matchRoutes([
      {
        path: ROUTES_XLOGS.unposted_sessions.mySessions,
      },
      {
        path: ROUTES_XLOGS.unposted_sessions.decSessions,
      },
      {
        path: ROUTES_XLOGS.unposted_sessions.assistantSessions,
      },
      {
        path: ROUTES_XLOGS.unposted_sessions.index,
      },
      {
        path: ROUTES_XLOGS.unposted_sessions.notator,
      },
  
    ], thisLocation);
  
    queryClient.refetchQueries({
      queryKey: QUERY_KEYS.v1SessionsUnpostedCountGet
    })
  
    if (inUnpostedSessionsView) {
      queryClient.refetchQueries({
        queryKey: QUERY_KEYS.v1SessionsSlimGet
      })
      queryClient.refetchQueries({
        queryKey: QUERY_KEYS.v1UsersIdGet
      })
    }
  }

  export default refetchUserAndUnpostedSessions;