/**
 * Toggles an item in and out of an array based on a specified key. If the item exists in the array (matching by the key), it is removed. If it doesn't exist, it is added.
 *
 * @param {T} item - The item to toggle in the array.
 * @param {T[]} array - The array from which to toggle the item.
 * @param {keyof T} key - The key to match the item against existing items in the array.
 * @returns {T[]} A new array with the item toggled.
 * @type T - The type of elements in the array and the item.
 *
 * @example
 * // Define an array of objects with type {id: number, name: string}
 * const people = [{ id: 1, name: 'John' }, { id: 2, name: 'Doe' }];
 *
 * // Toggle an item by the 'id' key
 * const updatedPeople = toggleFromArrayByKey({ id: 2, name: 'Doe' }, people, 'id');
 * // updatedPeople will be [{ id: 1, name: 'John' }] as the item was found and removed
 *
 * // Toggle an item that doesn't exist in the array
 * const newUpdatedPeople = toggleFromArrayByKey({ id: 3, name: 'Jane' }, updatedPeople, 'id');
 * // newUpdatedPeople will be [{ id: 1, name: 'John' }, { id: 3, name: 'Jane' }] as the item was not found and added
 */
export default function toggleFromArrayByKey<T>(item: T, array: T[], key: keyof T): T[] {
  if (Boolean(array.find((a) => a[key] === item[key]))) {
    const res = array.filter((_item) => {
      return _item[key] !== item[key];
    });
    return res;
  } else {
    const res = [...array, item];
    return res;
  }
}
