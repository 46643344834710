import { API_USERS } from "../../../../api/api";
import { selectClientID } from "../../../../context/slices/loggedInClientSlice";
import { ACTION_toggleRefreshSwitch } from "../../../../context/slices/refreshSwitch";
import { selectStateInUS } from "../../../../context/slices/stateInUsSlice";
import { thankYouModalActions } from "../../../../context/slices/thankYouModalSlice";
import {
  selectLoggedInClientAssignment,
  selectUser,
} from "../../../../context/slices/userProfileSlice";
import { useXNGDispatch, useXNGSelector } from "../../../../context/store";
import { PatchClientAssignmentRequest, ServiceProviderRef } from "../../../../profile-sdk";
import useRefetchUnpostedSessionsRequests from "../../../../views/unposted_sessions/hooks/use_refetch_unposted_sessions_requests";
import { ACTION_addProviderToSupervisedCaseload } from "../../../../context/slices/userProfileSlice";

export function useAddProviderToApproverCaseload() {
  const dispatch = useXNGDispatch();
  const clientID = useXNGSelector(selectClientID);
  const stateInUS = useXNGSelector(selectStateInUS);
  const loggedInClientAssignment = useXNGSelector(selectLoggedInClientAssignment);
  const user = useXNGSelector(selectUser);
  const { refetchOnCaseloadAction } = useRefetchUnpostedSessionsRequests();

  async function addToApproverCaseload(serviceProvider: ServiceProviderRef) {
    if (!serviceProvider || !clientID) {
      console.error("Insufficient data provided to 'addProviderToApproverCaseload'!");
      return;
    }

    const supervisedServiceProviders = loggedInClientAssignment.supervisedServiceProviders ?? [];
    const newSupervisedServiceProviders = [...supervisedServiceProviders, serviceProvider];
    const patchClientAssignmentRequest: PatchClientAssignmentRequest = {
      supervisedServiceProviders: newSupervisedServiceProviders,
    };

    const userResponse = await API_USERS.v1UsersIdClientAssignmentsClientIdPatch(
      user!.id!,
      clientID,
      stateInUS,
      patchClientAssignmentRequest,
    );

    const supervisedProviderCaseload = userResponse.clientAssignments?.find(
      (client) => client.client?.id === clientID,
    )?.supervisedServiceProviders;

    dispatch(
      ACTION_addProviderToSupervisedCaseload({
        providers: supervisedProviderCaseload!,
        loggedinClientId: clientID,
      }),
    );

    refetchOnCaseloadAction();
    dispatch(ACTION_toggleRefreshSwitch());
    dispatch(
      thankYouModalActions.ACTION_ShowThankyouModal({
        show: true,
        text: `You can now access ${serviceProvider.firstName} ${serviceProvider.lastName}'s account 
      by navigating to “My Profile” in the 
      navigation bar.`,
      }),
    );
  }

  return addToApproverCaseload;
}
