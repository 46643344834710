import { useMemo } from "react";
import { ScheduleOfService } from "../../../../../profile-sdk";
import {
  GetMinutesSpentOnOrderedServiceAreaResponse,
  SessionResponse,
} from "../../../../../session-sdk";
import { scheduleOfServicesIntervalToString } from "../constants/schedule_of_services_interval_to_string";
import dayjs from "dayjs";
import { NonSchoolDaysContainer } from "../../../../../browser/non_school_days/district_non_school_days";
import getTotalScheduledMinutes from "../utils/get_total_scheduled_minutes";

export interface TextLabels {
  duration: string;
  remainingDaysOfServicesAvailable: string;
  startAndEndDate: string;
  frequency: string;
  timeAwayFromStudent: string;
  netDuration: string;
  scheduledMinutes: string;
  remainingMinutesNeeded: string;
}

interface ServiceAnalysisLabelCalculationParameters {
  selectedOption: ScheduleOfService | null;
  draftSession: SessionResponse;
  minutesSpentOnOrderedServiceArea: GetMinutesSpentOnOrderedServiceAreaResponse | null;
  useTeamAggregatedData: boolean;
  nonSchoolDayCtr: NonSchoolDaysContainer;
}
export function useLabelCalculator(
  props: ServiceAnalysisLabelCalculationParameters,
): TextLabels | null {
  const {
    selectedOption,
    draftSession,
    minutesSpentOnOrderedServiceArea,
    useTeamAggregatedData,
    nonSchoolDayCtr,
  } = props;

  const textLabels: TextLabels | null = useMemo(() => {
    if (!selectedOption) return null;

    // All string labels are informed by:
    // * Profile Domain model documentation: https://www.figma.com/board/zAkAD978JbGYQVx5n3JN7Q/Profile-Domain?node-id=18-1846&t=FYTyLjcwIliZ8UEm-0
    // * Wireframe comments: https://www.figma.com/design/JM8E1G1C44946UVNeNbRSV/Notator---Doc-View?node-id=1289-5396&t=qluzBp25jrbxj7t6-0

    const res = {} as TextLabels;

    res.duration = selectedOption.durationOfOccurances + " minutes";

    res.frequency =
      selectedOption.interval !== undefined
        ? scheduleOfServicesIntervalToString[selectedOption.interval]
        : "Unknown";

    res.startAndEndDate =
      dayjs(selectedOption?.startDate).format(FORMATTER) +
      " - " +
      dayjs(selectedOption?.endDate).format(FORMATTER);

    res.remainingDaysOfServicesAvailable =
      dayjs(selectedOption?.endDate).diff(draftSession.meetingDetails?.endTime, "days") + " days";

    res.timeAwayFromStudent = minutesSpentOnOrderedServiceArea?.totalTimeAwayMinutes + " minutes";

    const netDuration = getNetDuration(useTeamAggregatedData, minutesSpentOnOrderedServiceArea);

    if (minutesSpentOnOrderedServiceArea) {
      res.netDuration = netDuration + " minutes";
    }

    if (selectedOption.durationOfOccurances === undefined) return res;
    if (selectedOption.interval === undefined) return res;

    const scheduledMinutes = getTotalScheduledMinutes(
      selectedOption.durationOfOccurances,
      dayjs(selectedOption.startDate),
      dayjs(selectedOption.endDate),
      selectedOption.interval,
      nonSchoolDayCtr,
    );
    res.scheduledMinutes = scheduledMinutes + " minutes";

    const remainingMinutesNeeded = scheduledMinutes - netDuration;
    res.remainingMinutesNeeded = remainingMinutesNeeded + " minutes";

    return res;
  }, [props]);

  return textLabels;
}

const FORMATTER = "MM/DD/YY";

function getNetDuration(
  useTeamAggregatedData: boolean,
  minutesSpentOnOrderedServiceArea: GetMinutesSpentOnOrderedServiceAreaResponse | null,
): number {
  if (useTeamAggregatedData) {
    return minutesSpentOnOrderedServiceArea?.totalMinutesSpent ?? 0;
  } else {
    return minutesSpentOnOrderedServiceArea?.totalMinutesSpentWithServiceProvider ?? 0;
  }
}
