import { Box, Typography, useTheme } from "@mui/material";
import { MSBIconRenderer } from "../fortitude";
import { IoMdInformationCircleOutline } from "react-icons/io";

function NotAuthorizedIndicator() {
  const { palette } = useTheme();

  return (
    <Box
      className="noselect"
      sx={{
        display: "flex",
        width: "100%",
        gap: "1rem",
        color: palette.grey[500],
        justifyContent: "center",
        height: "20rem",
        alignItems: "center",
      }}
    >
      <MSBIconRenderer i={<IoMdInformationCircleOutline />} size="lg" />
      <Typography variant="h5">
        401: Not authorized to view. If you believe this is an error, contact MSB support.
      </Typography>
    </Box>
  );
}

export default NotAuthorizedIndicator;
