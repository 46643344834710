import { CaseloadStudentDisplay } from "../../../profile-sdk";

const getProgressPercentage = (s: CaseloadStudentDisplay) => {
  const today = new Date().getTime();
  let start = s.activePlanOfCare?.startDate;
  let end = s.activePlanOfCare?.endDate;

  if (!start || !end) return 0;
  start = new Date(start);
  end = new Date(end);

  return ((today - start.getTime()) / (end.getTime() - start.getTime())) * 100;
};

export default getProgressPercentage;
