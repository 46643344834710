import {
  Box,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  IconButton,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { CaseloadStudentDisplay, SchoolCampusRef } from "../../../../profile-sdk";
import usePalette from "../../../../hooks/usePalette";
import React, { useEffect, useState } from "react";
import useSortStudentsByName from "../../hooks/use_sort_students_by_name";
import { getSizing } from "../../../../design/sizing";
import MediaQueryBox from "../../../../design/components-dev/MediaQueryBox";
import { XNGIconRenderer, XNGICONS } from "../../../../design";
import XNGToggleGroup from "../../../../design/low-level/button_togglegroup";
import XNGButton from "../../../../design/low-level/button";
import RemoveStudentModal from "./components/remove_student_modal";
import { MSBSearch, MSBSearchMultiselect } from "../../../../fortitude";
import CreateStudentForm from "./components/create_student_form";
import { MSBSearchMultiselectProps } from "../../../../fortitude/components/search_multiselect/search_multiselect";

function StudentManagerHeader(
  props: Readonly<{
    layoutMode: "grid" | "list";
    onLayoutButtonClick: () => void;
    filteredStudents: CaseloadStudentDisplay[];
    setFilteredStudents: React.Dispatch<React.SetStateAction<CaseloadStudentDisplay[]>>;
    studentCaseLoad: CaseloadStudentDisplay[];
    createAndAddStudent: Function;
    deleteStudent: Function;
    campusList: SchoolCampusRef[] | undefined;
    usesCampusRestrictions: boolean;
    searchBarState: {
      value: string;
      onChange: (v: string) => void;
    };
  }>,
) {
  const thm = useTheme();
  const palette = usePalette();
  const [medicaidBool, setMedicaidBool] = useState<boolean>(false);
  const [createNew, setCreateNew] = useState<boolean>(false);
  const [removeStudentModalOpen, setRemoveStudentModalOpen] = useState<boolean>(false);
  const [selectedCampusOptions, setSelectedCampusOptions] = useState<SchoolCampusRef[]>([]);
  const [selectedGradeOptions, setSelectedGradeOptions] = useState<string[]>([]);

  const sortStudentsByName = useSortStudentsByName();

  function filterStudents() {
    let test = props.studentCaseLoad;

    if (medicaidBool) {
      test = test.filter((s) => s.medicaidId);
    }
    if (selectedGradeOptions.length > 0) {
      if (!selectedGradeOptions.includes("All")) {
        test = test.filter((s) =>
          selectedGradeOptions.includes(gradeOptionValues[s.grade?.valueOf()!]),
        );
      }
    }

    // Filter `test` by checking campus names in `selectedCampusOptions` against `s.activeSchoolCampuses`
    if (selectedCampusOptions.length > 0) {
      test = test.filter(
        (student) =>
          // Include student if they have no active campuses or if there's a matching campus in the selected options
          (student.activeSchoolCampuses ?? []).length === 0 ||
          (student.activeSchoolCampuses ?? []).some((campusAssignment) =>
            selectedCampusOptions.some(
              (campusOption) => campusOption.name === campusAssignment.name,
            ),
          ),
      );
    }

    props.setFilteredStudents(test.sort(sortStudentsByName));
  }

  useEffect(() => {
    filterStudents();
  }, [selectedCampusOptions, selectedGradeOptions, medicaidBool]);

  return (
    <>
      <MediaQueryBox
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
        showIf={thm.breakpoints.down("md")}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h5">Student Manager</Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <XNGToggleGroup
            options={[{ label: "Create" }, { label: "Remove" }, { label: "Upload" }]}
          />
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ display: "flex", paddingRight: getSizing(7) }}>
            <XNGIconRenderer i={<XNGICONS.Filter />} size="sm" />
            <Typography variant="body1">Filter Results: {props.filteredStudents.length}</Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Typography variant="body1">Student Results: {props.studentCaseLoad.length}</Typography>
            <XNGIconRenderer i={<XNGICONS.Grid4X4 />} size="sm" />
          </Box>
        </Box>
      </MediaQueryBox>

      <MediaQueryBox
        sx={{
          display: "block",
          width: "100%",
          paddingX: getSizing(5),
          paddingTop: getSizing(3),
          paddingBottom: getSizing(3),
          backgroundColor: palette.primary[1],
        }}
        showIf={thm.breakpoints.up("md")}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingY: getSizing(2),
              alignItems: "center",
            }}
          >
            <Typography color={palette.contrasts[5]} variant="h5">
              Student Manager
            </Typography>
            <Box sx={{ display: "flex", gap: getSizing(2) }}>
              <XNGButton
                sx={{
                  backgroundColor: palette.contrasts[5],
                  color: palette.primary[1],
                  ":hover": {
                    backgroundColor: palette.primary[3],
                    color: palette.contrasts[5],
                  },
                }}
                onClick={() => {
                  setCreateNew(!createNew);
                }}
              >
                Create{" "}
              </XNGButton>
              <XNGButton
                sx={{
                  backgroundColor: palette.contrasts[5],
                  color: palette.primary[1],
                  ":hover": {
                    backgroundColor: palette.primary[3],
                    color: palette.contrasts[5],
                  },
                }}
                onClick={() => {
                  setRemoveStudentModalOpen(true);
                }}
              >
                Remove
              </XNGButton>
              <XNGButton
                sx={{
                  backgroundColor: palette.contrasts[5],
                  color: palette.primary[1],
                  ":hover": {
                    backgroundColor: palette.primary[3],
                    color: palette.contrasts[5],
                  },
                }}
                onClick={() => {}}
              >
                Upload
              </XNGButton>
            </Box>
            {createNew ? (
              <CreateStudentForm
                open={createNew}
                handleClose={() => setCreateNew(false)}
                createStudent={props.createAndAddStudent}
              />
            ) : null}
            <RemoveStudentModal
              open={removeStudentModalOpen}
              onClose={() => setRemoveStudentModalOpen(false)}
              studentCaseLoad={props.studentCaseLoad}
              deleteStudent={props.deleteStudent}
            />
          </Box>

          <StudentManagerWidescreenFilterWrapper
            leftFlex={
              <>
                <Box sx={{ maxWidth: "min-content" }}>
                  <XNGIconRenderer i={<XNGICONS.Filter />} size="sm" color={palette.contrasts[5]} />
                </Box>
                <FilterDropdown
                  options={props.campusList ?? []}
                  selectedOptions={selectedCampusOptions}
                  getOptionLabel={(v) => v.name ?? ""}
                  onChange={setSelectedCampusOptions}
                  label="Campuses"
                />
                <FilterDropdown
                  options={gradeOptionValues ?? []}
                  selectedOptions={selectedGradeOptions}
                  getOptionLabel={(v) => v ?? ""}
                  onChange={setSelectedGradeOptions}
                  label="Grades"
                />
                {/* TODO: Replace with MSBToggle once we develop one. */}
                <StudentManagerToggle
                  value={medicaidBool}
                  label={"Medicaid only?"}
                  onChange={() => setMedicaidBool(!medicaidBool)}
                />
              </>
            }
            rightFlex={
              <>
                <MSBSearch
                  value={props.searchBarState.value}
                  placeholder="Search for a student"
                  sx={{ backgroundColor: palette.contrasts[5] }}
                  onChange={(e) => props.searchBarState.onChange(e.target.value)}
                  useClear={{ onClear: () => props.searchBarState.onChange("") }}
                  fullWidth
                />
                <Box sx={{ maxWidth: "min-content" }}>
                  <IconButton onClick={() => props.onLayoutButtonClick()}>
                    {props.layoutMode === "grid" && (
                      <XNGIconRenderer
                        i={<XNGICONS.Grid4X4 />}
                        size="sm"
                        color={palette.contrasts[5]}
                      />
                    )}
                    {props.layoutMode === "list" && (
                      <XNGIconRenderer
                        i={<XNGICONS.ThreeHorizontalLines />}
                        size="sm"
                        color={palette.contrasts[5]}
                      />
                    )}
                  </IconButton>
                </Box>
              </>
            }
          />
        </Box>
      </MediaQueryBox>
    </>
  );
}

const gradeOptionValues = [
  "Pre-K",
  "Kindergarten",
  "1st",
  "2nd",
  "3rd",
  "4th",
  "5th",
  "6th",
  "7th",
  "8th",
  "9th",
  "10th",
  "11th",
  "12th",
  "Other",
];

const StudentManagerToggle = (props: {
  value: boolean;
  label: string;
  onChange: FormControlLabelProps["onChange"];
}) => {
  const palette = useTheme().palette;
  return (
    <FormControlLabel
      sx={{ m: 0, maxWidth: "min-content", whiteSpace: "nowrap" }}
      onChange={props.onChange}
      control={<Switch size={"small"} value={props.value} />}
      label={
        <Typography
          variant="body2"
          color={palette?.contrasts?.[1]}
          className="noselect" // Prevents text highlighting when toggle is clicked twice in quick succession
        >
          {props.label}
        </Typography>
      }
    />
  );
};

/**
 * Maintains consistent visuals for local MSBSearchMultiselects.
 */
function FilterDropdown<T>(props: MSBSearchMultiselectProps<T>) {
  return (
    <FormControl sx={{ minWidth: "10rem" }}>
      <MSBSearchMultiselect<T>
        {...props}
        renderOptionVariant="checkbox"
        variant="no overflow"
        colorVariant="white-on-blue"
      />
    </FormControl>
  );
}

/**
 * Spaces out two flex rows from eachother, for use on wide screen.
 */
function StudentManagerWidescreenFilterWrapper(
  props: Readonly<{ leftFlex: React.ReactNode; rightFlex: React.ReactNode }>,
) {
  const { leftFlex: left, rightFlex: right } = props;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>{left}</Box>
      <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>{right}</Box>
    </Box>
  );
}

export default StudentManagerHeader;
