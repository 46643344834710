import { Dialog, Typography } from "@mui/material";
import Box from "../../../components-dev/BoxExtended";
import { getSizing } from "../../../sizing";
import XNGClose from "../../../low-level/button_close";
import { useState } from "react";
import { ServiceProviderCaseloadOption, ServiceProviderRef } from "../../../../profile-sdk";
import XNGButton from "../../../low-level/button";
import { XNGSearch } from "../../../low-level/input_search";

type AddServiceProviderToCaseloadModalProps = Readonly<{
  open: boolean;
  onClose: () => void;
  options: ServiceProviderCaseloadOption[];
  onUserClick: (serviceProvider: ServiceProviderRef) => void;
  onCreateUserClick: () => void;
}>;

export function AddServiceProviderToCaseloadModal(props: AddServiceProviderToCaseloadModalProps) {
  const [selectedServiceProvider, setSelectedServiceProvider] = useState<ServiceProviderRef>();
  return (
    <Dialog
      className="noselect"
      onClose={() => {
        props.onClose();
      }}
      open={props.open}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: getSizing(2),
          width: getSizing(55),
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ marginTop: getSizing(1), paddingLeft: getSizing(1) }} variant="h6">
            Add Provider to Profile
          </Typography>
          <XNGClose onClick={() => props.onClose()} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: getSizing(2),
            paddingTop: 0,
            gap: getSizing(2),
          }}
        >
          <Typography variant="body1">Type a provider's name to search: </Typography>
          <XNGSearch<ServiceProviderCaseloadOption | string>
            options={["Create New User", ...props.options]}
            label=""
            onSelect={(serviceProvider: ServiceProviderCaseloadOption | string) => {
              if (typeof serviceProvider === "string") {
                props.onCreateUserClick();
                props.onClose();
              } else setSelectedServiceProvider(serviceProvider);
            }}
            getOptionLabel={(serviceProvider: ServiceProviderCaseloadOption | string) => {
              if (typeof serviceProvider === "string") {
                return "Create New User";
              } else {
                return `${serviceProvider.firstName} ${serviceProvider.lastName} - ${
                  serviceProvider.campus === null ? "No Campus" : serviceProvider.campus
                }`;
              }
            }}
            setValues={true}
          />
          <XNGButton
            onClick={() => {
              props.onClose();
              if (selectedServiceProvider) {
                props.onUserClick(selectedServiceProvider);
              } else {
                console.error("selectedServiceProvider was null!");
              }
            }}
          >
            Request Access
          </XNGButton>
        </Box>
      </Box>
    </Dialog>
  );
}
