import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import   './index.css'
import useFinancialDashboard from '../../../hooks/useFinancialDashboard'; 
import { dataGridHelper } from '../../../utils/data_grid_helper';
import SkeletonLoading from '../../skeletonLoading/SkeletonLoading';

 

const ProviderParticipationByServiceCategoryDataGrid = () => {

  const data = useFinancialDashboard()?.state?.data?.providerParticipationReports 
  const isLoading = useFinancialDashboard()?.state?.loading
  let rows = data ? dataGridHelper.mountProviderParticipationServiceCategoryColumns().sanitizeProviderParticipationServiceCategoryRows(data).providerParticipationServiceCategoryDataRow : []
  let cols = data ? dataGridHelper.providerParticipationServiceCategoryDataCol : []
 

  return (
    <Box sx={{width:"100%", height:"100%" }}>
      <Box sx={{display:"flex",alignItems:"center",backgroundColor:"#3E4C59",justifyContent:"center",borderRadius:"10px 10px 0 0" ,width:"100%",height:60}}>
         <Typography  sx={{ fontWeight: 'bold',fontSize:"20px",color:"white" }}>
         Provider Participation by Service Category
         </Typography>
      </Box>
      {
        !isLoading && data ?  <DataGrid
        rows={rows}
        columns={cols}
        hideFooterPagination
        hideFooter
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow data_grid_text_clr' : ' data_grid_text_clr'
        }  
        checkboxSelection={false}
        disableRowSelectionOnClick
        style={{padding:"0px 10px 20px 10px",fontWeight:"400",fontSize:"14px",color:"black",maxHeight:"698px"}}
      />:<SkeletonLoading width='1600px' height='600px'/> 
      }
      
    </Box>
  );
}

export default ProviderParticipationByServiceCategoryDataGrid