import { V1SessionReportsSessionLogsDownloadCSVPostRequest } from "@xng/reporting";
import { API_SESSION_LOGS } from "../../api";
import useApiMutateData from "../use_api_mutate_data";
import XNGApiMutateParamObject from "../../../types/xng_api_mutate_param_object";

type Data = Awaited<ReturnType<typeof API_SESSION_LOGS.v1SessionReportsSessionLogsDownloadCSVPost>>; // generic parameter T is used to define data the type of the useApiMutateData hook
type Body = V1SessionReportsSessionLogsDownloadCSVPostRequest | undefined;  // generic parameter B is used to define body the type of the useApiMutateData hook

const useApiMutateDownloadSessionLogsCsv = (paramObject?: Partial<XNGApiMutateParamObject<{}, Data, Body>>) => {
  return useApiMutateData({
    mutationFn: async (body: Body) =>
      await API_SESSION_LOGS.v1SessionReportsSessionLogsDownloadCSVPost(body),
    mutationKey: ["v1SessionReportsSessionLogsDownloadCSVPost"],
    ...(paramObject?.options ?? {})
  });
};

export default useApiMutateDownloadSessionLogsCsv;
