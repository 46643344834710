import dayjs, { Dayjs } from "dayjs";

/**
 * Calculates the completion percentage between two dates relative to the current date.
 * @param startDate - The start date as a Dayjs object.
 * @param endDate - The end date as a Dayjs object.
 * @returns The completion percentage (1-100) or bounds (0 or 100) if out of range.
 */
function getProgressBetweenDates(startDate: Dayjs, endDate: Dayjs): number {
  const today = dayjs();

  if (today.isBefore(startDate)) return 0;
  if (today.isAfter(endDate)) return 100;

  const totalDuration = endDate.diff(startDate);

  const elapsedDuration = today.diff(startDate);

  const completionPercentage = (elapsedDuration / totalDuration) * 100;

  return Math.round(completionPercentage);
}

export default getProgressBetweenDates;
