import { useState } from "react";
import useEffectSkipMount from "../../../hooks/use_effect_after_mount";

/**
 * Will maintain a state and fire a computation off of the main thread when state changes.
 */
export function useAsyncComputeOnChange<T>(props: {
  defaultValue: T;
  onChange: () => void;
}): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [optimistic, setOptimistic] = useState<T>(props.defaultValue);

  useEffectSkipMount(() => {
    async function handleChangeAsync() {
      props.onChange();
    }

    handleChangeAsync();
  }, [optimistic]);

  return [optimistic, setOptimistic];
}
