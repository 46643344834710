import { Box, Typography } from "@mui/material";
import GridSectionLayout from "../../../../../../../design/high-level/common/grid_section_layout";
import XNGRadioGroup from "../../../../../../../design/low-level/radio_group";
import XNGButtonIconLink from "../../../../../../../design/low-level/button_icon_link";
import XNGInput2 from "../../../../../../../design/low-level/input_2";
import { XNGDateField } from "../../../../../../unposted_sessions/components/common/date_field";
import ProviderRecordsModal, {
  ProviderRecordsModalType,
} from "../../../components/interactive/provider_records_modal";
import {
  BlockBillingHistory,
  MedicaidCredential,
  ParticipationListStatusType,
  ProviderRate,
  ServiceProviderResponse,
} from "../../../../../../../profile-sdk";
import React from "react";
import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";

import StaffDirectoryProfileTabToolbar from "../../../components/interactive/staff_directory_profile_tab_toolbar";
import { yupResolver } from "@hookform/resolvers/yup";
import XNGDropDown from "../../../../../../../design/low-level/dropdown2";
import dayjs from "dayjs";
import useFeedbackModal from "../../../../../../../hooks/use_feedback_modal";
import useApiMutatePatchServiceProviderById from "../../../../../../../api/hooks/service_provider/use_api_mutate_patch_service_provider_by_id";
import useUserManagementContext from "../../../../../hooks/context/use_user_management_context";
import UserManagementConfirmationModalType from "../../../../../types/user_management_confirmation_modal_type";
import GetParticipantListInfoOptions from "../../../../../../../data/get_participant_list_info_options";
import produce from "immer";
import {
  getGetParticipantListEnumFromString,
  getGetParticipantListStringFromEnum,
} from "../../../../../../../utils/xlogs_participant_list_mapper";
import useApiQueryServiceProviderById from "../../../../../../../api/hooks/service_provider/use_api_query_service_provider_by_id";
import {
  MedicaidInfoFormData,
  medicaidInfoTabFormSchema,
} from "../../../constants/medicaid_info_tab_form_schema";
import { MSBICONS } from "../../../../../../../fortitude";
import QueryStatusModal from "../../../../../../../design/modal_templates/query_status_modal";

enum ProviderRecords {
  RATE = "RATE",
  LICENSING_INFO = "LICENSING_INFO",
  BLOCK_BILLING_HISTORY = "BLOCK_BILLING_HISTORY",
}

type Props = {
  serviceProvider: ServiceProviderResponse;
  stateInUs: string;
  clientId: string;
  refetchServiceProvider: ReturnType<typeof useApiQueryServiceProviderById>["refetch"];
};

const MedicaidInfoTabContent = (props: Props) => {
  const { Folder } = MSBICONS;
  const [providerRecordRateModalConfig, setProviderRecordRateModalConfig] = React.useState<{
    title?: string;
    subtitle?: string;
    canDeleteRecord: boolean;
    open: boolean;
    selected_record?: ProviderRecords;
    noRecordsText?: string;
    // record: providerRecordsModalType[];
  }>({
    title: "",
    open: false,
    canDeleteRecord: false,
    // record: [],
    noRecordsText: "No records found",
  });

  const setUserManagementConfirmationModal = useUserManagementContext().confirmation_modal.setState;

  const { onFailedSave } = useFeedbackModal();
  const [showMutationStatusModal, setShowMutationStatusModal] = React.useState(false);
  // mutate service provider
  const { mutate: mutateServiceProvider, status: mutateServiceProviderStatus } =
    useApiMutatePatchServiceProviderById({
      queryParams: {
        id: props.serviceProvider.id ?? "",
        clientId: props.clientId,
        state: props.stateInUs,
      },
      options: {
        onSuccess: () => {
          props.refetchServiceProvider();
          onSaveSucess();
        },
      },
    });

  //used to bypass sonarLint deeply nested lint warning
  const getUserManageMentConfirmationModalSetterFn = (
    props: UserManagementConfirmationModalType,
  ) => {
    return () => {
      setUserManagementConfirmationModal(props);
    };
  };

  // used to populate the provider records modal based on the selected record
  const modalRecord: ProviderRecordsModalType[] = React.useMemo((): ProviderRecordsModalType[] => {
    switch (providerRecordRateModalConfig.selected_record) {
      case ProviderRecords.RATE:
        return (
          props.serviceProvider.rateRecords?.map((rate) => {
            return {
              name: `Rate: $${rate.hourlyRate}/hr`,

              dateRange: `${dayjs(rate.startDate).utc().format("MM/DD/YYYY")} - ${dayjs(
                rate.endDate,
              )
                .utc()
                .format("MM/DD/YYYY")}`,
              onDelete: getUserManageMentConfirmationModalSetterFn({
                isOpen: true,
                icon: "danger",
                body: (() => (
                  <Typography maxWidth={"320px"}>
                    Are you sure you would like to remove the rate of{" "}
                    <Box component={"span"} fontWeight={"700"}>
                      ${rate.hourlyRate}/hr
                    </Box>{" "}
                    for the date range of{" "}
                    <Box component={"span"} fontWeight={"700"}>
                      {`${dayjs(rate.startDate).utc().format("MM/DD/YYYY")} - ${dayjs(rate.endDate)
                        .utc()
                        .format("MM/DD/YYYY")}`}
                    </Box>
                  </Typography>
                ))(),
                title: "Warning",
                confirmText: "Yes, Remove",
                styleBtns: {
                  yesButton: {
                    padding: "1.5rem",
                  },
                  noButton: {
                    display: "none",
                  },
                },

                onConfirm: () => {
                  setUserManagementConfirmationModal({
                    isOpen: false,
                  } as UserManagementConfirmationModalType);
                  onRemoveRateRecord(rate);
                },
                onCancel: () =>
                  setUserManagementConfirmationModal({
                    isOpen: false,
                  } as UserManagementConfirmationModalType),
              }),
            };
          }) || []
        );
      case ProviderRecords.LICENSING_INFO:
        return (
          props.serviceProvider.medicaidCredentials?.map((info, i) => {
            return {
              name: `${info.profession}`,

              dateRange: `${dayjs(info.startDate).utc().format("MM/DD/YYYY")} - ${dayjs(
                info.endDate,
              )
                .utc()
                .format("MM/DD/YYYY")}`,
              onDelete: getUserManageMentConfirmationModalSetterFn({
                isOpen: true,
                icon: "danger",
                body: (() => (
                  <Typography maxWidth={"320px"}>
                    Are you sure you would like to remove the license of{" "}
                    <Box component={"span"} fontWeight={"700"}>
                      {info.profession}
                    </Box>{" "}
                    for the date range of{" "}
                    <Box component={"span"} fontWeight={"700"}>
                      {`${dayjs(info.startDate).utc().format("MM/DD/YYYY")} - ${dayjs(info.endDate)
                        .utc()
                        .format("MM/DD/YYYY")}`}
                    </Box>
                  </Typography>
                ))(),
                title: "Warning",
                confirmText: "Yes, Remove",
                styleBtns: {
                  yesButton: {
                    padding: "1.5rem",
                  },
                  noButton: {
                    display: "none",
                  },
                },

                onConfirm: () => {
                  setUserManagementConfirmationModal({
                    isOpen: false,
                  } as UserManagementConfirmationModalType);
                  onRemoveLicensingInfo(info);
                },
                onCancel: () =>
                  setUserManagementConfirmationModal({
                    isOpen: false,
                  } as UserManagementConfirmationModalType),
              }),
            };
          }) || []
        );
      case ProviderRecords.BLOCK_BILLING_HISTORY:
        return (
          props.serviceProvider.blockBillingHistory
            ?.filter((b) => b.blockBillingForProvider)
            .map((billing, i) => {
              return {
                name: `Billing Start Date: ${dayjs(billing.blockBillingStartDate)
                  .utc()
                  .format("MM/DD/YYYY")}`,

                dateRange: `Date Posted: ${dayjs(billing.datePosted).utc().format("MM/DD/YYYY")}`,
              };
            }) || []
        );

      default:
        return [];
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.serviceProvider.medicaidCredentials,
    props.serviceProvider.rateRecords,
    providerRecordRateModalConfig.selected_record,
  ]);

  //#region HOOKFORMS

  const {
    register,
    watch,
    resetField,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<MedicaidInfoFormData>({
    resolver: yupResolver(medicaidInfoTabFormSchema),
    defaultValues: {
      providerRateArray: props.serviceProvider.rateRecords,
      medicaidCredentialsArray: props.serviceProvider.medicaidCredentials,
      npiNumber: props.serviceProvider.npi,
      medicaidNumber: props.serviceProvider.stateMedicaidNumber,
      isOnParticipationList: !!props.serviceProvider.isOnParticipationList,
      participationListStatus: getGetParticipantListStringFromEnum(
        (props.serviceProvider?.participationListStatus as ParticipationListStatusType) ||
          ParticipationListStatusType.NUMBER_0,
      ),
      blockBilling: {
        blockBillingForProvider: props.serviceProvider.blockBillingForProvider,
      },
      block_billing_history: props.serviceProvider.blockBillingHistory,
      medicaidCredentials: undefined,
      providerRate: undefined,
    },
  });
  //#endregion
  function arrayHasItem<T>(
    array: T[],
    arrayItem: T,
    getPropsToCompare: (item: T) => (keyof typeof item)[],
  ) {
    //if at least one item is found, where all properties match, return true
    return array.some((item) => {
      const props = getPropsToCompare(item);
      return props.every((prop) => JSON.stringify(item[prop]) === JSON.stringify(arrayItem[prop]));
    });
  }

  //#region METHODS
  const onSubmit: SubmitHandler<MedicaidInfoFormData> = (data) => {
    let isValid = true;

    // performs transformation to disallow empty objects from being sent to the backend
    const transformedData = function <T>(data: T, dataKeys: (data: T) => (keyof typeof data)[]) {
      return (dataKeys(data).some((key) => !!data[key]) ? data : undefined) as T;
    };

    const transformed_medicaid_credential = transformedData(
      data.medicaidCredentials as MedicaidCredential,
      () => ["endDate", "licenseNumber", "nameOnLicense", "profession", "startDate"],
    );

    const transformed_providerRate = transformedData(data.providerRate as ProviderRate, () => [
      "endDate",
      "hourlyRate",
      "startDate",
    ]);

    // const block_billing = transformedData(data.block_billing., (data) => ["blockBillingForProvider", "blockBillingStartDate"]);

    const medicaidCredentialsRecords = produce(
      props.serviceProvider.medicaidCredentials ?? [],
      (draft) => {
        //perform transformation check

        if (transformed_medicaid_credential) {
          // check if the credentials we are looking to add already exists
          // also ensure that the credentials we are checking against has no duplicates to avoid user being unable to save, we are validating for new credentials only
          // const nonDuplicateDraft = removeArrrayDuplicates([...draft], ()=>["profession", "nameOnLicense", "licenseNumber", "startDate", "endDate"]);
          const exists = arrayHasItem(
            props.serviceProvider.medicaidCredentials ?? [],
            transformed_medicaid_credential,
            () => ["profession", "nameOnLicense", "licenseNumber", "startDate", "endDate"],
          );

          if (exists) {
            onFailedSave("Licensing Information already exists in our records");
            isValid = false;
            return;
          }
          draft.push({
            ...transformed_medicaid_credential,
            state: props.stateInUs,
          });
        }
      },
    );

    const providerRateRecords = produce(props.serviceProvider.rateRecords ?? [], (draft) => {
      if (transformed_providerRate) {
        // check if the rate we are looking to add already exists
        // also ensure that the rate we are checking against has no duplicates to avoid user being unable to save, we are validating for new rate only
        // const isUnique = uniqueYupArrayTestCallback(draft, () => ["hourlyRate", "startDate", "endDate"]);
        const exists = arrayHasItem(
          props.serviceProvider.rateRecords ?? [],
          transformed_providerRate,
          () => ["hourlyRate", "startDate", "endDate"],
        );
        if (exists) {
          onFailedSave("Provider Rate already exists in our records");
          isValid = false;
          return;
        }
        draft.push(transformed_providerRate);
      }
    });

    const blockBillingHistoryRecords = produce(
      props.serviceProvider.blockBillingHistory ?? [],
      (draft) => {
        if (data?.blockBilling?.blockBillingForProvider) {
          const exists = arrayHasItem(
            data.block_billing_history as BlockBillingHistory[],
            data.blockBilling,
            () => ["blockBillingForProvider", "blockBillingStartDate", "datePosted"],
          );

          if (exists) {
            onFailedSave("Block Billing Information already exists in our records");
            isValid = false;
            return;
          }
          draft.push({
            ...(data.blockBilling as BlockBillingHistory),
            datePosted: new Date(new Date().toDateString()),
          });
        } else if (data.blockBilling?.blockBillingForProvider) {
          return [
            {
              ...data.blockBilling,
              datePosted: new Date(new Date().toDateString()),
            } as BlockBillingHistory,
          ];
        }
      },
    );

    if (!isValid) return;
    // return;

    onSaveMedicaidInfo({
      ...data,
      medicaidCredentialsArray: medicaidCredentialsRecords,
      providerRateArray: providerRateRecords,
      block_billing_history: blockBillingHistoryRecords,
    });
  };

  const onError: SubmitErrorHandler<MedicaidInfoFormData> = (errors) => {
    if (errors.providerRate?.message) {
      onFailedSave("Provider Rate: " + errors.providerRate?.message);
    } else if (errors.medicaidCredentials?.message) {
      onFailedSave("Licensing Info: " + errors.medicaidCredentials?.message);
    } else if (errors.blockBilling?.message) {
      onFailedSave("Block Billing: " + errors.blockBilling?.message);
    }
  };

  async function onRemoveRateRecord(record: ProviderRate) {
    const newProviderRate = produce(props.serviceProvider.rateRecords, (draft) => {
      const index = draft?.findIndex((item) => JSON.stringify(item) === JSON.stringify(record));
      if (index !== -1 && index !== undefined) {
        draft?.splice(index, 1);
      }
    });

    onSaveMedicaidInfo({
      ...watch(),
      providerRateArray: newProviderRate,
    });
  }

  async function onRemoveLicensingInfo(licensingInfo: MedicaidCredential) {
    const newMedicaidCredentials = produce(props.serviceProvider.medicaidCredentials, (draft) => {
      const index = draft?.findIndex(
        (item) => JSON.stringify(item) === JSON.stringify(licensingInfo),
      );
      if (index !== -1 && index !== undefined) {
        draft?.splice(index, 1);
      }
    });

    onSaveMedicaidInfo({
      ...watch(),
      medicaidCredentialsArray: newMedicaidCredentials,
    });
  }

  function onSaveMedicaidInfo(data: MedicaidInfoFormData) {
    const serviceProviderProfile = produce(props.serviceProvider, (draft) => {
      draft.npi = data.npiNumber ?? undefined;
      draft.stateMedicaidNumber = data.medicaidNumber ?? undefined;

      draft.medicaidCredentials =
        (data.medicaidCredentialsArray as MedicaidCredential[]) ?? undefined;
      draft.rateRecords = (data.providerRateArray as ProviderRate[]) ?? undefined;

      draft.isOnParticipationList = data.isOnParticipationList;
      draft.participationListStatus = data.isOnParticipationList
        ? getGetParticipantListEnumFromString(data?.participationListStatus ?? "")
        : undefined; //add validation, if !isOnList then set to undefined
      draft.blockBillingStartDate = data.blockBilling?.blockBillingStartDate ?? undefined;
      draft.blockBillingForProvider = data.blockBilling?.blockBillingForProvider ?? undefined;
      draft.blockBillingHistory =
        (data.block_billing_history as BlockBillingHistory[]) ?? undefined;
    });
    setShowMutationStatusModal(true);
    mutateServiceProvider({ profile: serviceProviderProfile });
  }

  function onSaveSucess() {
    setValue("providerRateArray", props.serviceProvider.rateRecords);
    setValue("medicaidCredentialsArray", props.serviceProvider.medicaidCredentials);
    setValue("block_billing_history", props.serviceProvider.blockBillingHistory);
    resetField("medicaidCredentials.licenseNumber");
    resetField("medicaidCredentials.nameOnLicense");
    resetField("medicaidCredentials.profession");
    resetField("medicaidCredentials.startDate");
    resetField("medicaidCredentials.endDate");
    resetField("providerRate.hourlyRate");
    resetField("providerRate.startDate");
    resetField("providerRate.endDate");
    resetField("blockBilling.blockBillingStartDate");
  }
  //#endregion

  //#endregion

  //#region SECTIONS
  const participantListInfo =
    props.stateInUs.toUpperCase() === "NH" ? null : (
      <GridSectionLayout
        headerConfig={{
          title: "Participant List Info",
        }}
        divider
        rows={[
          {
            fullwidth: true,
            cells: [
              <Controller
                key={"isOnParticipationList"}
                control={control}
                name="isOnParticipationList"
                render={({ field }) => (
                  <XNGRadioGroup
                    value={field.value ? "yes" : "no"}
                    onChange={(e) => {
                      field.onChange(e.target.value === "yes");
                    }}
                    options={["Yes", "No"]}
                    values={["yes", "no"]}
                    formLabel={
                      <Typography component={"span"}>
                        Is{" "}
                        <Box component={"span"} sx={{ color: "primary.main", fontWeight: 700 }}>
                          {props.serviceProvider.firstName} {props.serviceProvider.lastName}
                        </Box>{" "}
                        on the Participant List?
                      </Typography>
                    }
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      pl: "5px",
                    }}
                    radioSx={{}}
                  />
                )}
              />,
            ],
          },
          {
            cells: [
              watch("isOnParticipationList") ? (
                <Controller
                  control={control}
                  name="participationListStatus"
                  render={({ field }) => (
                    <XNGDropDown
                      id={"participant-list-status"}
                      ref={field.ref}
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      items={GetParticipantListInfoOptions()}
                      label={"Participant List Status"}
                      fullWidth
                      maxwidth="100%"
                      useError={errors.participationListStatus?.message}
                    />
                  )}
                />
              ) : null,
            ],
          },
        ]}
      />
    );

  const providerRateRecords =
    props.stateInUs.toUpperCase() === "TX" ? null : (
      <GridSectionLayout
        headerConfig={{
          title: "Provider Rate",
          headerContent: (
            <XNGButtonIconLink
              label="History"
              startIcon={<Folder />}
              onClick={() => {
                setProviderRecordRateModalConfig({
                  title: "Historical Provider Rate Records",
                  canDeleteRecord: true,
                  subtitle: "Below are this provider’s historical rate dates.",
                  open: true,
                  selected_record: ProviderRecords.RATE,
                });
              }}
            />
          ),
        }}
        divider
        useError={errors?.providerRate?.message}
        rows={[
          {
            cells: [
              <XNGInput2
                key={"hourly-rate"}
                type={"number"}
                useAdornment={{ start: "$" }}
                label="Hourly Rate"
                id={"hourly-rate-id"}
                fullWidth
                useError={errors?.providerRate?.hourlyRate?.message}
                inputProps={{
                  step: "0.01",
                }}
                {...register(`providerRate.hourlyRate`)}
                disbaleBottomMargin
              />,
              <Typography
                key={"per-hour"}
                variant="body1"
                sx={{ height: "100%", display: "flex", alignItems: "center" }}
              >
                Per hour
              </Typography>,
            ],
          },
          {
            cells: [
              <Controller
                key={"start-date"}
                control={control}
                name={`providerRate.startDate`}
                render={({ field }) => (
                  <XNGDateField
                    label="Start Date"
                    value={field.value ? dayjs(new Date(field.value).toDateString()).utc() : null}
                    onChange={field.onChange}
                    fullWidth
                  />
                )}
              />,
              <Controller
                key={"end-date"}
                control={control}
                name={`providerRate.endDate`}
                render={({ field }) => (
                  <XNGDateField
                    label="End Date"
                    value={field.value ? dayjs(new Date(field.value).toDateString()) : null}
                    onChange={field.onChange}
                    useError={errors?.providerRate?.endDate?.message}
                    fullWidth
                  />
                )}
              />,
            ],
          },
        ]}
      />
    );

  const licensingInfo = (
    <GridSectionLayout
      headerConfig={{
        title: "Licensing Info",
        headerContent: (
          <XNGButtonIconLink
            label="History"
            startIcon={<Folder />}
            onClick={() => {
              setProviderRecordRateModalConfig({
                title: "Historical Provider Licensing Info",
                subtitle: "Below are this provider’s historical licenses on file.",
                canDeleteRecord: true,
                open: true,
                selected_record: ProviderRecords.LICENSING_INFO,
              });
            }}
          />
        ),
      }}
      useError={errors?.medicaidCredentials?.message}
      divider
      rows={[
        {
          cells: [
            <XNGInput2
              type={"text"}
              label="Name on License"
              id={"name-on-license-id"}
              fullWidth
              {...register(`medicaidCredentials.nameOnLicense`)}
              disbaleBottomMargin
            />,
            <XNGInput2
              type={"text"}
              label="Profession"
              id={"profession-id"}
              fullWidth
              {...register(`medicaidCredentials.profession`)}
              disbaleBottomMargin
            />,
            <XNGInput2
              type={"text"}
              label="License Number "
              id={"license-number-id"}
              {...register(`medicaidCredentials.licenseNumber`)}
              fullWidth
              disbaleBottomMargin
            />,
          ],
        },
        {
          useCellStyling: {
            indexes: 2,
            sx: {
              display: "flex",
              gap: 2,
              alignItems: "center",
            },
          },
          cells: [
            <Controller
              key={"smedicaidCredentials.startDate"}
              control={control}
              name={`medicaidCredentials.startDate`}
              render={({ field }) => (
                <XNGDateField
                  label="License Start Date"
                  value={field.value ? dayjs(new Date(field.value).toDateString()).utc() : null}
                  onChange={field.onChange}
                  fullWidth
                />
              )}
            />,
            <Controller
              key={"medicaidCredentials.endDate"}
              control={control}
              name={`medicaidCredentials.endDate`}
              render={({ field }) => (
                <XNGDateField
                  label="License Expiration Date"
                  value={field.value ? dayjs(new Date(field.value).toDateString()).utc() : null}
                  onChange={field.onChange}
                  useError={errors?.medicaidCredentials?.endDate?.message}
                  fullWidth
                />
              )}
            />,
            // <Box>
            //   <XNGButtonIconLink
            //     label="License Photo 2023.PDF"
            //     color="black"
            //     start
            //     icon={<XNGICONS.PhotoPlaceholder />}
            //   />
            // </Box>,
          ],
        },
      ]}
    />
  );

  const identification = (
    <GridSectionLayout
      headerConfig={{
        title: "Identification",
      }}
      divider
      rows={[
        {
          cells: [
            <XNGInput2
              type={"text"}
              label="NPI Number"
              id={"npi-number-id"}
              {...register("npiNumber")}
              fullWidth
              disbaleBottomMargin
            />,

            <XNGInput2
              type={"text"}
              label="State Medicaid Number"
              id={"state-medicaid-number-id"}
              {...register("medicaidNumber")}
              fullWidth
              disbaleBottomMargin
            />,
          ],
        },
      ]}
    />
  );

  const blockBillingForProvider = (
    <GridSectionLayout
      headerConfig={{
        title: "Block Billing for Provider",
        headerContent: (
          <XNGButtonIconLink
            label="History"
            startIcon={<Folder />}
            onClick={() => {
              setProviderRecordRateModalConfig({
                open: true,
                title: "Block Billing History",
                canDeleteRecord: false,
                subtitle: "Below are this provider’s Block Billing history on file.",
                selected_record: ProviderRecords.BLOCK_BILLING_HISTORY,
              });
            }}
          />
        ),
      }}
      bottomMargin={"10rem"}
      rows={[
        {
          fullwidth: true,
          cells: [
            <Controller
              key={"blockBilling.blockBillingForProvider"}
              control={control}
              name="blockBilling.blockBillingForProvider"
              render={({ field }) => (
                <XNGRadioGroup
                  value={field.value ? "yes" : "no"}
                  onChange={(e) => {
                    field.onChange(e.target.value === "yes");
                  }}
                  options={["Yes", "No"]}
                  values={["yes", "no"]}
                  formLabel={
                    <Typography>
                      Block Billing for{" "}
                      <Box component={"span"} sx={{ color: "primary.main", fontWeight: 700 }}>
                        {props.serviceProvider.firstName} {props.serviceProvider.lastName}
                      </Box>
                      ?
                    </Typography>
                  }
                  sx={{
                    mb: 2,
                  }}
                  radioSx={{}}
                />
              )}
            />,
          ],
        },
        {
          cells: [
            watch("blockBilling.blockBillingForProvider") ? (
              <Controller
                control={control}
                name={`blockBilling.blockBillingStartDate`}
                render={({ field }) => (
                  <XNGDateField
                    label="Block Billing Start Date"
                    value={field.value ? dayjs(new Date(field.value).toDateString()).utc() : null}
                    onChange={field.onChange}
                    fullWidth
                    useError={errors?.blockBilling?.blockBillingStartDate?.message}
                  />
                )}
              />
            ) : null,
          ],
        },
      ]}
    />
  );
  // #endregion

  return (
    <>
      <Box position={"relative"}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <StaffDirectoryProfileTabToolbar />
          {participantListInfo}
          {providerRateRecords}
          {licensingInfo}
          {identification}
          {blockBillingForProvider}
        </form>

        <ProviderRecordsModal
          // key={providerRateDefault.length.toString() + medicaidCredentialsDefault.length.toString()}
          title={providerRecordRateModalConfig?.title ?? ""}
          subTitle={providerRecordRateModalConfig?.subtitle}
          isOpen={providerRecordRateModalConfig.open}
          record={modalRecord}
          canDelete={providerRecordRateModalConfig.canDeleteRecord}
          onClose={() =>
            setProviderRecordRateModalConfig({
              open: false,
              canDeleteRecord: false,
            })
          }
        />
      </Box>
      <QueryStatusModal
        isOpen={showMutationStatusModal}
        status={mutateServiceProviderStatus}
        content={{
          pendingTitle: "Saving Medicaid Information",
          successTitle: "Thank You!",
          errorTitle: "Error",
          errorBody: "Failed to save Medicaid Information. Please refresh window and try again.",
          successBody: "Medicaid Information Saved Successfully",
        }}
        onSettledClose={() => {
          setShowMutationStatusModal(false);
        }}
      />
    </>
  );
};

export default MedicaidInfoTabContent;
