import { Paper, Divider, Box, Typography, Skeleton } from "@mui/material";
import { motion } from "framer-motion";

const UserApprovalsSkeletonCard = () => {
  const partitionProps = {
    width: "50%",
    maxWidth: "50%",
    overflow: "hidden",
  };

  const avatarNameSection = (
    <Box display={"flex"} alignItems={"center"} justifyContent={"flex-start"} gap={2} pt={2}>
      <Skeleton variant="circular" width={76} height={76} />
      <Skeleton variant="rectangular" width={180} height={60} />
    </Box>
  );

  const clientDistrictStatusSection = (
    <Box
      sx={{
        display: "flex",
        gap: 1,
      }}
    >
      <Box {...partitionProps}>
        <CardSectionTitle title={"Client"} />
        <Skeleton variant="rectangular" width={"100%"} height={20} />
      </Box>

      <Box {...partitionProps}>
        <CardSectionTitle title={"Status"} />
        <Skeleton variant="rectangular" width={"100%"} height={20} />
      </Box>
    </Box>
  );

  const districtsSection = (
    <Box position={"relative"}>
      <CardSectionTitle title={"District(s)"} />
      <Skeleton variant="rectangular" width={"100%"} height={35} />
    </Box>
  );

  const emailSection = (
    <Box>
      <CardSectionTitle title={"Email"} />
      <Skeleton variant="rectangular" width={"100%"} height={20} />
    </Box>
  );

  const serviceProviderTypeSection = (
    <Box>
      <CardSectionTitle title={"Service Provider Type(s)"} />
      <Skeleton variant="rectangular" width={"100%"} height={20} />
    </Box>
  );

  const xlogsRoleDropDownSection = (
    <Box>
      <CardSectionTitle title={"X Logs Role Assigned"} />
      <Skeleton variant="rectangular" width={"100%"} height={20} />
    </Box>
  );

  // VIEWS FOR THE CARD
  const mainCardView = (
    <>
      <Box>{avatarNameSection}</Box>
      {clientDistrictStatusSection}
      {emailSection}
      {districtsSection}
      {serviceProviderTypeSection}
      <Divider sx={{ borderBottomWidth: 2 }} />
      {xlogsRoleDropDownSection}
    </>
  );

  return (
    <Paper
      component={motion.div}
      sx={{
        maxWidth: 320,
        minWidth: 320,
        minHeight: 395,
        overflow: "hidden",
        pt: 1,
        pb: 4,
        px: 2.6,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        "&:hover": {
          cursor: "pointer",
          boxShadow: "0px 0px 8px rgba(0,0,0,0.2)",
        },
      }}
      variant="outlined"
      whileHover={{
        scale: 1.02,
        transition: {
          duration: 0.03,
        },
      }}
      whileTap={{
        scale: 0.98,
      }}
    >
      {mainCardView}
    </Paper>
  );
};

// Title for the card sections
const CardSectionTitle = (props: { title: string; disableGutter?: boolean }) => {
  return (
    <Typography fontWeight={700} color={"primary.main"} mb={props.disableGutter ? "1px" : "5px"}>
      {props.title}
    </Typography>
  );
};

export default UserApprovalsSkeletonCard;
