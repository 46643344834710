import { Button, styled } from "@mui/material";

export const PrescribingProviderActionButton = styled(Button)(({ theme, variant }) => ({
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    backgroundColor: "white",
    border: `2px solid ${theme.palette.primary.main}`,
    height: "43px !important",
    borderRadius: "3px",
    flexShrink: 0,
    ...(variant === "outlined" && {
      backgroundColor: "transparent",
      border: `2px solid ${theme.palette.primary.main}`,
    }),
    ...(variant === "contained" && {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    })
  }));


 