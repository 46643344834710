import { useMediaQuery } from "@mui/material";

/**
 * Custom hook that calculates the height of a grid based on the current viewport width.
 * @returns The calculated height of the grid as a CSS value.
 */
const useGridBreakpointHeight = () => {
  const hasHitFirstBreakPoint = useMediaQuery("(width <= 600px)");
  const hasHitSecondBreakPoint = useMediaQuery("(width <= 735px)");

  if (hasHitFirstBreakPoint) return "calc(100vh - 500px)";
  if (hasHitSecondBreakPoint) return "calc(100vh - 490px)";
  return "calc(100vh - 400px)";
};

export default useGridBreakpointHeight;
