import { useCallback, useState } from "react";
import { ServiceProviderRef } from "../../../../profile-sdk";
import { useAddProviderToApproverCaseload } from "./use_add_provider_to_approver_caseload";
import { useAddProviderToProxyCaseload } from "./use_add_provider_to_proxy_caseload";

export type CreateNewProviderModalContext = "approver" | "dec" | null;

/**
 * Contract for communicating with the Create New Provider modal.
 *
 * Do not expose `setIsOpen`. Controlling modal visibility should be done via
 * the open/close handler functions so the expected data gets provided.
 */
interface CreateNewProviderModalController {
  isOpen: boolean;
  handleClose: () => void;
  handleOpen: (ctx: CreateNewProviderModalContext) => void;
  handleSubmit: (sp: ServiceProviderRef) => void;
}

export function useCreateNewProviderModalController(): CreateNewProviderModalController {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [ctx, setCtx] = useState<CreateNewProviderModalContext>(null);

  const addToApproverCaseload = useAddProviderToApproverCaseload();
  const addToProxyCaseload = useAddProviderToProxyCaseload();

  const handleSubmit = useCallback(
    (newSP: ServiceProviderRef) => {
      if (ctx === "approver") {
        addToApproverCaseload(newSP);
      }
      if (ctx === "dec") {
        addToProxyCaseload(newSP);
      }

      handleClose();
    },
    [ctx],
  );

  const handleClose = useCallback(() => {
    setCtx(null);
    setIsOpen(false);
  }, []);
  const handleOpen = useCallback((ctx: CreateNewProviderModalContext) => {
    setCtx(ctx);
    setIsOpen(true);
  }, []);

  return { isOpen, handleOpen, handleClose, handleSubmit };
}
