import { XLogsRoleStrings } from "../../../../../../context/types/xlogsrole";
import {
  DistrictAccessRequestRef,
  ServiceProviderRef,
  UserManagementCard,
} from "../../../../../../profile-sdk";
import UsersTabState, { UsersTabAction } from "../../types/users_tabs_state";
import produce from "immer";

export function initialUserSelectState(tabData: { cards: UserManagementCard[] }): UsersTabState {
  const selectedUsersOptions = new Map<string, UserManagementCard>();
  const selectedDistricts = new Map<string, DistrictAccessRequestRef[]>();
  const selectedXlogsRole = new Map<string, XLogsRoleStrings>();
  const selectedServiceProviderTypes = new Map<string, ServiceProviderRef[]>();

  tabData.cards.forEach((card) => {
    selectedUsersOptions.set(card.user?.id ?? "", card);
    selectedDistricts.set(card.user?.id ?? "", card.districtAccessRequests || []);
    selectedXlogsRole.set(card.user?.id ?? "", "Service Provider - Autonomous");
    selectedServiceProviderTypes.set(card.user?.id ?? "", card.user?.serviceProviderTypes || []);
  });

  return {
    viewOnlySelected: false,
    searchValue: "",
    usersOptions: selectedUsersOptions,
    filteredUsers: Array.from(selectedUsersOptions.keys()),
    viewType: "grid",
    selectedDistricts: selectedDistricts,
    selectedServiceProviderTypes: selectedServiceProviderTypes,
    selectedXlogsRole: selectedXlogsRole,
    selectedUsers: [],
  };
}

function searchUsers(searchValue: string, usersOptions: Map<string, UserManagementCard>): string[] {
  return Array.from(usersOptions.values())
    .filter((userManagementCard) => {
      const fullName = `${userManagementCard.user?.firstName} ${userManagementCard.user?.lastName}`;
      return fullName.toLowerCase().includes(searchValue?.toLowerCase() ?? "");
    })
    .map((userManagementCard) => userManagementCard.user?.id ?? "");
}

function getSelectedUsers(viewSelected: boolean, draftState: UsersTabState): string[] {
  return viewSelected && draftState.selectedUsers.length > 0
    ? draftState.selectedUsers
    : searchUsers(draftState.searchValue ?? "", draftState.usersOptions);
}

function userApprovalsOrDenialsReducer(state: UsersTabState, action: UsersTabAction) {
  switch (action.type) {
    case "select_districts": {
      const newState = produce(state, (draftState) => {
        draftState.selectedDistricts.set(action.payload.userId, action.payload.selectedData);
      });
      return newState;
    }
    // break;
    case "select_xlogs_role": {
      const newState = produce(state, (draftState) => {
        draftState.selectedXlogsRole.set(action.payload.userId, action.payload.selectedData);
      });
      return newState;
    }
    case "select_service_provider_types": {
      const newState = produce(state, (draftState) => {
        draftState.selectedServiceProviderTypes.set(
          action.payload.userId,
          action.payload.selectedData,
        );
      });
      return newState;
    }
    case "search_users": {
      const newstate = produce(state, (draftState) => {
        draftState.searchValue = action.payload;
        draftState.viewOnlySelected = false;
        draftState.filteredUsers = searchUsers(action.payload, draftState.usersOptions);
      });
      return newstate;
    }
    case "select_users": {
      const newState = produce(state, (draftState) => {
        draftState.selectedUsers = action.payload;
        if (draftState.selectedUsers.length === draftState.usersOptions.size) {
          draftState.viewOnlySelected = false;
          draftState.filteredUsers = searchUsers(
            draftState.searchValue ?? "",
            draftState.usersOptions,
          );
        }
      });
      return newState;
    }
    case "select_single_user": {
      const newState = produce(state, (draftState) => {
        draftState.selectedUsers.push(action.payload);
      });

      return newState;
    }
    case "remove_user": {
      const newState = produce(state, (draftState) => {
        draftState.selectedUsers = draftState.selectedUsers.filter(
          (userId) => userId !== action.payload,
        );
        draftState.filteredUsers = getSelectedUsers(draftState.viewOnlySelected, draftState);
        if (draftState.selectedUsers.length <= 0) {
          draftState.viewOnlySelected = false;
          draftState.filteredUsers = searchUsers(
            draftState.searchValue ?? "",
            draftState.usersOptions,
          );
        }
      });

      return newState;
    }
    case "select_view_type": {
      const newState = produce(state, (draftState) => {
        draftState.viewType = action.payload;
      });
      return newState;
    }
    case "view_selected": {
      const newState = produce(state, (draftState) => {
        draftState.filteredUsers = getSelectedUsers(action.payload, draftState);
        draftState.viewOnlySelected = action.payload;
      });
      return newState;
    }
    case "update_users": {
      const newState = produce(state, (draftState) => {
        draftState.usersOptions.clear();
        action.payload.forEach((card) => {
          draftState.usersOptions.set(card.user?.id!, card);
          if (!draftState.selectedDistricts.has(card.user!.id!)) {
            draftState.selectedDistricts.set(
              card.user?.id ?? "",
              card.districtAccessRequests || [],
            );
          }
          if (!draftState.selectedXlogsRole.has(card.user!.id!)) {
            draftState.selectedXlogsRole.set(card.user?.id ?? "", "Service Provider - Autonomous");
          }
          if (!draftState.selectedServiceProviderTypes.has(card.user!.id!)) {
            draftState.selectedServiceProviderTypes.set(
              card.user?.id ?? "",
              card.user?.serviceProviderTypes || [],
            );
          }
        });
        draftState.selectedDistricts.forEach((_, userId) => {
          if (!draftState.usersOptions.has(userId)) {
            draftState.selectedDistricts.delete(userId);
          }
        });
        draftState.selectedServiceProviderTypes.forEach((_, userId) => {
          if (!draftState.usersOptions.has(userId)) {
            draftState.selectedServiceProviderTypes.delete(userId);
          }
        });
        draftState.selectedXlogsRole.forEach((_, userId) => {
          if (!draftState.usersOptions.has(userId)) {
            draftState.selectedXlogsRole.delete(userId);
          }
        });
        draftState.viewOnlySelected = false;
        draftState.filteredUsers = draftState.filteredUsers.filter((userId) =>
          draftState.usersOptions.has(userId),
        ); // remove users that are not in the new data
        draftState.filteredUsers = searchUsers(
          draftState.searchValue ?? "",
          draftState.usersOptions,
        ); // reapply search
        draftState.selectedUsers = draftState.selectedUsers.filter((userId) =>
          draftState.usersOptions.has(userId),
        );
      });

      return newState;
    }
    case "initialize": {
      const newState = produce(state, (_) => {
        return initialUserSelectState({ cards: action.payload });
      });

      return newState;
    }
    default:
      return state;
  }
}

export default userApprovalsOrDenialsReducer;
