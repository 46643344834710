import localStorageKeys from "../../../../constants/localStorageKeys";

import isEqual from "lodash.isequal";
const { CURRENT_SESSION_KEY, CURRENT_STUDENT_KEY } = localStorageKeys;

/**
 * Provides comparison boolean and cleanup functions used in the BlockNavigationModal.
 */
export function useUnsavedChangesComparison(props: {
  editedState: any;
  view: string;
  dirty: boolean;
}) {
  const { editedState, view, dirty } = props;
  let cleanup = () => {};
  let comparison: boolean = false;

  if (view === "notator" && editedState) {
    comparison = !isEqual(editedState, JSON.parse(localStorage.getItem(CURRENT_SESSION_KEY)!))
    cleanup = () => localStorage.removeItem(CURRENT_SESSION_KEY);
  } else if (view === "student_goals_tab" && editedState) {
    comparison =
      dirty || !isEqual(editedState, JSON.parse(localStorage.getItem(CURRENT_SESSION_KEY)!))
    cleanup = () => localStorage.removeItem(CURRENT_STUDENT_KEY);
  }

  return { comparison, cleanup };
}
