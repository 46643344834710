import sessionStorageKeys from "../browser/sessionStorageKeys";
import { clearLoggedInUserCookie } from "./cookies";
import { msalInstance } from "../auth/msalInstance";

export default function LogoutRedirect() {
  clearLoggedInUserCookie();

  if (sessionStorage.getItem(sessionStorageKeys.ALL_SESSIONS_ELECTRONIC_SIGNATURE_KEY)) {
    sessionStorage.removeItem(sessionStorageKeys.ALL_SESSIONS_ELECTRONIC_SIGNATURE_KEY);
  }
  msalInstance.logoutRedirect();
}
