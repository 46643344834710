const sessionStorageKeys = {
  FOCUSED_DATE_KEY: "focusedDate",
  ALL_SESSIONS_ELECTRONIC_SIGNATURE_KEY: "allSessionsElectronicSignature",
  NON_SCHOOL_DAYS: "nonSchoolDays",
  ALL_DISTRICTS: "allDistricts",
} as const;

export type XNGSessionStorageKey = (typeof sessionStorageKeys)[keyof typeof sessionStorageKeys];

export default sessionStorageKeys;
