import { useEffect, useMemo, useState } from "react";
import { FutureTabs } from "../../types/types";
import XNGInput from "../../../../design/low-level/input";
import { Stack } from "@mui/material";
import { useNotatorTools } from "../../tools";
import AutoBreakpointNotatorLayoutTwoColumns from "../../layouts/tab_inner_viewport_layout_two_sections";
import { produce } from "immer";
import { StagingInput } from "./components/staging_input";
import { useStagingInputDetails } from "./hooks/use_staging_details";
import CheckboxOption from "./components/checkbox_option";
import { useNotatorObservations } from "./hooks/use_notator_observations";
import useObservationTabDataOperationHandlers from "./hooks/use_observation_tab_data_operation_handlers";

// This is purely a presentational, or "dumb" component. This is not to house any of its own state. It should only ever provide callbacks.
// See more:
// https://medium.com/@dan_abramov/smart-and-dumb-components-7ca2f9a7c7d0
// https://medium.com/@thejasonfile/dumb-components-and-smart-components-e7b33a698d43

export default function ObservationsTabView(
  props: Readonly<{
    applyFuture: Array<FutureTabs[]>;
    setApplyFuture: React.Dispatch<React.SetStateAction<Array<FutureTabs[]>>>;
    selectedStudentIndex?: number;
  }>,
) {
  const { setDraftSession, selectedStudentIndex, readOnly, draftSession } = useNotatorTools();

  const actualStudentIndex = props.selectedStudentIndex ? props.selectedStudentIndex : selectedStudentIndex;

  const [indexer, setIndexer] = useState<number>(0);

  const [studentNotes, setStudentNotes] = useState<string>(
    draftSession.studentJournalList![actualStudentIndex].observationSection?.narrative ?? " ",
  );

  const areObservationOptionsReadonly = useMemo<boolean>(() => {
    return draftSession.status === 4 || draftSession.status === 5;
  }, [draftSession.status]);

  useEffect(() => {
    if (
      props.applyFuture[actualStudentIndex]?.findIndex((section) => section.section === 6) !=
        -1 &&
      props.applyFuture[actualStudentIndex]?.[indexer]
    ) {
      setIndexer(
        props.applyFuture[actualStudentIndex].findIndex((section) => section.section === 6),
      );
    }

    // Reset student notes
    if (studentNotes !== "") {
      setStudentNotes(
        draftSession.studentJournalList![actualStudentIndex].observationSection?.narrative ?? " ",
      );
    }
  }, [actualStudentIndex]);

  const stagingInput = useStagingInputDetails({selectedStudentIndex: actualStudentIndex});
  const observations = useNotatorObservations({selectedStudentIndex: actualStudentIndex});
  const handlers = useObservationTabDataOperationHandlers({selectedStudentIndex: actualStudentIndex});

  return (
    <AutoBreakpointNotatorLayoutTwoColumns
      sx={{ ...(areObservationOptionsReadonly && { pointerEvents: "none" }) }}
      left={
        <Stack sx={{ ml: ".25rem" }}>
          {observations.default.map((v, i) => (
            <CheckboxOption
              key={i}
              label={v.text}
              checked={v.checked}
              onClick={() => handlers.toggleCheckedObservation(v.text)}
            />
          ))}

          {observations.custom.map((v, i) => (
            <CheckboxOption
              key={i}
              label={v.text}
              checked={v.checked}
              onClick={() => handlers.toggleCheckedObservation(v.text)}
              useClose={{
                onClose: () => handlers.deleteCustomObservation(v.text),
                tooltip: "Remove custom observation",
              }}
            />
          ))}

          <StagingInput
            value={stagingInput.value}
            onChange={stagingInput.setValue}
            onValidate={stagingInput.handleValidate}
            onConfirm={stagingInput.handleConfirm}
          />
        </Stack>
      }
      leftConfig={{ title: "Student Observations" }}
      right={
        <XNGInput
          multiline
          row={12}
          defaultValue={studentNotes}
          value={studentNotes}
          onChange={(e) => {
            setStudentNotes(e.target.value);
          }}
          onBlur={() =>
            setDraftSession(
              produce((draft) => {
                draft.studentJournalList![actualStudentIndex].observationSection!.narrative =
                  studentNotes;
              }),
            )
          }
          disabled={readOnly}
        />
      }
      rightConfig={{ title: "Student Notes" }}
    />
  );
}

// TODO: Reinstate this later. We need to make sure the accurate but slower code works properly before adding this optimization in.
// How to add: Just replace `<CheckboxOption` with `<OptimisticCheckbox` on lines 66, 75. Easy!
// function OptimisticCheckbox(props: Readonly<CheckboxOptionProps>) {
//   const { selectedStudentIndex } = useNotatorTools();
//   const [checked, setChecked] = useState<boolean>(props.checked);

//   useEffect(() => setChecked(props.checked), [selectedStudentIndex]);

//   function handleToggleOptimistically() {
//     setChecked(!checked);
//     setTimeout(() => {
//       props.onClick();
//     }, 0);
//   }

//   return <CheckboxOption {...props} checked={checked} onClick={handleToggleOptimistically} />;
// }
