import { Box, styled } from "@mui/material";

const StudentCardGrid = styled(Box)({
  maxWidth: "100%",
  display: "flex",
  flexWrap: "wrap",
  gap: "1rem",
  justifyContent: "center",
});

export default StudentCardGrid;
