import { ScheduleOfServicesInterval } from "../../../../../profile-sdk";

/**
 * TECH DEBT: Using a record to map from enum to string. This is unfavored as it created a dependency loop if enums change.
 * The solution is to have API return strings directly instead of enum types from our OpenAPI SDKs.
 */
export const scheduleOfServicesIntervalToString: Record<ScheduleOfServicesInterval, string> = {
  [ScheduleOfServicesInterval.NUMBER_0]: "Daily",
  [ScheduleOfServicesInterval.NUMBER_1]: "Weekly",
  [ScheduleOfServicesInterval.NUMBER_2]: "Biweekly",
  [ScheduleOfServicesInterval.NUMBER_3]: "Monthly",
  [ScheduleOfServicesInterval.NUMBER_4]: "Bimonthly",
  [ScheduleOfServicesInterval.NUMBER_5]: "Per Timespan",
};
