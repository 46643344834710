import { FormControlLabel, Checkbox, FormControlLabelProps, CheckboxProps } from "@mui/material";
import { CSSProperties } from "react";
// import usePalette from "../../hooks/usePalette";

type Props = {
  label: FormControlLabelProps["label"];
  name?: FormControlLabelProps["name"];
  checked?: CheckboxProps["checked"];
  disabled?: CheckboxProps["disabled"];
  onChange?: CheckboxProps["onChange"];
  size?: CheckboxProps["size"];
  whiteSpace?: CSSProperties["whiteSpace"];
  onBlur?: CheckboxProps["onBlur"];
  ["aria-label"]?: string;
};

/**
 * ## DEPRECATED
 *
 * Do not reuse or modify, replace references with MUI / Fortitude components in new development.
 *
 * See: https://msb-tlm.atlassian.net/wiki/spaces/XL/pages/508035073/MSB+Design+System+Fortitude#Note-for-Developers%3A-Deprecated-Design-System-Exists-in-XNG-Codebase
 */
const XNGCheckboxLabel = (props: Props) => {
  // const palette = usePalette();
  return (
    <FormControlLabel
      name={props.name}
      slotProps={{
        typography: {
          whiteSpace: props.whiteSpace ?? "nowrap",
        },
      }}
      control={
        <Checkbox
          /* sx={{ svg: { color: palette.contrasts[3] },}} */ aria-label={props["aria-label"]}
          value={props.checked}
          checked={props.checked}
          disabled={props.disabled}
          onChange={props.onChange}
          onBlur={props.onBlur}
          size={props.size}
        />
      }
      label={props.label}
    />
  );
};

export default XNGCheckboxLabel;
