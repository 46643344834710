import * as yup from "yup";

const medicatedCredentialsSchema = yup.object({
    profession: yup.string().trim().optional().notRequired(),
    nameOnLicense: yup.string().trim().optional().notRequired(),
    licenseNumber: yup.string().trim().optional().notRequired(),
    startDate: yup.date().optional().notRequired(),
    endDate: yup.date().optional().notRequired(),
    state: yup.string().trim().optional().notRequired(),
    county: yup.string().trim().optional().notRequired(),
  });
  
  
  const providerRateSchema = yup.object({
    hourlyRate: yup
      .number()
      .transform((value) => (value ? Number(value) : 0))
      .optional()
      .notRequired()
      .min(0, "hourly rate must be a positive number"),
    startDate: yup.date().optional().notRequired(),
    endDate: yup.date().optional().notRequired(),
  });
  
  const blockBillingSchema = yup.object({
    blockBillingForProvider: yup.boolean().optional().notRequired(),
    blockBillingStartDate: yup.date().optional().notRequired(),
    datePosted: yup.date().optional().notRequired(),
  });
  
  const medicaidInfoTabFormSchema = yup.object({
    medicaidCredentials: medicatedCredentialsSchema
      .optional()
      .notRequired()
      .test(
        "is-greater-than-start-date",
        "End date must be greater than start date",
        function (value) {
          const { startDate, endDate } = this.parent.medicaidCredentials;
          // console.log("value", this.parent)
          if (!startDate || !value) {
            // If either start date or end date is not provided, consider it as valid
            return true;
          }
          return new Date(endDate) > new Date(startDate);
        },
      )
      .test(
        "licensing-info-is-complete",
        "Please complete Licensing Information entry",
        function (credential) {
          if (!credential) {
            return true;
          }
          return (
            (!!credential.nameOnLicense &&
              !!credential.profession &&
              !!credential.licenseNumber &&
              !!credential.startDate &&
              !!credential.endDate) ||
            (!credential.nameOnLicense &&
              !credential.profession &&
              !credential.licenseNumber &&
              !credential.startDate &&
              !credential.endDate)
          );
        },
      ),
    medicaidCredentialsArray: yup.array().of(medicatedCredentialsSchema).optional().notRequired(),
    providerRate: providerRateSchema
      .optional()
      .notRequired()
      .test(
        "is-greater-than-start-date",
        "End date must be greater than start date",
        function (value) {
          const { startDate, endDate } = this.parent.providerRate;
          if (!startDate || !value) {
            // If either start date or end date is not provided, consider it as valid
            return true;
          }
          return new Date(endDate) > new Date(startDate);
        },
      )
      .test("rate-is-complete", "Please complete provider rates entry", function (rate) {
        if (!rate) {
          return true;
        }
  
        return (
          (!rate.hourlyRate && !rate.startDate && !rate.endDate) ||
          (!!rate.hourlyRate && !!rate.startDate && !!rate.endDate)
        );
      }),
  
    providerRateArray: yup.array().of(providerRateSchema).optional().notRequired(),
  
    npiNumber: yup.string().optional().notRequired().trim(),
    medicaidNumber: yup.string().optional().notRequired().trim(),
    isOnParticipationList: yup.boolean(),
    participationListStatus: yup
      .string()
      .test(
        "is-participation-list-status-valid",
        "Please select a valid participation list status",
        function (value) {
          const { isOnParticipationList } = this.parent;
          if (!isOnParticipationList) {
            return true;
          }
          return !!value;
        },
      ),
    blockBilling: blockBillingSchema
      .optional()
      .notRequired()
      .test("is-block-billing-valid", "Please select a valid block billing date", function (value) {
        const { blockBillingForProvider, blockBillingStartDate } = this.parent.block_billing;
  
        if (!blockBillingForProvider) {
          return true;
        }
        // console.log("blockBillingStartDate", blockBillingStartDate)
        return !!blockBillingStartDate;
      }),
    block_billing_history: yup.array().of(blockBillingSchema).optional().notRequired(),
  });
  
  type MedicaidInfoFormData = yup.InferType<typeof medicaidInfoTabFormSchema>;

  export { medicaidInfoTabFormSchema };
  export type { MedicaidInfoFormData };