import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useXNGSelector } from "../../../../context/store";
import { selectStateInUS } from "../../../../context/slices/stateInUsSlice";
import { selectLoggedInClientAssignment } from "../../../../context/slices/userProfileSlice";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormHelperText from "@mui/material/FormHelperText";
import { API_USERS } from "../../../../api/api";
import { CreateUnregisteredProviderRequest, ServiceProviderRef } from "../../../../profile-sdk";
import useApiQueryGetServiceProviderTypesByDate from "../../../../api/hooks/state_snapshots/use_api_query_get_service_provider_types_by_date";
import { MSBInputErrorWrapper, MSBSearchMultiselect } from "../../../../fortitude";
import msbMUIAutoCompleteFilterOptions from "../../../../utils/msb_mui_auto_complete_filter_options";
import MSBClose from "../../../../fortitude/components/button_close";
import { Stack } from "@mui/material";

const formSchema = yup.object().shape({
  firstName: yup.string().trim().required("First Name is a required field"),
  lastName: yup.string().trim().required("Last Name is a required field"),
  email: yup.string().trim().required("Email is a required field"),
  ServiceProviderTypes: yup
    .array()
    .of(
      yup.object({
        id: yup.string().nullable().optional(),
        name: yup.string().nullable().optional(),
        legacyId: yup.string().nullable().optional(),
        serviceArea: yup
          .object({
            id: yup.string().nullable().optional(),
            name: yup.string().nullable().optional(),
          })
          .nullable()
          .optional(),
      }),
    )
    .min(1, "Please select at least one Service Provider Type"),
});

type FormInputs = yup.InferType<typeof formSchema>;

type CreateNewProviderModalProps = {
  open: boolean;
  handleClose: () => void;
  onSubmit: (v: ServiceProviderRef) => void;
};
export function CreateNewProviderModal(props: Readonly<CreateNewProviderModalProps>) {
  const userClientAssignment = useXNGSelector(selectLoggedInClientAssignment);
  const state = useXNGSelector(selectStateInUS);

  const {
    data: serviceProviderTypesOptionsResponse,
    isError: serviceProviderTypesOptionsIsError,
    isPending: serviceProviderTypesOptionsIsPending,
    refetch: refetchMutlipleServiceProviderTypesOptions,
  } = useApiQueryGetServiceProviderTypesByDate({
    queryParams: {
      state,
    },
  });

  const {
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      ServiceProviderTypes: [],
    },
  });

  const addUnregisteredProvider = () => async (data: FormInputs) => {
    let body: CreateUnregisteredProviderRequest = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      client: { id: userClientAssignment.client?.id, name: userClientAssignment.client?.name },
      serviceProviderTypes: (data.ServiceProviderTypes as ServiceProviderRef[]) ?? [],
    };

    const addResponse = await API_USERS.v1UsersUnregisteredProviderPost(state, body);

    if (addResponse.serviceProvider) {
      props.onSubmit(addResponse.serviceProvider);
    } else {
      console.error("Created service provider was undefined!");
    }

    props.handleClose();
  };

  const inputProps = { required: true };

  return (
    <Dialog
      onKeyDown={(e) => {
        if (e.key === "Tab") {
          e.stopPropagation();
        }
      }}
      open={props.open}
    >
      <Stack sx={{ gap: "1rem", p: "1rem", width: "30rem" }}>
        <Stack>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <MSBClose onClick={() => props.handleClose()} />
          </Box>
          <Typography variant="h6">Create New User</Typography>
        </Stack>

        <TextField
          id="cs-first-name"
          label="First Name"
          size="small"
          variant="outlined"
          fullWidth
          {...inputProps}
          {...register("firstName")}
          // error={!!errors?.firstName}
          helperText={
            <FormHelperText sx={{ marginInline: 0 }} error={!!errors?.firstName}>
              {errors?.firstName?.message}
            </FormHelperText>
          }
        />
        <TextField
          id="cs-last-name"
          label="Last Name"
          size="small"
          fullWidth
          variant="outlined"
          {...inputProps}
          {...register("lastName")}
          // error={!!errors?.lastName}
          helperText={
            <FormHelperText sx={{ marginInline: 0 }} error={!!errors?.lastName}>
              {errors?.lastName?.message}
            </FormHelperText>
          }
        />
        <TextField
          id="cs-email"
          label="Email"
          size="small"
          fullWidth
          variant="outlined"
          {...inputProps}
          {...register("email")}
          // error={!!errors?.lastName}
          helperText={
            <FormHelperText sx={{ marginInline: 0 }} error={!!errors?.email}>
              {errors?.email?.message}
            </FormHelperText>
          }
        />
        <Controller
          name="ServiceProviderTypes"
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <MSBInputErrorWrapper
                isError={serviceProviderTypesOptionsIsError || !!error}
                displayRefreshButton={serviceProviderTypesOptionsIsError}
                errorText={
                  error?.message ??
                  "Failed to load Service Provider Types, please click refresh icon to retry"
                }
                refetch={refetchMutlipleServiceProviderTypesOptions}
              >
                <MSBSearchMultiselect
                  selectedOptions={field.value || []}
                  options={serviceProviderTypesOptionsResponse?.serviceProviderTypes || []}
                  getOptionLabel={(option) => `${option?.name}`}
                  onChange={(newServiceProviderTypes) => {
                    setValue("ServiceProviderTypes", newServiceProviderTypes);
                  }}
                  renderOptionVariant="checkbox"
                  variant="no overflow after 1"
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  label={(() => {
                    if (serviceProviderTypesOptionsIsPending) {
                      return "Loading Service Provider Types Options...";
                    }
                    if (serviceProviderTypesOptionsIsError) {
                      return "Failed to load Service Provider Types Options";
                    }
                    return "Service Provider Type(s)";
                  })()}
                  disabled={
                    serviceProviderTypesOptionsIsPending || serviceProviderTypesOptionsIsError
                  }
                  autocompleteProps={{
                    disableCloseOnSelect: true,
                    fullWidth: true,
                    filterOptions: msbMUIAutoCompleteFilterOptions(),
                  }}
                  sx={{
                    flex: 1,
                  }}
                />
              </MSBInputErrorWrapper>
            );
          }}
        />

        <Button
          onClick={() => {
            handleSubmit(addUnregisteredProvider())();
          }}
          variant="contained"
          size="small"
          fullWidth
        >
          Create
        </Button>
      </Stack>
    </Dialog>
  );
}
