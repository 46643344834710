import { Box, Stack } from "@mui/material";
import UserApprovalsCard from "../components/cards/user_approvals_card";
import UserApprovalsTabsEnum from "../types/user_approvals_tabs_enum";
import UserApprovalsPagination from "../components/user_approval_pagination";
import { DistrictAccessRequestRef } from "../../../../../profile-sdk";

import React from "react";
import { xlogsRoleOptions } from "../../../../../utils/xlogs_role_mapper";
import useUserManagementContext from "../../../hooks/context/use_user_management_context";
import { useXNGSelector } from "../../../../../context/store";
import useApiQueryGetServiceProviderTypesByDate from "../../../../../api/hooks/state_snapshots/use_api_query_get_service_provider_types_by_date";
import { selectStateInUS } from "../../../../../context/slices/stateInUsSlice";
import { XLogsRoleStrings } from "../../../../../context/types/xlogsrole";
import UsersTabState, { UsersTabAction } from "../types/users_tabs_state";
import useXNGPagination from "../../../../../hooks/use_xng_pagination";
import useUserApprovalsContext from "../hooks/use_user_approvals_context";
import UserApprovalsTable from "../components/table/user_approvals_table";
import useUserApprovalsColumnGridDef from "../hooks/use_user_approvals_column_grid_def";

type Props = {
  tabData: UsersTabState;
  tabDispatch: React.Dispatch<UsersTabAction>;
};

const UserApprovalsOrDenialTabView = (props: Props) => {
  const adminUser = useUserManagementContext().store.userManagementData.user;
  const selectedTabIndex = useUserApprovalsContext().selectedTabIndex;
  const stateInUs = useXNGSelector(selectStateInUS);
  const approvingOrDenyingUser =
    useUserApprovalsContext().patchUsersRequestingAccessToDistrictApiClientHandler.isPending;
  const serviceProviderApiOptionsManager = useApiQueryGetServiceProviderTypesByDate({
    queryParams: {
      state: stateInUs,
    },
  });

  const columns = useUserApprovalsColumnGridDef({
    serviceProviderApiOptionsManager,
    districtsComponentsProps: {
      value: (params) => props.tabData.selectedDistricts.get(params.row.user?.id ?? "") || [],
      onChange: (params) => (newDistricts: DistrictAccessRequestRef[]) => {
        props.tabDispatch({
          type: "select_districts",
          payload: {
            userId: params.row.user?.id ?? "",
            selectedData: newDistricts,
          },
        });
      },
    },
    serviceProvidersComponentsProps: {
      value: (params) =>
        props.tabData.selectedServiceProviderTypes.get(params.row.user?.id ?? "") || [],
      onChange: (params) => (newServiceProviders) => {
        props.tabDispatch({
          type: "select_service_provider_types",
          payload: {
            userId: params.row.user?.id ?? "",
            selectedData: newServiceProviders,
          },
        });
      },
    },
    xlogsRoleAssignedComponentsProps: {
      value: (params) =>
        props.tabData.selectedXlogsRole.get(params.row.user?.id ?? "") ??
        "Service Provider - Autonomous",
      onChange: (params) => (e) => {
        props.tabDispatch({
          type: "select_xlogs_role",
          payload: {
            userId: params.row.user?.id ?? "",
            selectedData: e.target.value as XLogsRoleStrings,
          },
        });
      },
    },
  });

  const { paginatedItems, currentPage, totalPages, showPagination, setCurrentPage } =
    useXNGPagination(props.tabData.filteredUsers);

  return (
    <Box
      pt={1}
      sx={{
        overflowY: "auto",
        opacity: approvingOrDenyingUser ? 0.5 : 1,
      }}
    >
      {props.tabData.viewType === "grid" && (
        <Box>
          <Stack
            direction={"row"}
            gap={1}
            flexWrap={"wrap"}
            sx={{
              justifyContent: {
                xs: "center",
                sm: "flex-start",
              },
            }}
          >
            {paginatedItems?.map((userId) => (
              <UserApprovalsCard
                key={userId}
                selectedDistricts={props.tabData.selectedDistricts.get(userId ?? "") || []}
                setSelectedDistricts={(districts) =>
                  props.tabDispatch({
                    type: "select_districts",
                    payload: {
                      userId: userId ?? "",
                      selectedData: districts,
                    },
                  })
                }
                districtOptions={
                  props.tabData.usersOptions.get(userId)?.districtAccessRequests || []
                }
                selectedServiceProviderTypes={
                  props.tabData.selectedServiceProviderTypes.get(userId ?? "") || []
                }
                setSelectedServiceProviderTypes={(serviceProviderTypes) =>
                  props.tabDispatch({
                    type: "select_service_provider_types",
                    payload: {
                      userId: userId,
                      selectedData: serviceProviderTypes,
                    },
                  })
                }
                selectedXlogsRole={
                  props.tabData.selectedXlogsRole.get(userId) ?? "Service Provider - Autonomous"
                }
                setSelectedXlogsRole={(role) => {
                  props.tabDispatch({
                    type: "select_xlogs_role",
                    payload: {
                      userId: userId,
                      selectedData: role,
                    },
                  });
                }}
                xlogsRoleOptions={xlogsRoleOptions}
                serviceProviderApiOptionsManager={serviceProviderApiOptionsManager}
                userCard={props.tabData.usersOptions.get(userId)!}
                user={adminUser}
                isSelected={() => props.tabData.selectedUsers.includes(userId)}
                onAddCardData={() =>
                  props.tabDispatch({
                    type: "select_single_user",
                    payload: userId,
                  })
                }
                onRemoveCardData={() =>
                  props.tabDispatch({
                    type: "remove_user",
                    payload: userId,
                  })
                }
              />
            ))}
          </Stack>
          <UserApprovalsPagination
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            showPagination={showPagination}
          />
        </Box>
      )}
      {props.tabData?.viewType === "list" && (
        <UserApprovalsTable
          rows={
            props.tabData.filteredUsers?.map((userId) => props.tabData.usersOptions.get(userId)!) ||
            []
          }
          columns={columns}
          emptyRowsText={
            selectedTabIndex === UserApprovalsTabsEnum.user_approvals
              ? "No Users to Approve"
              : "No Users Deny"
          }
          getRowId={(row) => row.user?.id ?? crypto.randomUUID()}
          rowSelectionModel={props.tabData.selectedUsers}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            props.tabDispatch({
              type: "select_users",
              payload: newRowSelectionModel as string[],
            });
          }}
        />
      )}
    </Box>
  );
};

export default UserApprovalsOrDenialTabView;
