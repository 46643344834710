function truncateUntilFirstNonAlphanumericCharacter(str: string): string {
  const regex = /\W/;
  const match = regex.exec(str);
  if (match) {
    return str.substring(0, match.index) + "...";
  }
  return str;
}

export default truncateUntilFirstNonAlphanumericCharacter;
