import { API_SESSIONS } from "../../../../api/api";
import useApiQueryData from "../../../../api/hooks/use_api_query_data";
import XNGApiQueryParamObject from "../../../../types/xng_api_query_param_object";

type Data = Awaited<ReturnType<typeof API_SESSIONS.v1SessionsSlimGet>>;
type QueryParams = {
  serviceProviderIds: string;
  statuses: string;
  clientId: string;
  state: string;
  fromDateTime?: Date;
  toDateTime?: Date;
  timezone?: string;
};

const useApiQueryUnpostedSessionsData = (
  paramObject: XNGApiQueryParamObject<QueryParams, Data>,
) => {
  const { serviceProviderIds, statuses, clientId, state } = paramObject.queryParams;
  return useApiQueryData({
    queryFn: async () =>
      await API_SESSIONS.v1SessionsSlimGet(
        serviceProviderIds,
        statuses,
        clientId,
        state,
        paramObject.queryParams?.fromDateTime,
        paramObject.queryParams?.toDateTime,
        paramObject.queryParams?.timezone,
      ),
    queryKey: [
      "v1SessionsSlimGet",
      serviceProviderIds,
      statuses,
      clientId,
      state,
      paramObject.queryParams?.fromDateTime,
      paramObject.queryParams?.toDateTime,
      paramObject.queryParams?.timezone,
    ],
    ...(paramObject.options ?? {}),
  });
};

export default useApiQueryUnpostedSessionsData;
