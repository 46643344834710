/**
 * Converts a number to a formatted currency string.
 *
 * @param {number} number - The number to be converted.
 * @return {string} The formatted currency string.
 */
export const convertToCommaSeparatedValue = (number:number) => {
  // Check if the number is a whole number
  if (Number.isInteger(number)) {
    // Format without decimal places
    return new Intl.NumberFormat('en-US').format(number);
  } else {
    // Format with two decimal places
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  }
};