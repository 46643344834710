import { SxProps, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import MSBIconRenderer from "../iconography/icon_renderer";
import MSBICONS from "../iconography/icons";

interface MSBCloseProps {
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  sx?: SxProps;
}

function MSBClose(props: Readonly<MSBCloseProps>) {
  const sizeRem = 1;

  return (
    <Box
      onClick={(e) => props.onClick(e)}
      sx={{
        ":hover": { cursor: "pointer", svg: { stroke: "red!important" } },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: sizeRem + "rem",
        width: sizeRem + "rem",
        minHeight: sizeRem + "rem",
        minWidth: sizeRem + "rem",
        maxHeight: sizeRem + "rem",
        maxWidth: sizeRem + "rem",
        ...props.sx,
      }}
    >
      <IconButton>
        <MSBIconRenderer i={<MSBICONS.Close />} size={sizeRem} />
      </IconButton>
    </Box>
  );
}

export default MSBClose;
