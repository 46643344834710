import React, { createContext, useEffect, useMemo, useReducer } from "react";
import UnpostedSessionsContextState from "../types/unposted_sessions_context_state";
import { useXNGSelector } from "../../../context/store";
import { selectServiceProviderProfile } from "../../../context/slices/loggedInClientSlice";
import {
  selectLoggedInClientAssignment,
  selectUser,
} from "../../../context/slices/userProfileSlice";
import { useLocation, useNavigate } from "react-router";
import { ROUTES_XLOGS } from "../../../constants/URLs";
import { selectStateInUS } from "../../../context/slices/stateInUsSlice";
import unpostedSessionsViewStateReducer, {
  unpostedSessionsViewStateInitializer,
} from "./reducers/unposted_sessions_sessions_view_state_reducer";
import useUnpostedSessionsMatchPath from "../hooks/use_unposted_sessions_match_path";
import useUnpostedSessionsApiClientManagersAndProviderCaseload from "../hooks/use_unposted_sessions_api_client_managers_and_provder_caseload";

type Props = {
  children: React.ReactNode;
};

const UnpostedSessionsProvider = (props: Props) => {
  const clientId = useXNGSelector(selectLoggedInClientAssignment)?.client?.id;
  const stateInUs = useXNGSelector(selectStateInUS);
  const reduxUser = useXNGSelector(selectUser);
  const location = useLocation();
  const unpostedSessionsMatchPath = useUnpostedSessionsMatchPath();

  const navigate = useNavigate();

  const loggedinServiceProvider = useXNGSelector(selectServiceProviderProfile);

  const {
    unpostedSessionsCountApiQueryClientManager,
    myUnpostedSessionsApiQueryClientManager,
    decUnpostedSessionsApiQueryClientManager,
    assistantsUnpostedSessionsApiQueryClientManager,
    appointingServiceProviders,
    supervisedServiceProviders,
  } = useUnpostedSessionsApiClientManagersAndProviderCaseload({
    clientId,
    stateInUs,
    unpostedSessionsMatchPath,
    reduxUser,
    loggedinServiceProvider,
    onInitMySessionsState() {
      myUnpostedSessionsViewDispatch({
        type: "initialize_state",
        payload: {
          serviceProviderOptions: loggedinServiceProvider ? [loggedinServiceProvider] : [],
          unpostedSessionsApiQueryClientManager: myUnpostedSessionsApiQueryClientManager,
        },
      });
    },
    onUpdateMySessionsState() {
      myUnpostedSessionsViewDispatch({
        type: "update_state",
        payload: {
          serviceProviderOptions: loggedinServiceProvider ? [loggedinServiceProvider] : [],
          unpostedSessionsApiQueryClientManager: myUnpostedSessionsApiQueryClientManager,
        },
      });
    },
    onInitDecSessionsState() {
      decUnpostedSessionsViewDispatch({
        type: "initialize_state",
        payload: {
          serviceProviderOptions:(loggedinServiceProvider ? [loggedinServiceProvider] : []).concat(appointingServiceProviders ?? []),
          unpostedSessionsApiQueryClientManager: decUnpostedSessionsApiQueryClientManager,
          defaultSelected: appointingServiceProviders,
        },
      });
    },
    onUpdateDecSessionsState() {
      if(!decUnpostedSessionsViewState.hasInitializedState) return;
      decUnpostedSessionsViewDispatch({
        type: "update_state",
        payload: {
          serviceProviderOptions: (loggedinServiceProvider ? [loggedinServiceProvider] : []).concat(appointingServiceProviders ?? []),
          unpostedSessionsApiQueryClientManager: decUnpostedSessionsApiQueryClientManager,
        },
      });
    },
    onInitAssistantsSessionsState() {
      assistantsUnpostedSessionsViewDispatch({
        type: "initialize_state",
        payload: {
          serviceProviderOptions: supervisedServiceProviders ?? [],
          unpostedSessionsApiQueryClientManager: assistantsUnpostedSessionsApiQueryClientManager,
        },
      });
    },
    onUpdateAssistantsSessionsState() {
      assistantsUnpostedSessionsViewDispatch({
        type: "update_state",
        payload: {
          serviceProviderOptions: supervisedServiceProviders ?? [],
          unpostedSessionsApiQueryClientManager: assistantsUnpostedSessionsApiQueryClientManager,
        },
      });
    },
  });

  const [myUnpostedSessionsViewState, myUnpostedSessionsViewDispatch] = useReducer(
    unpostedSessionsViewStateReducer,
    {
      serviceProviderOptions: loggedinServiceProvider ? [loggedinServiceProvider] : [],
      unpostedSessionsApiQueryClientManager: myUnpostedSessionsApiQueryClientManager,
    },
    unpostedSessionsViewStateInitializer,
  );

  const [decUnpostedSessionsViewState, decUnpostedSessionsViewDispatch] = useReducer(
    unpostedSessionsViewStateReducer,
    {
      serviceProviderOptions: appointingServiceProviders ?? [],
      unpostedSessionsApiQueryClientManager: decUnpostedSessionsApiQueryClientManager,
    },
    unpostedSessionsViewStateInitializer,
  );

  const [assistantsUnpostedSessionsViewState, assistantsUnpostedSessionsViewDispatch] = useReducer(
    unpostedSessionsViewStateReducer,
    {
      serviceProviderOptions: supervisedServiceProviders ?? [],
      unpostedSessionsApiQueryClientManager: assistantsUnpostedSessionsApiQueryClientManager,
    },
    unpostedSessionsViewStateInitializer,
  );

  useEffect(() => {
    if (unpostedSessionsMatchPath.customId === "index") {
      navigate(ROUTES_XLOGS.unposted_sessions.mySessions);
    }
  }, [location, unpostedSessionsMatchPath, navigate]);

  const contextValue: UnpostedSessionsContextState = useMemo(() => {
    return {
      unpostedSessionsCountApiQueryClientManager,
      myUnpostedSessionsViewState,
      myUnpostedSessionsViewDispatch,
      myUnpostedSessionsApiQueryClientManager,
      decUnpostedSessionsViewState,
      decUnpostedSessionsViewDispatch,
      decUnpostedSessionsApiQueryClientManager,
      assistantsUnpostedSessionsViewState,
      assistantsUnpostedSessionsViewDispatch,
      assistantsUnpostedSessionsApiQueryClientManager,
    };
  }, [
    assistantsUnpostedSessionsApiQueryClientManager,
    assistantsUnpostedSessionsViewState,
    decUnpostedSessionsApiQueryClientManager,
    decUnpostedSessionsViewState,
    myUnpostedSessionsApiQueryClientManager,
    myUnpostedSessionsViewState,
    unpostedSessionsCountApiQueryClientManager,
  ]);

  return (
    <UnpostedSessionsContext.Provider value={contextValue}>
      {props.children}
    </UnpostedSessionsContext.Provider>
  );
};

export const UnpostedSessionsContext = createContext<UnpostedSessionsContextState>(
  {} as UnpostedSessionsContextState,
);

export default UnpostedSessionsProvider;
