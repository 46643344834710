import { useDataGridBase } from "../../../../../hooks/use_datagrid_base";
import { StudentManagerCaseloadResultsProps } from "../types/student_manager_caseload_results_props";
import { ProviderCaseloadHeader } from "./provider_caseload_header";
import { DataGrid } from "@mui/x-data-grid";
import { StudentManagerSectionWrapper } from "./student_manager_section_wrapper";
import { useNavigate } from "react-router";
import {
  PROVIDER_STUDENT_CASELOAD_COLUMNS as columns,
  ProviderCaseloadListRow,
} from "../constants/columns";
import { useGenerateStudentManagerRows } from "../hooks/use_generate_student_manager_rows.ts";
import { ROUTES_XLOGS } from "../../../../../constants/URLs";
import DataGridLoadingLayout from "../../../../../design/high-level/data_grid_loading_layout";

function ProviderCaseloadListResults(props: Readonly<StudentManagerCaseloadResultsProps>) {
  const { filteredStudents, failedStudentImports, totalStudentsInCaseload } = props;
  const navigate = useNavigate();

  const rows = useGenerateStudentManagerRows(filteredStudents, failedStudentImports);
  const table = useDataGridBase<ProviderCaseloadListRow>({ rows, columns });

  return (
    <StudentManagerSectionWrapper>
      <ProviderCaseloadHeader
        totalStudentsInCaseload={totalStudentsInCaseload}
        totalFilteredStudents={filteredStudents.length}
      />

      <DataGridLoadingLayout isLoading={props.isLoading} sizeAnchor="19.5rem">
        <DataGrid
          disableVirtualization // Required to ensure the table is rendered in the JDSOM when testing.
          onRowClick={(v) => {
            if ((v.row as ProviderCaseloadListRow).isFailedImport) return;
            navigate(
              ROUTES_XLOGS._students.manager +
                "/" +
                (v.row as ProviderCaseloadListRow).xlogsStudentID,
            );
          }}
          rows={table.rows}
          columns={table.columns}
          sx={{
            "& .MuiDataGrid-row:hover": {
              cursor: "pointer",
            },
          }}
        />
      </DataGridLoadingLayout>
    </StudentManagerSectionWrapper>
  );
}

export default ProviderCaseloadListResults;
