import { placeholderForFutureLogErrorText } from "../../temp/errorText";
import { XNGSlide } from "../types/slide";

export interface IXNGSlideshow {
  view: number;
  slides: XNGSlide[];
}

/**
 * ## DEPRECATED
 *
 * Do not reuse or modify, replace references with MUI / Fortitude components in new development.
 *
 * See: https://msb-tlm.atlassian.net/wiki/spaces/XL/pages/508035073/MSB+Design+System+Fortitude#Note-for-Developers%3A-Deprecated-Design-System-Exists-in-XNG-Codebase
 */
export function XNGSlideshow(props: IXNGSlideshow) {
  const slide = props.slides.find((s) => s.id === props.view);
  if (slide === undefined) throw new Error(placeholderForFutureLogErrorText);

  return slide.content;
}
