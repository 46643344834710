import { useCallback } from "react";
import { CaseloadStudentDisplay } from "../../../profile-sdk";

function useSortStudentsByName() {
  const sortStudentsByName = useCallback((a: CaseloadStudentDisplay, b: CaseloadStudentDisplay) => {
    const nameA =
      (a.lastName?.toLocaleLowerCase() ?? "") + (a.firstName?.toLocaleLowerCase() ?? "");
    const nameB =
      (b.lastName?.toLocaleLowerCase() ?? "") + (b.firstName?.toLocaleLowerCase() ?? "");

    if (nameA < nameB) {
      return -1;
    }

    if (nameA > nameB) {
      return 1;
    }

    return 0;
  }, []);

  return sortStudentsByName;
}

export default useSortStudentsByName;
