import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function PageNotFound() {
  const navigate = useNavigate();

  useEffect(() => {
    const path = window.location.pathname.split("/")[1];
    if (path === "") {
      // Use navigate for navigation
      navigate("/xlogs/calendar");
    }
  }, []);

  return <>Error 404: Page not found!</>;
}

export default PageNotFound;
