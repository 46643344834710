import { API_USERS } from "../../api";
import { CreateUnregisteredProviderRequest } from "../../../profile-sdk";
import useApiMutateData from "../use_api_mutate_data";
import XNGApiMutateParamObject from "../../../types/xng_api_mutate_param_object";

type Data = Awaited<ReturnType<typeof API_USERS.v1UsersUnregisteredProviderPost>>; // generic parameter T is used to define data the type of the useApiMutateData hook
type Body = CreateUnregisteredProviderRequest;  // generic parameter B is used to define body the type of the useApiMutateData hook
type QueryParams = {
  state: string;
}

const useApiMutatePostUnregisteredServiceProvider = (paramObject: XNGApiMutateParamObject<QueryParams, Data, Body>) => {

  const { state } = paramObject.queryParams;

  return useApiMutateData({
    mutationFn: async (body: Body) =>
      await API_USERS.v1UsersUnregisteredProviderPost(state, body),
    mutationKey: ["v1UsersUnregisteredProviderPost", state],
    ...(paramObject.options ?? {})
  });
};

export default useApiMutatePostUnregisteredServiceProvider;
