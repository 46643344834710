import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Tooltip,
  Typography,
} from "@mui/material";
import { MSBICONS } from "../../../../fortitude";
import UnpostedSessionsViewState from "../../types/unposted_sessions_view_state";
import { SessionSlimCard } from "../../../../session-sdk";
import useCardContentResponsiveHeight from "../../hooks/use_card_content_responsive_height";

type Props = {
  serviceProvider: UnpostedSessionsViewState["selectedServiceProviders"][number];
  sessionsData: SessionSlimCard[] | undefined;
  totalUnpostedSessions: number;
  mySessions?: boolean;
  onSessionBtnClick?: (session: SessionSlimCard) => void;
  disable?: boolean;
};

type HeaderProps = {
  name: string;
  filteredUnpostedSessions: number;
  totalUnpostedSessions: number;
};

type BodyProps = {
  sessionsData: SessionSlimCard[] | undefined;
  onSessionBtnClick?: (session: SessionSlimCard) => void;
  disableBtns?: boolean;
};

const UnpostedSessionsCard = (props: Props) => {
  return (
    <Box
      sx={{
        maxWidth: "250px",
        minWidth: "240px",
        opacity: props.disable ? 0.65 : 1,
      }}
    >
      <CardHeader
        name={
          props.mySessions
            ? "My Sessions"
            : `${props.serviceProvider.firstName} ${props.serviceProvider.lastName}`
        }
        filteredUnpostedSessions={props.sessionsData?.length ?? 0}
        totalUnpostedSessions={props.totalUnpostedSessions ?? 0}
      />
      <CardBody
        sessionsData={props.sessionsData}
        onSessionBtnClick={props.onSessionBtnClick}
        disableBtns={props.disable}
      />
    </Box>
  );
};

function CardHeader(props: Readonly<HeaderProps>) {
  return (
    <Box
      sx={{
        borderRadius: "5px",
        border: "1px solid",
        borderColor: "contrasts.2",
        display: "flex",
        flexDirection: "column",
        padding: "15px",
        gap: "5px",
        mb: "3px",
      }}
    >
      <Typography variant="body1">{props.name}</Typography>
      <Typography variant="body1" fontSize={"48px"} textAlign={"center"}>
        {props.filteredUnpostedSessions}
      </Typography>
      <Typography variant="caption" textAlign={"center"}>
        filtered sessions
      </Typography>
      <Typography variant="body1" fontWeight={700} textAlign={"center"}>
        Total Unposted: {props.totalUnpostedSessions}
      </Typography>
    </Box>
  );
}

function CardBody(props: Readonly<BodyProps>) {
  const { DownChevron } = MSBICONS;
  const height = useCardContentResponsiveHeight();
  return (
    <Box>
      <Accordion disableGutters elevation={0} square defaultExpanded>
        <AccordionSummary
          expandIcon={<DownChevron />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            px: "0px",
            minHeight: "20px",
            borderBottom: "2px solid",
            borderColor: "contrasts.2",
            "&:hover": {
              backgroundColor: "contrasts.1",
            },
          }}
        ></AccordionSummary>
        <AccordionDetails
          sx={{
            px: "0px",
            maxHeight: `calc(100vh - ${height})`,
            overflowY: "auto",
          }}
        >
          {(props.sessionsData ?? []).map((session, i) => {
            const displayText = `${session.title} ${new Date(
              session.startTime || "",
            )?.toLocaleString("en-US", {
              timeStyle: "short",
              dateStyle: "short",
            })}`;
            return (
              <Box
                component={"button"}
                disabled={props.disableBtns}
                key={session.id ?? crypto.randomUUID()}
                sx={{
                  border: "1px solid",
                  borderColor: "contrasts.2",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  mb: "5px",
                  py: "3px",
                  px: "5px",
                  backgroundColor: "transparent",
                  width: "100%",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "contrasts.2",
                  },
                  "&:active": {
                    backgroundColor: "contrasts.3",
                  },
                }}
                onClick={() => props.onSessionBtnClick?.(session)}
              >
                <Tooltip title={displayText} placement="top">
                  <Typography
                    variant="caption"
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "100%",
                      display: "inline-block",
                    }}
                  >
                    {displayText}
                  </Typography>
                </Tooltip>
              </Box>
            );
          })}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default UnpostedSessionsCard;
