import { useMediaQuery } from "@mui/material";

const useCardContentResponsiveHeight = () => {
  const breakpoint1 = useMediaQuery("(width <= 880px)");
  const breakpoint2 = useMediaQuery("(width <= 736px)");
  const breakpoint3 = useMediaQuery("(width <= 736px)");
  const breakpoint4 = useMediaQuery("(width <= 600px)");
  const breakpoint5 = useMediaQuery("(width <= 400px)");

  if (breakpoint5) {
    console.log("breakpoint2");
    return "690px";
  }

  if (breakpoint4) {
    console.log("breakpoint2");
    return "605px";
  }

  if (breakpoint3) {
    console.log("breakpoint2");
    return "535px";
  }

  if (breakpoint2) {
    console.log("breakpoint2");
    return "530px";
  }

  if (breakpoint1) {
    return "510px";
  }

  return "496px";
};

export default useCardContentResponsiveHeight;
