import { ServiceAreaReimbursementReport } from "@xng/reporting";



export enum ServiceCategory {
  AppliedBehaviorAnalysis = "Applied Behavior Analysis",
  CaseManagement = "Case Management",
  MedicalEvaluation = "Medical Evaluation",
  MentalHealthServices = "Mental Health Services",
  NursingServices = "Nursing Services",
  OccupationalTherapyServices = "Occupational Therapy Services",
  PhysicalTherapy = "Physical Therapy",
  PsychologicalServices = "Psychological Services",
  RehabilitativeAssistance = "Rehabilitative Assistance",
  SpeechLanguageHearingServices = "Speech Language & Hearing Services",
  Transportation = "Transportation",
  UncategorizedMedicaidPayments = "Uncategorized Medicaid Payments",
  VisionServices = "Vision Services",
  AllCategories = "All Categories",
}

// Define enum for values
export enum  ServiceUiLabel {
  Audiology = "Audiology",
  Counseling = "Counseling",
  Nursing = "Nursing",
  OccupationalTherapy = "Occupational Therapy",
  PersonalCare = "Personal Care",
  PersonalCareOnTheBus = "Personal Care On The Bus",
  PhysicalTherapy = "Physical Therapy",
  PhysicianServices = "Physician Services",
  Psychological = "Psychological",
  SpeechLanguage = "Speech & Language",
  Transportation = "Transportation",
  UncategorizedMedicaidPayments = "Uncategorized Medical Payments",
  VisionServices = "Vision Services",
  AllCategories = "All Categories",
}



// Define the mapping using Record<ServiceCategory,  ServiceUiLabel>
export const SERVICE_CATEGORTY_LOOKUP: Record<ServiceCategory,  ServiceUiLabel> = {
  [ServiceCategory.AppliedBehaviorAnalysis]:  ServiceUiLabel.Audiology,
  [ServiceCategory.CaseManagement]:  ServiceUiLabel.Counseling,
  [ServiceCategory.MedicalEvaluation]:  ServiceUiLabel.Nursing,
  [ServiceCategory.MentalHealthServices]:  ServiceUiLabel.OccupationalTherapy,
  [ServiceCategory.NursingServices]:  ServiceUiLabel.PersonalCare,
  [ServiceCategory.OccupationalTherapyServices]:  ServiceUiLabel.PersonalCareOnTheBus,
  [ServiceCategory.PhysicalTherapy]:  ServiceUiLabel.PhysicalTherapy,
  [ServiceCategory.PsychologicalServices]:  ServiceUiLabel.PhysicianServices,
  [ServiceCategory.RehabilitativeAssistance]:  ServiceUiLabel.Psychological,
  [ServiceCategory.SpeechLanguageHearingServices]:  ServiceUiLabel.SpeechLanguage,
  [ServiceCategory.Transportation]:  ServiceUiLabel.Transportation,
  [ServiceCategory.UncategorizedMedicaidPayments]:  ServiceUiLabel.UncategorizedMedicaidPayments,
  [ServiceCategory.VisionServices]:  ServiceUiLabel.VisionServices,
  [ServiceCategory.AllCategories]:  ServiceUiLabel.AllCategories,
};

export const serviceAreaReimbursementReports: ServiceAreaReimbursementReport[] = [
  {
     accountDistrictID : 27877,
     serviceCategory : ServiceCategory.AppliedBehaviorAnalysis,
     goal : 0,
     billed : 0,
     paid : 0,
     progress : 0,
     totalPaidLastYear : 0
  },
  {
     accountDistrictID : 27877,
     serviceCategory :  ServiceCategory.CaseManagement,
     goal : 0,
     billed : 0,
     paid : 0,
     progress : 0,
     totalPaidLastYear : 0
  },
  {
     accountDistrictID : 27877,
     serviceCategory :  ServiceCategory.MedicalEvaluation,  
     goal : 1170,
     billed : 0,
     paid : 0,
     progress : 0,
     totalPaidLastYear : 0
  },
  {
     accountDistrictID : 27877,
     serviceCategory :  ServiceCategory.MentalHealthServices,
     goal : 2340,
     billed : 599.4,
     paid : 164.22,
     progress : 0.0701,
     totalPaidLastYear : 0
  },
  {
     accountDistrictID : 27877,
     serviceCategory :  ServiceCategory.NursingServices,
     goal : 4680,
     billed : 0,
     paid : 0,
     progress : 0,
     totalPaidLastYear : 718.08
  },
  {
     accountDistrictID : 27877,
     serviceCategory :  ServiceCategory.OccupationalTherapyServices,
     goal : 5850,
     billed : 3883.51,
     paid : 933.96,
     progress : 0.1596,
     totalPaidLastYear : 4118.34
  },
  {
     accountDistrictID : 27877,
     serviceCategory :  ServiceCategory.PhysicalTherapy,
     goal : 3510,
     billed : 186.66,
     paid : 16.8,
     progress : 0.0047,
     totalPaidLastYear : 945.22
  },
  {
     accountDistrictID : 27877,
     serviceCategory :  ServiceCategory.PsychologicalServices,
     goal : 1170,
     billed : 0,
     paid : 0,
     progress : 0,
     totalPaidLastYear : 0
  },
  {
     accountDistrictID : 27877,
     serviceCategory :  ServiceCategory.RehabilitativeAssistance,
     goal : 73710,
     billed : 1136.7,
     paid : 149.62,
     progress : 0.002,
     totalPaidLastYear : 75901.11
  },
  {
     accountDistrictID : 27877,
     serviceCategory :  ServiceCategory.SpeechLanguageHearingServices,
     goal : 14040,
     billed : 4103.48,
     paid : 1425.74,
     progress : 0.1015,
     totalPaidLastYear : 8215.49
  },
  {
     accountDistrictID : 27877,
     serviceCategory :  ServiceCategory.Transportation,
     goal : 8190,
     billed : 0,
     paid : 0,
     progress : 0,
     totalPaidLastYear : 11478.85
  },
  {
     accountDistrictID : 27877,
     serviceCategory :  ServiceCategory.UncategorizedMedicaidPayments,
     goal : 0,
     billed : 0,
     paid : 633.17,
     progress : 0,
     totalPaidLastYear : 11580.45
  },
  {
     accountDistrictID : 27877,
     serviceCategory :  ServiceCategory.VisionServices,
     goal : 1170,
     billed : 0,
     paid : 0,
     progress : 0,
     totalPaidLastYear : 0
  },
  {
     accountDistrictID : 27877,
     serviceCategory :  ServiceCategory.AllCategories,
     goal : 115830,
     billed : 9909.75,
     paid : 3323.51,
     progress : 0.0286,
     totalPaidLastYear : 112957.54
  }
]


const goals = serviceAreaReimbursementReports.map(item => item.goal)
const billed = serviceAreaReimbursementReports.map(item => item.billed)
const paid = serviceAreaReimbursementReports.map(item => item.paid)

export const mapServiceCategoryToLabel = (serviceCategory: ServiceCategory): string => {
    return SERVICE_CATEGORTY_LOOKUP[serviceCategory] || serviceCategory;
};

// console.log("goals", goals, "billed", billed, "paid", paid)
export const dummyData = {
    labels: serviceAreaReimbursementReports.map(item => SERVICE_CATEGORTY_LOOKUP[item.serviceCategory as ServiceCategory]),
    datasets: [
      {
        label: "Goal",
        backgroundColor: "#3cb48c",
        data: goals,
        barThickness: 20,  
        categorySpacing: 50, 
      },
      {
        label: "Billed",
        backgroundColor: "blue", 
        data: billed, 
        barThickness: 20, 
        // Add space between bars (adjust as needed)
        categorySpacing: 50, 
      },
      {
        label: "Paid",
        backgroundColor: "#2C5545",
        data: paid,
        barThickness: 20, 
        // Add space between bars (adjust as needed)
        categorySpacing: 50, 
      }
    ]
};

export const isValidServiceCategory = (serviceCategory: string): boolean => { 
  return  serviceAreaReimbursementReports.find(item => item.serviceCategory === serviceCategory) ? true : false
}

export const generateDoughnutChartData = (category: string) => {
    const categoryData = serviceAreaReimbursementReports.find(item => item.serviceCategory === category);
   
    if (categoryData && categoryData.progress !==0 && categoryData.goal !==0) {
        return { 
            datasets: [
                {
                    data: [categoryData.progress, categoryData.goal],
                    backgroundColor:  [
                        "#3cb48c", 
                    ],
                    borderColor: [
                        "#3cb48c", 
                    ],
                    borderWidth: 1
                }
            ]
        };
    } 
    return { 
      datasets: [
          {
              data: [0, 10],
              backgroundColor:  [
                  "#3cb48c",
                  "#E8E8E8"
              ],
              borderColor: [
                  "#3cb48c",
                  "#E8E8E8"
              ],
              borderWidth: 1
          }
      ]
  };

};