import usePalette from "../../hooks/usePalette";
import Box from "../components-dev/BoxExtended";

/**
 * ## DEPRECATED
 *
 * Do not reuse or modify, replace references with MUI / Fortitude components in new development.
 *
 * See: https://msb-tlm.atlassian.net/wiki/spaces/XL/pages/508035073/MSB+Design+System+Fortitude#Note-for-Developers%3A-Deprecated-Design-System-Exists-in-XNG-Codebase
 */
function XNGSpinner(props: { fullPage?: boolean }) {
  const palette = usePalette();

  return (
    <Box
      sx={{
        ".lds-roller div:after": { bgcolor: palette.primary[2] + "!important" },
        ...(props.fullPage
          ? {
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }
          : {}),
      }}
    >
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Box>
  );
}

export default XNGSpinner;
