import usePalette from "../../hooks/usePalette";
import Box from "../components-dev/BoxExtended";
import { getSizing } from "../sizing";
import { Typography } from "@mui/material";

/**
 * ## DEPRECATED
 *
 * Do not reuse or modify, replace references with MUI / Fortitude components in new development.
 *
 * See: https://msb-tlm.atlassian.net/wiki/spaces/XL/pages/508035073/MSB+Design+System+Fortitude#Note-for-Developers%3A-Deprecated-Design-System-Exists-in-XNG-Codebase
 */
export function XNGErrorFeedback(props: { error: string | undefined }) {
  const palette = usePalette();

  return (
    <Box
      className="noselect"
      sx={{
        paddingBlock: props.error ? "3px" : 0,
        transition: "height .2s ease, margin .2s ease",
        display: "flex",
        alignItems: "center",
        // overflow: "hidden",
        whiteSpace: "normal",
        marginTop: props.error ? getSizing(0.5) : 0,
      }}
    >
      <Typography variant="body2" color={palette.danger[2]}>
        {props.error}
      </Typography>
    </Box>
  );
}
