export interface FinancialDashboardAction {
    type: ActionType;
    payload?: any; // Use specific type if possible
}


export enum ActionType {
    LOADING = "set_loading",
    FAILED = "set_failed",
    IDLE = "set_idle",
    SET_FINANCIAL_DASHBOARD_REPORT = "set_financial_dashboard_report"
}