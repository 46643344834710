import { useMemo } from "react";
import UserManagementTabs from "../../../components/user_management_tabs";
import UserApprovalsOrDenialTabView from "./user_approvals_or_denial_tab_view";
import UserHistoryTabView from "./user_history_tab_view";
import UserApprovalsTabsEnum from "../types/user_approvals_tabs_enum";
import UserApprovalsToolBar from "../components/toolbar/user_approvals_toolbar";
import useUserApprovalsContext from "../hooks/use_user_approvals_context";
import UserApprovalSkeletonView from "../components/loading/user_approval_skeleton_view";

const UserApprovalView = () => {
  const selectedTabIndex = useUserApprovalsContext().selectedTabIndex;
  const unapprovedTabData = useUserApprovalsContext().userApprovalsUnaprovedState;
  const unapprovedTabDispatch = useUserApprovalsContext().userApprovalsUnapprovedDispatch;
  const deniedTabData = useUserApprovalsContext().userApprovalsDenialState;
  const deniedTabDispatch = useUserApprovalsContext().userApprovalsDenialDispatch;
  const userHistoryTabData = useUserApprovalsContext().userApprovalsHistoryState;
  const userHistoryTabDispatch = useUserApprovalsContext().userApprovalsHistoryDispatch;
  const apiDataLoaded =
    useUserApprovalsContext().queryUserManagementCardsApiClientHandler?.isSuccess ?? false;

  const setSelectedTabIndex = useUserApprovalsContext().setSelectedTabIndex;

  const currentTabStore = useMemo(() => {
    switch (selectedTabIndex) {
      case UserApprovalsTabsEnum.approval_denial_history:
        return {
          tabData: userHistoryTabData,
          tabDispatch: userHistoryTabDispatch,
        };
      case UserApprovalsTabsEnum.user_denials:
        return {
          tabData: deniedTabData,
          tabDispatch: deniedTabDispatch,
        };
      case UserApprovalsTabsEnum.user_approvals:
      default:
        return {
          tabData: unapprovedTabData,
          tabDispatch: unapprovedTabDispatch,
        };
    }
  }, [
    deniedTabData,
    deniedTabDispatch,
    selectedTabIndex,
    unapprovedTabData,
    unapprovedTabDispatch,
    userHistoryTabData,
    userHistoryTabDispatch,
  ]);

  return (
    <UserManagementTabs
      tabs={[
        {
          label: "User Approvals",
          content: apiDataLoaded ? (
            <UserApprovalsOrDenialTabView
              tabData={unapprovedTabData}
              tabDispatch={unapprovedTabDispatch}
            />
          ) : (
            <UserApprovalSkeletonView />
          ),
        },
        {
          label: "User Denials",
          content: apiDataLoaded ? (
            <UserApprovalsOrDenialTabView tabData={deniedTabData} tabDispatch={deniedTabDispatch} />
          ) : (
            <UserApprovalSkeletonView />
          ),
        },
        {
          label: "Approval/Denial History",
          content: apiDataLoaded ? (
            <UserHistoryTabView tabData={userHistoryTabData} />
          ) : (
            <UserApprovalSkeletonView />
          ),
        },
      ]}
      selectedTabIndex={selectedTabIndex}
      setSelectedTabIndex={(value) => {
        setSelectedTabIndex(value);
      }}
      componentBetweenTabsAndContent={
        <UserApprovalsToolBar
          currentTabData={currentTabStore.tabData}
          currentTabDispatch={currentTabStore.tabDispatch}
        />
      }
    />
  );
};

export default UserApprovalView;
