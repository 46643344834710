import { Alert, Box } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridInputRowSelectionModel,
  GridRowIdGetter,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import React from "react";
import { UserManagementCard } from "../../../../../../profile-sdk";
import useGridBreakpointHeight from "../../hooks/use_grid_breakpoint_height";

type Props = {
  getRowId?: GridRowIdGetter<UserManagementCard>;
  rows: UserManagementCard[];
  columns: readonly GridColDef<UserManagementCard>[];
  emptyRowsText: string;
  rowSelectionModel?: GridInputRowSelectionModel;
  onRowSelectionModelChange?: (rowSelectionModel: GridRowSelectionModel) => void;
};

const UserApprovalsTable = (props: Props) => {
  const gridHeight = useGridBreakpointHeight();

  return (
    <Box
      sx={{
        height: gridHeight,

        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "contrasts.1",
          borderBottom: "3px solid",
          borderBottomColor: "contrasts.2",
        },
      }}
    >
      <DataGrid
        getRowId={(row) => props?.getRowId?.(row) ?? crypto.randomUUID()}
        columns={props.columns}
        rows={props.rows}
        rowHeight={80}
        slots={{
          noRowsOverlay: NoRowsOverlay({
            text: props.emptyRowsText,
          }),
        }}
        sortingOrder={["asc", "desc"]}
        disableRowSelectionOnClick
        disableColumnSelector
        pagination
        paginationMode="client"
        initialState={{
          pagination: { paginationModel: { pageSize: 10, page: 0 } },
        }}
        checkboxSelection
        rowSelectionModel={props.rowSelectionModel}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          props.onRowSelectionModelChange?.(newRowSelectionModel);
        }}
        pageSizeOptions={[10, 20, 50, 100]}
      />
    </Box>
  );
};
const NoRowsOverlay = (props: { text: string }) => {
  return () => (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Alert severity="info" sx={{ width: "80%" }}>
        {props.text}
      </Alert>
    </Box>
  );
};
export default UserApprovalsTable;
