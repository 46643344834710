import { useEffect, useMemo, useState } from "react";
import { Alert, Box, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, Skeleton, SxProps, Typography } from "@mui/material";
import XNGInput from "../../../design/low-level/input";
import { getSizing } from "../../../design/sizing";
import usePalette from "../../../hooks/usePalette";
import { XNGIconRenderer, XNGICONS } from "../../../design/icons";
import DeprecatedBoxComponent from "../../../design/components-dev/BoxExtended";
import XNGDatePicker from "../../../design/low-level/calendar";
import { EditStudentFunctionType } from "../types";
import dayjs, { Dayjs } from "dayjs";
import {
  ConsentForMedicaidBilling,
  EligibilityRecord,
  PrescribedServiceArea,
  PrescribingProvider,
  ServiceAreaRef,
  StudentResponse,
} from "../../../profile-sdk";
import { XLogsPalette } from "../../../design/colors/types";
import { API_STATESNAPSHOTS } from "../../../api/api";
import { useXNGSelector } from "../../../context/store";
import { selectStateInUS } from "../../../context/slices/stateInUsSlice";
import XNGRadioGroup from "../../../design/low-level/radio_group";
import produce from "immer";
import { useStudentProfileContext } from "../context/context";
import usePrescribingProvider from "../../admin/views/prescibing_provider/hooks/usePrescribingProvider"; 
import useApiQueryGetServiceAreasByDate from "../../../api/hooks/state_snapshots/use_api_query_get_service_areas_by_date";
import { MSBICONS } from "../../../fortitude";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
const CONST_LARGE = "large";

/**
 * This React component 'Medicaid' is responsible for the medicaid tab of the student management screen, found in
 * the student tab. As with other student profile systems, it allows the user to modify student records in a larger
 * CRUD system.
 *
 * ## Team Decision Note 1/26/24
 * As part of our ongoing efforts to enhance the student profile's scalability and maintainability the team
 * has made several decisions that influence the development of the student profile screens going forward:
 * 1. We are transitioning to using Immer to enhance our complex state write operations.
 * 2. We are adopting a context-based approach for reading common state to enhance our read operations.
 * 3. Replacing deprecated Fortitude components with Material UI components in the instance of bug
 *    resolutions, as part of a larger progressive refactor.
 *
 * @remarks ---
 * * The subcomponent 'PrescribedServiceAreaItem' appears to handle individual service areas, showing dates and provider details.
 */
function Medicaid(props: {
  editStudent: EditStudentFunctionType;
  editedStudent: StudentResponse | null;
}) {
  const { setEditedStudent } = useStudentProfileContext();
  const allPrescribingProvidersUnderCurrentClientIdAndState = usePrescribingProvider()?.state?.data
  const state = useXNGSelector(selectStateInUS);
  const palette = usePalette();
  const radioSize = { "& .MuiSvgIcon-root": { fontSize: 20 } };
  const [select, setSelect] = useState<string>("");
  const [select2, setSelect2] = useState<string>("");

  const [personalCareSupplement, setPersonalCareSupplement] = useState<number>(
    props.editedStudent?.spedDossier?.personalCareSupplement === true
      ? 0
      : props.editedStudent?.spedDossier?.personalCareSupplement === false
      ? 1
      : 2,
  );
  const [personalCareServices, setPersonalCareServices] = useState<number>(
    props.editedStudent?.spedDossier?.prescribedServiceAreas?.[0]?.decision || 2,
  );
  const [consentDecision, setConsentDecision] = useState<number | undefined>(
    /**
     * This is a frontend workaround because the backend is currently always sending back
     * a sorted list of consent decision objects in which the first item is always an object with only null date values,
     * and the decision value as 3. So the second item, if it exists, is the most recent consent decision made and we use that here
     */
    props.editedStudent?.spedDossier?.consent?.[1].decision === 0 // passing 0 in a truthy evaluation results in false so we explicitly check here
      ? 0
      : !props.editedStudent?.spedDossier?.consent?.[1].decision // If the consent decision doesnt exist or hasnt been touched, we want to select 3 which corresponds to "None Selected" in the ConsentDecision enum
      ? 3
      : props.editedStudent?.spedDossier?.consent?.[1].decision, // The actual decision value if it exists
  );
  const [consentDate, setConsentDate] = useState<Dayjs | null>(null);
  const [startValue, setStartValue] = useState<Dayjs | null>(null);
  const [endValue, setEndValue] = useState<Dayjs | null>(null);
  const [specializedTransportation, setSpecializedTransportation] = useState<number>(2);
  const [personalCareOnBus, setPersonalCareOnBus] = useState<number>(2);
  const [speciallyAdjustedVehicle, setSpeciallyAdjustedVehicle] = useState<number>(
    props.editedStudent?.spedDossier?.speciallyAdjustedVehicle === true
      ? 0
      : props.editedStudent?.spedDossier?.speciallyAdjustedVehicle === false
      ? 1
      : 2,
  );
  const [blockBilling, setBlockBilling] = useState<number>(
    props.editedStudent?.spedDossier?.billingBlockTerm === null
      ? 2
      : props.editedStudent?.spedDossier?.billingBlockTerm?.blockBilling === true
      ? 0
      : 1,
  );
  const [billStartValue, setBillStartValue] = useState<Dayjs | null>(
    props.editedStudent?.spedDossier?.billingBlockTerm
      ? dayjs(props.editedStudent?.spedDossier?.billingBlockTerm?.blockBillingStartDate)
      : null,
  );

  const [billEndValue, setBillEndValue] = useState<Dayjs | null>(
    props.editedStudent?.spedDossier?.billingBlockTerm
      ? dayjs(props.editedStudent?.spedDossier?.billingBlockTerm?.blockBillingEndDate)
      : null,
  );
  const careAreas = [
    "Personal Care Services",
    "Personal Care Supplement",
    "Specialized Transportation Services",
    "Personal Care on Bus Services",
    "Specially Adapted Vehicle",
  ];

  const radioChoices: {
    [key: string]: boolean | null;
  } = {
    "0": true,
    "1": false,
    "2": null,
  };

  const consentChoices: {
    [key: number]: string;
  } = {
    0: "yes",
    1: "no",
    2: "refuse",
    3: "none",
  };

  const todaysDate = {
    day: dayjs().day(),
    month: dayjs().month(),
    year: dayjs().year(),
  }
  const memoizedDate = useMemo(() => {
    return dayjs().toDate();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [todaysDate.day, todaysDate.month, todaysDate.year]);

  const {
    data: serviceAreasResponse,
    isFetching: serviceAreasRespnonseIsFetching,
    refetch: refetchServiceAreasRespnonse,
    isError: serviceAreasRespnonseIsError,
    isSuccess: serviceAreasRespnonseIsSuccess,
    isLoading: serviceAreasRespnonseIsLoading,
  } = useApiQueryGetServiceAreasByDate({
    queryParams: {
      state: state,
      date: memoizedDate,
      filterForMedicaidInfoOnly: true,
    },
  });
  const studentPrescribedServiceAreasMap = useMemo(() => {
    const mapSet = new Map<string, PrescribedServiceArea>();
    props.editedStudent?.spedDossier?.prescribedServiceAreas?.forEach((prescribedServiceArea) => {
      mapSet.set(prescribedServiceArea.serviceArea?.id!, prescribedServiceArea);
    });

    return mapSet;
  }, [props.editedStudent?.spedDossier?.prescribedServiceAreas])
  const {Refresh, LoadingAnimation} = MSBICONS;
 
  const onHandleConsentChoiceChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const values: {
      [key: string]: number | undefined;
    } = {
      yes: 0,
      no: 1,
      refuse: 2,
      none: 3,
    };

    const choice = values[e.target.value];
    setConsentDecision(choice);

    const new_edited_student = produce(props.editedStudent, (draft) => {
      if (draft && draft.spedDossier && draft.spedDossier.consent) {
        if (!consentDate) {
          setConsentDate(dayjs());
          draft.spedDossier.consent[0].startDate = dayjs().toDate();
        }
        draft.spedDossier.consent[0].decision = choice;
      }
    });
    setEditedStudent(new_edited_student);
  };

  const onHandleConsentDateChange = (e: Dayjs | null) => {
    const new_edited_student = produce(props.editedStudent, (draft) => {
      if (draft && draft.spedDossier && draft.spedDossier.consent) {
        draft.spedDossier.consent[0].startDate = dayjs(e).toDate();
      }
    });
    setEditedStudent(new_edited_student);
  };

  if (props.editedStudent?.spedDossier?.consent === null) {
    let createTemp = [] as ConsentForMedicaidBilling[];
    createTemp.push({} as ConsentForMedicaidBilling);
    props.editStudent("spedDossier.consent", createTemp);
  }

  const handleSelect = (e: any) => {
    setSelect(e.target.value);
  };

  const handleSelect2 = (e: any) => {
    setSelect2(e.target.value);
    // props.editStudent("spedDossier.prescribedServiceAreas.serviceArea.name", e.target.value)
  };

  const handlePersonalCareServices = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPersonalCareServices(parseInt(e.target.value));

    // THIS IMPLEMENTATION SEEMS TO MAKE NO SENSE, SO I WILL BE COMMMENTING THIS OUT AS A STOPGAP
    // if (props.editedStudent?.spedDossier?.prescribedServiceAreas) {
    //   props.editStudent("spedDossier.prescribedServiceAreas.0.decision", parseInt(e.target.value));
    // }
  };

  const handlePersonalCareSupplement = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPersonalCareSupplement(parseInt(e.target.value));
    props.editStudent("spedDossier.personalCareSupplement", radioChoices[e.target.value]);
  };

  const handleSpecializedTransportation = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setSpecializedTransportation(parseInt(e.target.value));
  };

  const handleTransportationBus = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPersonalCareOnBus(parseInt(e.target.value));
  };

  const handleSpeciallyAdjustedVehicle = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setSpeciallyAdjustedVehicle(parseInt(e.target.value));
    props.editStudent("spedDossier.speciallyAdjustedVehicle", radioChoices[e.target.value]);
  };

  const handleBilling = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setBlockBilling(parseInt(e.target.value));
    if (props.editedStudent?.spedDossier?.billingBlockTerm === null) {
      props.editStudent("spedDossier.billingBlockTerm", {
        blockBilling: radioChoices[e.target.value],
        blockBillingStartDate: billStartValue !== null ? billStartValue.toDate() : null,
        blockBillingEndDate: billEndValue !== null ? billEndValue.toDate() : null,
      });
      return;
    }
    props.editStudent("spedDossier.billingBlockTerm.blockBilling", radioChoices[e.target.value]);
  };

  const handleBillStartChange = (value: Dayjs | null) => {
    props.editStudent(
      "spedDossier.billingBlockTerm.blockBillingStartDate",
      value !== null ? value.toDate() : null,
    );
  };

  const handleBillEndChange = (value: Dayjs | null) => {
    props.editStudent(
      "spedDossier.billingBlockTerm.blockBillingEndDate",
      value !== null ? value.toDate() : null,
    );
  };


  // useEffect(() => {
  //   if (consentDecision != null) {
  //     props.editStudent("spedDossier.consent.0.startDate", dayjs(consentDate).toDate());
  //     props.editStudent("spedDossier.consent.0.decision", 0);
  //     console.log(props.editedStudent?.spedDossier?.consent);
  //     // setRefuseDate(null);
  //   }
  // }, [consentDecision]);

  // useEffect(() => {
  //   if (refuseDate != null) {
  //     console.log(refuseDate);
  //     props.editStudent("spedDossier.consent.0.startDate", dayjs(refuseDate).toDate());
  //     props.editStudent("spedDossier.consent.0.decision", 2);
  //     setConsentDate(null);
  //   }
  // }, [refuseDate]);



  useEffect(() => {
    props.editedStudent?.spedDossier?.prescribedServiceAreas?.forEach((prescribedServiceArea) => {
      if (prescribedServiceArea.serviceArea?.name?.includes("Personal Care on Bus")) {
        setPersonalCareOnBus(prescribedServiceArea.decision as number);
      }

      if (prescribedServiceArea.serviceArea?.name?.includes("Specialized Transportation")) {
        setSpecializedTransportation(prescribedServiceArea.decision as number);
      }
    });
  }, []);

  const { handleEligibility } = useEligibilityDataOperations();

  return (
    <DeprecatedBoxComponent
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: getSizing(2),
        paddingBottom: getSizing(2),
      }}
    >
      <DeprecatedBoxComponent sx={{ display: "flex", gap: getSizing(2) }}>
        <Typography variant="h5">Eligibility</Typography>
        <XNGIconRenderer i={<XNGICONS.Files />} size={"md"} color={palette.primary[2]} />
      </DeprecatedBoxComponent>
      <DeprecatedBoxComponent
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: getSizing(3),
          paddingTop: getSizing(2),
        }}
      >
        <DeprecatedBoxComponent sx={{ display: "flex", gap: getSizing(2) }}>
          <DeprecatedBoxComponent>
            <FormControl>
              <RadioGroup
                aria-labelledby="ElegibilityYN"
                name="ElegibilityYN"
                value={
                  props.editedStudent?.spedDossier?.eligibilityRecords &&
                  props.editedStudent.spedDossier.eligibilityRecords[0]?.status
                }
                onChange={handleEligibility}
                sx={{ display: "flex", flexDirection: "row", gap: getSizing(2) }}
              >
                <FormControlLabel
                  value="0"
                  control={<Radio sx={radioSize} />}
                  label={<Typography variant="body2">Yes</Typography>}
                />
                <FormControlLabel
                  value="1"
                  control={<Radio sx={radioSize} />}
                  label={<Typography variant="body2">No</Typography>}
                />
              </RadioGroup>
            </FormControl>
          </DeprecatedBoxComponent>
        </DeprecatedBoxComponent>
        <DeprecatedBoxComponent sx={{ display: "flex" }}>
          <XNGInput
            size={CONST_LARGE}
            placeholder="Medicaid Number"
            onBlur={(e) => {
              props.editStudent("medicaidId", e.target.value);
            }}
            defaultValue={props.editedStudent?.medicaidId}
            label="Medicaid Number"
          />
        </DeprecatedBoxComponent>
      </DeprecatedBoxComponent>

      <DeprecatedBoxComponent
        sx={{ width: "100%", bgcolor: palette.contrasts[3], height: "1px", marginY: getSizing(2) }}
      />

      <DeprecatedBoxComponent sx={{ display: "flex", gap: getSizing(2) }}>
        <Typography variant="h5">Parental Consent</Typography>
        <XNGIconRenderer i={<XNGICONS.Files />} size={"md"} color={palette.primary[2]} />
      </DeprecatedBoxComponent>
      <DeprecatedBoxComponent
        sx={{ display: "flex", flexDirection: "column", gap: getSizing(2), alignItems: "left" }}
      >
        <Typography variant="body1">Parent / Guardian Consent Signed</Typography>
        <XNGRadioGroup
          value={consentDecision !== undefined && consentChoices[consentDecision]}
          onChange={onHandleConsentChoiceChange}
          sx={{ flexDirection: "row" }}
          options={["Yes", "No", "Refuse", "None Selected"]}
          values={["yes", "no", "refuse", "none"]}
          radioSx={{}}
          formLabel={undefined}
        />
        <XNGDatePicker
          label="Consent Date"
          title="Consent Date"
          setValue={setConsentDate}
          onChange={onHandleConsentDateChange}
          defaultValue={
            consentDate ||
            (props.editedStudent?.spedDossier?.consent![1].decision === 3
              ? null
              : dayjs(props.editedStudent?.spedDossier?.consent![1].startDate))
          }
        />
      </DeprecatedBoxComponent>

      <DeprecatedBoxComponent
        sx={{ width: "100%", bgcolor: palette.contrasts[3], height: "1px", marginY: getSizing(2) }}
      />

      <Typography variant="h5">Prescriptions</Typography>

      <Alert
        severity="error"
        sx={{ width: "80%", display: serviceAreasRespnonseIsError ? "flex" : "none" }}
        action={
          <IconButton size="small" title="Refresh" disabled={serviceAreasRespnonseIsFetching} onClick={()=> refetchServiceAreasRespnonse()}>
            {serviceAreasRespnonseIsFetching && <LoadingAnimation width={"20px"} height={"20px"} />}
            {!serviceAreasRespnonseIsFetching && <Refresh width={"15px"} height={"15px"} />}
          </IconButton>
        }
      >
        Failed to fetch service areas. Please try again.
      </Alert>
      {serviceAreasRespnonseIsSuccess && serviceAreasResponse?.serviceAreas?.map((prescribedServiceAreaOption) => {
        const prescribedServiceArea = studentPrescribedServiceAreasMap.get(
          prescribedServiceAreaOption?.id ?? "",
        );

        return (
          <PrescribedServiceAreaItem
            key={prescribedServiceAreaOption.id}
            serviceArea={prescribedServiceAreaOption}
            decision={prescribedServiceArea?.decision ?? null}
            decisionOptions={["Yes", "No", "None Selected"]}
            startDate={
              prescribedServiceArea?.startDate ? dayjs(prescribedServiceArea.startDate) : null
            }
            endDate={prescribedServiceArea?.endDate ? dayjs(prescribedServiceArea.endDate) : null}
            prescribingServiceProvider={prescribedServiceArea?.prescribingProvider ?? null}
            prescribingServiceProviderOptions={allPrescribingProvidersUnderCurrentClientIdAndState}
            onChangeDecision={(v) => {
              const newEditedStudent = produce(props.editedStudent, (draft) => {
                if (draft?.spedDossier?.prescribedServiceAreas) {
                  const serviceAreaIndex = draft.spedDossier.prescribedServiceAreas.findIndex(
                    (psa) => psa.serviceArea?.id === prescribedServiceAreaOption.id,
                  );
                  if (serviceAreaIndex !== -1) {
                    draft.spedDossier.prescribedServiceAreas[serviceAreaIndex].decision = v;
                  } else {
                    draft.spedDossier.prescribedServiceAreas.push({
                      serviceArea: prescribedServiceAreaOption,
                      prescribingProvider: undefined,
                      decision: v,
                      endDate: undefined,
                      startDate: undefined,
                    });
                  }
                }
              });
              setEditedStudent(newEditedStudent);
            }}
            onChangePrescribingServiceProvider={(v) => {
              const newEditedStudent = produce(props.editedStudent, (draft) => {
                if (draft?.spedDossier?.prescribedServiceAreas) {
                  const serviceAreaIndex = draft.spedDossier.prescribedServiceAreas.findIndex(
                    (psa) => psa.serviceArea?.id === prescribedServiceAreaOption.id,
                  );
                  if (serviceAreaIndex !== -1) {
                    draft.spedDossier.prescribedServiceAreas[serviceAreaIndex].prescribingProvider =
                      v;
                  } else {
                    draft.spedDossier.prescribedServiceAreas.push({
                      serviceArea: prescribedServiceAreaOption,
                      prescribingProvider: {
                        id: v.id,
                        firstName: v?.firstName,
                        lastName: v?.lastName,
                        middleName: v?.middleName,
                        npi: v?.npi,
                        stateMedicaidNumber: v?.stateMedicaidNumber,
                      },
                      decision: undefined,
                      endDate: undefined,
                      startDate: undefined,
                    });
                  }
                }
              });
              setEditedStudent(newEditedStudent);
            }}
            onChangeStartDate={(v) => {
              const newEditedStudent = produce(props.editedStudent, (draft) => {
                if (draft?.spedDossier?.prescribedServiceAreas) {
                  const serviceAreaIndex = draft.spedDossier.prescribedServiceAreas.findIndex(
                    (psa) => psa.serviceArea?.id === prescribedServiceAreaOption.id,
                  );
                  if (serviceAreaIndex !== -1) {
                    draft.spedDossier.prescribedServiceAreas[serviceAreaIndex].startDate =
                      v.toDate();
                  } else {
                    draft.spedDossier.prescribedServiceAreas.push({
                      serviceArea: prescribedServiceAreaOption,
                      prescribingProvider: undefined,
                      decision: undefined,
                      endDate: undefined,
                      startDate: v.toDate(),
                    });
                  }
                }
              });
              setEditedStudent(newEditedStudent);
            }}
            onChangeEndDate={(v) => {
              const newEditedStudent = produce(props.editedStudent, (draft) => {
                if (draft?.spedDossier?.prescribedServiceAreas) {
                  const serviceAreaIndex = draft.spedDossier.prescribedServiceAreas.findIndex(
                    (psa) => psa.serviceArea?.id === prescribedServiceAreaOption.id,
                  );
                  if (serviceAreaIndex !== -1) {
                    draft.spedDossier.prescribedServiceAreas[serviceAreaIndex].endDate = v.toDate();
                  } else {
                    draft.spedDossier.prescribedServiceAreas.push({
                      serviceArea: prescribedServiceAreaOption,
                      prescribingProvider: undefined,
                      decision: undefined,
                      endDate: v.toDate(),
                      startDate: undefined,
                    });
                  }
                }
              });

              setEditedStudent(newEditedStudent);
            }}
          />
        );
      })}
      {serviceAreasRespnonseIsLoading && Array.from({ length: 3 }).map((_, index) => (<PrescribedServiceAreaItemSkeleton key={index + index}/>))}
      <DeprecatedBoxComponent
        sx={{ width: "100%", bgcolor: palette.contrasts[3], height: "1px", marginY: getSizing(2) }}
      />

      <Typography variant="h5">Personal Care Services</Typography>
      <Typography variant="body1">
        Does {props.editedStudent?.firstName} have Personal Care Services ordered in the IEP?
      </Typography>
      <DeprecatedBoxComponent
        sx={{
          display: "flex",
          columnGap: getSizing(3),
        }}
      >
        <DeprecatedBoxComponent>
          <FormControl>
            <RadioGroup
              aria-labelledby="PersonalCareServices"
              name="PersonalCareServices"
              value={personalCareServices}
              onChange={handlePersonalCareServices}
              sx={{ display: "flex", flexDirection: "row", gap: getSizing(2) }}
            >
              <FormControlLabel
                value="0"
                control={<Radio sx={radioSize} />}
                label={<Typography variant="body2">Yes</Typography>}
              />
              <FormControlLabel
                value="1"
                control={<Radio sx={radioSize} />}
                label={<Typography variant="body2">No</Typography>}
              />
              <FormControlLabel
                value="2"
                control={<Radio sx={radioSize} />}
                label={<Typography variant="body2">None Selected</Typography>}
              />
            </RadioGroup>
          </FormControl>
        </DeprecatedBoxComponent>
      </DeprecatedBoxComponent>
      <Typography variant="body1">
        Does {props.editedStudent?.firstName} have Personal Care Supplement?
      </Typography>
      <DeprecatedBoxComponent
        sx={{
          display: "flex",
          columnGap: getSizing(3),
        }}
      >
        <DeprecatedBoxComponent>
          <FormControl>
            <RadioGroup
              aria-labelledby="PersonalCareSupplement"
              name="PersonalCareSupplement"
              value={personalCareSupplement}
              onChange={handlePersonalCareSupplement}
              sx={{ display: "flex", flexDirection: "row", gap: getSizing(2) }}
            >
              <FormControlLabel
                value="0"
                control={<Radio sx={radioSize} />}
                label={<Typography variant="body2">Yes</Typography>}
              />
              <FormControlLabel
                value="1"
                control={<Radio sx={radioSize} />}
                label={<Typography variant="body2">No</Typography>}
              />
              <FormControlLabel
                value="2"
                control={<Radio sx={radioSize} />}
                label={<Typography variant="body2">None Selected</Typography>}
              />
            </RadioGroup>
          </FormControl>
        </DeprecatedBoxComponent>
      </DeprecatedBoxComponent>

      <DeprecatedBoxComponent
        sx={{ width: "100%", bgcolor: palette.contrasts[3], height: "1px", marginY: getSizing(2) }}
      />

      <Typography variant="h5">Transportation Services</Typography>
      <Typography variant="body1">
        Does {props.editedStudent?.firstName} have Specialized Transportation Services ordered in
        the IEP?
      </Typography>
      <DeprecatedBoxComponent
        sx={{
          display: "flex",
          columnGap: getSizing(3),
        }}
      >
        <DeprecatedBoxComponent>
          <FormControl>
            <RadioGroup
              aria-labelledby="SpecializedTransportationServices"
              name="SpecializedTransportationServices"
              value={specializedTransportation}
              onChange={handleSpecializedTransportation}
              sx={{ display: "flex", flexDirection: "row", gap: getSizing(2) }}
            >
              <FormControlLabel
                value="0"
                control={<Radio sx={radioSize} disabled={specializedTransportation !== 0} />}
                label={<Typography variant="body2">Yes</Typography>}
              />
              <FormControlLabel
                value="1"
                control={<Radio sx={radioSize} disabled={specializedTransportation !== 1} />}
                label={<Typography variant="body2">No</Typography>}
              />
              <FormControlLabel
                value="2"
                control={<Radio sx={radioSize} disabled={specializedTransportation !== 2} />}
                label={<Typography variant="body2">None Selected</Typography>}
              />
            </RadioGroup>
          </FormControl>
        </DeprecatedBoxComponent>
      </DeprecatedBoxComponent>
      <Typography variant="body1">
        Does {props.editedStudent?.firstName} have Personal Care Services ordered on the bus?
      </Typography>
      <DeprecatedBoxComponent
        sx={{
          display: "flex",
          columnGap: getSizing(3),
        }}
      >
        <DeprecatedBoxComponent>
          <FormControl>
            <RadioGroup
              aria-labelledby="SpecializedTransportationServicesBus"
              name="SpecializedTransportationServicesBus"
              value={personalCareOnBus}
              onChange={handleTransportationBus}
              sx={{ display: "flex", flexDirection: "row", gap: getSizing(2) }}
            >
              <FormControlLabel
                value="0"
                control={<Radio sx={radioSize} disabled={personalCareOnBus !== 0} />}
                label={<Typography variant="body2">Yes</Typography>}
              />
              <FormControlLabel
                value="1"
                control={<Radio sx={radioSize} disabled={personalCareOnBus !== 1} />}
                label={<Typography variant="body2">No</Typography>}
              />
              <FormControlLabel
                value="2"
                control={<Radio sx={radioSize} disabled={personalCareOnBus !== 2} />}
                label={<Typography variant="body2">None Selected</Typography>}
              />
            </RadioGroup>
          </FormControl>
        </DeprecatedBoxComponent>
      </DeprecatedBoxComponent>
      <Typography variant="body1">
        Does {props.editedStudent?.firstName} require specially adjusted vehicle?
      </Typography>
      <DeprecatedBoxComponent
        sx={{
          display: "flex",
          columnGap: getSizing(3),
        }}
      >
        <DeprecatedBoxComponent>
          <FormControl>
            <RadioGroup
              aria-labelledby="SpecializedTransportationServicesAuto"
              name="SpecializedTransportationServicesAuto"
              value={speciallyAdjustedVehicle}
              onChange={handleSpeciallyAdjustedVehicle}
              sx={{ display: "flex", flexDirection: "row", gap: getSizing(2) }}
            >
              <FormControlLabel
                value="0"
                control={<Radio sx={radioSize} />}
                label={<Typography variant="body2">Yes</Typography>}
              />
              <FormControlLabel
                value="1"
                control={<Radio sx={radioSize} />}
                label={<Typography variant="body2">No</Typography>}
              />
              <FormControlLabel
                value="2"
                control={<Radio sx={radioSize} />}
                label={<Typography variant="body2">None Selected</Typography>}
              />
            </RadioGroup>
          </FormControl>
        </DeprecatedBoxComponent>
      </DeprecatedBoxComponent>

      <DeprecatedBoxComponent
        sx={{ width: "100%", bgcolor: palette.contrasts[3], height: "1px", marginY: getSizing(2) }}
      />

      <Typography variant="h5">
        Medicaid Billing Dates{" "}
        <Typography variant="body1" display={"inline"}>
          (Billing Override)
        </Typography>
      </Typography>
      <Typography variant="body1">
        Would you like to block billing for {props.editedStudent?.firstName}?
      </Typography>
      <DeprecatedBoxComponent
        sx={{
          display: "flex",
          columnGap: getSizing(3),
        }}
      >
        <DeprecatedBoxComponent>
          <FormControl>
            <RadioGroup
              aria-labelledby="BlockBilling"
              name="BlockBilling"
              value={blockBilling}
              onChange={handleBilling}
              sx={{ display: "flex", flexDirection: "row", gap: getSizing(2) }}
            >
              <FormControlLabel
                value="0"
                control={<Radio sx={radioSize} />}
                label={<Typography variant="body2">Yes</Typography>}
              />
              <FormControlLabel
                value="1"
                control={<Radio sx={radioSize} />}
                label={<Typography variant="body2">No</Typography>}
              />
              <FormControlLabel
                value="2"
                control={<Radio sx={radioSize} />}
                label={<Typography variant="body2">None Selected</Typography>}
              />
            </RadioGroup>
          </FormControl>
        </DeprecatedBoxComponent>
      </DeprecatedBoxComponent>
      <Typography variant="body1">Start Medicaid Billing Date:</Typography>
      <DeprecatedBoxComponent
        sx={{
          display: "flex",
          columnGap: getSizing(3),
        }}
      >
        <XNGDatePicker
          title="Start Date"
          setValue={setBillStartValue}
          defaultValue={billStartValue || null}
          onChange={handleBillStartChange}
        />
      </DeprecatedBoxComponent>
      <Typography variant="body1">Stop Medicaid Billing Date</Typography>
      <DeprecatedBoxComponent
        sx={{
          display: "flex",
          columnGap: getSizing(3),
        }}
      >
        <XNGDatePicker
          title="End Date"
          setValue={setBillEndValue}
          defaultValue={billEndValue || null}
          onChange={handleBillEndChange}
        />
      </DeprecatedBoxComponent>
    </DeprecatedBoxComponent>
  );
}

function PrescribedServiceAreaItem(
  props: Readonly<{
    serviceArea: ServiceAreaRef;
    startDate: Dayjs | null;
    endDate: Dayjs | null;
    prescribingServiceProvider: PrescribingProvider | null;
    prescribingServiceProviderOptions?: PrescribingProvider[];
    decision: number | null;
    decisionOptions: ["Yes", "No", "None Selected"];
    onChangePrescribingServiceProvider?: (v: PrescribingProvider) => void;
    onChangeStartDate?: (v: Dayjs) => void;
    onChangeEndDate?: (v: Dayjs) => void;
    onChangeDecision?: (v: number) => void;
  }>,
) {
  const inputSectionPairStyling:SxProps = {
    gap: "5px",
    mb: 2,
    display: "flex",
    flexDirection: {
      flexDirection: "column",
      sm: "row",
    },
  };
  
  //this is here because some of the schemas in the options are not consistent with the schema in the prescribingServiceProvider
  //found in the student profile, so we need to map the input value  the options to the schema in the student profile
  //to avoid rendering issues
  const prescribingServiceProviderOptionsMapSet = useMemo(() => {
    const mapSet = new Map<string, PrescribingProvider>();
    props.prescribingServiceProviderOptions?.forEach((prescribingProvider) => {
      mapSet.set(prescribingProvider.id!, prescribingProvider);
    });
    return mapSet;
  }, [props.prescribingServiceProviderOptions]);
  return (
    <Box mb={2} maxWidth={"800px"}>
      <Typography
        variant="h3"
        fontSize={"18px"}
        mb={"12px"}
        sx={{
          width: "max-content",
          pb: "1px",
          borderBottom: "2px solid",
          borderColor: "contrasts.3",
        }}
      >
        {props.serviceArea.name}
      </Typography>
      <Box>
        <Box sx={inputSectionPairStyling}>
          <FormControl fullWidth>
            <DatePicker
              value={props.startDate}
              onChange={(v) => v && props.onChangeStartDate?.(v)}
              slotProps={{
                textField: {
                  size: "small",
                  label: "Start Date",
                },
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <DatePicker
              value={props.endDate}
              onChange={(v) => v && props.onChangeEndDate?.(v)}
              slotProps={{
                textField: {
                  size: "small",
                  label: "End Date",
                },
              }}
            />
          </FormControl>
        </Box>
        <Box sx={inputSectionPairStyling}>
          <FormControl fullWidth>
            <InputLabel id="prescribing-provider" size="small">
              Prescribing Provider
            </InputLabel>
            <Select
              id="prescribing-provider"
              value={props.prescribingServiceProvider ? JSON.stringify(prescribingServiceProviderOptionsMapSet.get(props.prescribingServiceProvider.id!)) : ""}
              label="Prescribing Provider"
              onChange={(e) =>
                e.target.value && props?.onChangePrescribingServiceProvider?.(
                  JSON.parse(e.target.value) as PrescribingProvider,
                )
              }
              size="small"
              fullWidth
            >
              {props.prescribingServiceProviderOptions?.map((provider) => (
                <MenuItem key={provider.id} value={JSON.stringify(provider)}>
                  {provider.firstName} {provider.lastName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="prescribing-decision" size="small">
              Prescription Decision
            </InputLabel>
            <Select
              id="prescribing-decision"
              value={props.decision}
              label="Prescription Decision"
              onChange={(e) => props?.onChangeDecision?.(Number(e.target.value))}
              size="small"
            >
              {props.decisionOptions.map((decision, i) => (
                <MenuItem key={decision} value={i}>
                  {decision}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={inputSectionPairStyling}>
          <Typography
            sx={{
              alignSelf: {
                alignSelf: "start",
                sm: "center",
              },
            }}
          >
            NPI: {props.prescribingServiceProvider?.npi ?? "N/A"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
function PrescribedServiceAreaItemSkeleton() {
  const inputSectionPairStyling:SxProps = {
    gap: "5px",
    mb: 2,
    display: "flex",
    flexDirection: {
      flexDirection: "column",
      sm: "row",
    },
  };

  const skeletonHeight = "40px";
  return (
    <Box mb={2} maxWidth={"800px"}>
      <Skeleton variant="text" width={"100%"} height={skeletonHeight} sx={{maxWidth:"300px"}}/>
      <Box>
        <Box sx={inputSectionPairStyling}>
        <Skeleton variant="text" width={"100%"} height={skeletonHeight} />
        <Skeleton variant="text" width={"100%"} height={skeletonHeight} />
        </Box>
        <Box sx={inputSectionPairStyling}>
        <Skeleton variant="text" width={"100%"} height={skeletonHeight} />
        <Skeleton variant="text" width={"100%"} height={skeletonHeight} />
        </Box>
        <Box sx={inputSectionPairStyling}>
        <Skeleton variant="text" width={"100%"} height={skeletonHeight} sx={{maxWidth: "250px"}}/>
        </Box>
      </Box>
    </Box>
  );
}

export default Medicaid;

function useEligibilityDataOperations() {
  const { editedStudent, setEditedStudent } = useStudentProfileContext();

  const initializeEligibilityRecords = (draft: StudentResponse | null) => {
    if (!draft?.spedDossier?.eligibilityRecords) {
      if (!draft?.spedDossier) return;

      draft.spedDossier.eligibilityRecords = [{} as EligibilityRecord];
    }
  };

  const updateEligibilityStatus = (draft: StudentResponse | null, status: number) => {
    if (draft?.spedDossier?.eligibilityRecords) {
      draft.spedDossier.eligibilityRecords[0].status = status;
    }
  };

  function handleEligibility(e: any) {
    const statusValue = parseInt(e.target.value);
    if (isNaN(statusValue)) return;

    setEditedStudent((s) =>
      produce(s, (draft) => {
        if (draft?.spedDossier) {
          initializeEligibilityRecords(draft);
          updateEligibilityStatus(draft, statusValue);
        }
      }),
    );
  }

  useEffect(() => {
    const eligibilityRecords = editedStudent?.spedDossier?.eligibilityRecords;
    if (!eligibilityRecords) return;
    if (eligibilityRecords.length === 0) return;

    const currentStatus = eligibilityRecords[0].status;
    const isStatusInvalid = currentStatus !== 0 && currentStatus !== 1;

    if (isStatusInvalid) {
      setEditedStudent((s) =>
        produce(s, (draft) => {
          if (draft?.spedDossier?.eligibilityRecords) {
            draft.spedDossier.eligibilityRecords[0].startDate = dayjs().toDate();
          }
        }),
      );
    }
  }, [editedStudent?.spedDossier?.eligibilityRecords, setEditedStudent]);

  return { handleEligibility };
}
