import { useCallback, useEffect, useMemo, useState } from "react";
import { Goal, GoalDisplay } from "../../../../profile-sdk";
import { useNotatorTools } from "../../tools";
import { useXNGSelector } from "../../../../context/store";
import { selectStateInUS } from "../../../../context/slices/stateInUsSlice";
import { API_STATESNAPSHOTS } from "../../../../api/api";
import { Typography, Button, Dialog, Box, Stack } from "@mui/material";
import XNGClose from "../../../../design/low-level/button_close";
import { GoalCard } from "../../components/GoalCard";
import toggleFromArray from "../../../../utils/getToggledArray";
import { produce } from "immer";
import { MSBScrollShadowViewport, MSBSearchSingleSelect } from "../../../../fortitude";
import toggleFromArrayByKey from "../../../../utils/toggle_from_array_by_key";

const CONSISTENT_GAP = "1rem";
const STATIC_CARD_VIEWPORT_HEIGHT = "calc(100vh - 20rem)";

export function EditGoalListModal(
  props: Readonly<{
    open: boolean;
    onClose: () => void;
    fetchedActiveGoals: GoalDisplay[] | null;
    selectedStudentIndex: number;
  }>,
) {
  const { selectedStudentIndex, open, onClose, fetchedActiveGoals } = props;
  const { draftSession, setDraftSession } = useNotatorTools();

  const goalInventory = useMemo(
    () => draftSession.studentJournalList![selectedStudentIndex].goalInventory!,
    [draftSession, selectedStudentIndex],
  );

  const [draftGoalInventory, setDraftGoalInventory] = useState<GoalDisplay[]>(goalInventory);

  useEffect(() => {
    setDraftGoalInventory(goalInventory);
  }, [props.open]);

  // New code
  const goalAreaOptions = useFetchedGoalAreaNamesForDropdown();
  const [selectedGoalAreaOption, setSelectedGoalAreaOption] = useState<string>("All");

  const filteredGoals = useFilteredGoals({
    goalDisplays: fetchedActiveGoals ?? [],
    selectedGoalAreaOfFocus: selectedGoalAreaOption,
  });

  const handleUpdateDraftSession = useCallback(() => {
    // draftSession.studentJournalList![selectedStudentIndex].goalInventory[0].;
    // draftGoalInventory[0].;

    setDraftSession(
      produce((draft) => {
        draft.studentJournalList![selectedStudentIndex].goalInventory = draftGoalInventory;
      }),
    );
  }, [setDraftSession, draftGoalInventory, selectedStudentIndex]);

  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth="xl">
      <Box sx={{ overflow: "hidden", p: CONSISTENT_GAP }}>
        <Stack gap={CONSISTENT_GAP} mb={CONSISTENT_GAP}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">Edit Goal List</Typography>
            <XNGClose onClick={() => onClose()} />
          </Box>

          <Typography variant="body1">
            You can edit which goals are visible within your session. Please select the goals you
            would like to appear in this session series.
          </Typography>

          {/* TODO: Replace this with an MSBSearchSingleSelect */}
          <MSBSearchSingleSelect<string>
            label="Area of Focus"
            selectedOption={selectedGoalAreaOption}
            options={["All", ...(goalAreaOptions ?? [])]}
            getOptionLabel={(v) => v}
            onChange={(v) => setSelectedGoalAreaOption(v ?? "All")}
            defaultOption="All"
          />
        </Stack>

        <MSBScrollShadowViewport
          maxHeight={STATIC_CARD_VIEWPORT_HEIGHT}
          sx={{
            display: "flex",
            gap: CONSISTENT_GAP,
            minHeight: STATIC_CARD_VIEWPORT_HEIGHT,
            overflowY: "auto",
            overflowX: "hidden",
            maxWidth: "78.5rem",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
          refreshDependencies={[filteredGoals.length]}
        >
          {filteredGoals.map((goal) => (
            <GoalCard
              key={goal.internalId}
              goal={goal}
              isChecked={
                draftGoalInventory.filter((g) => g.internalId === goal.internalId).length > 0
              }
              onClick={() => {
                setDraftGoalInventory(
                  toggleFromArrayByKey<Goal>(goal, [...draftGoalInventory], "internalId"),
                );
              }}
            />
          ))}
        </MSBScrollShadowViewport>

        <Box
          paddingY={CONSISTENT_GAP}
          mt={CONSISTENT_GAP}
          display={"flex"}
          justifyContent={{ justifyContent: "center", sm: "right" }}
          sx={{
            paddingRight: CONSISTENT_GAP,
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: { width: "100%", sm: "fit-content" },
              maxWidth: "295px",
            }}
            onClick={() => {
              handleUpdateDraftSession();
              props.onClose();
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

/**
 * TODO: Have this accept a Goal instead when we modify v1StudentsIdActiveGoalsGet to return a Goal instead
 */
// function goalDisplaysToGoalRef(displays: GoalDisplay[]): GoalRef[] {
// return displays.map((v) => ({"description": v.description, "goalNumberFromVendor": v.number, "internalId": null}));
// }

function useFilteredGoals(
  props: Readonly<{ selectedGoalAreaOfFocus: string | null; goalDisplays: GoalDisplay[] }>,
) {
  const { goalDisplays: goals } = props;
  const selectedGoalAreaOfFocus = props.selectedGoalAreaOfFocus ?? "All";

  const [filteredGoals, setFilteredGoals] = useState<GoalDisplay[]>(goals);
  useEffect(() => {
    if (selectedGoalAreaOfFocus === "All") {
      setFilteredGoals(goals);
    } else {
      const tempFilteredGoals = goals.filter(
        (g) =>
          g.goalAreaOfFocus?.name?.toLocaleLowerCase() ===
          selectedGoalAreaOfFocus.toLocaleLowerCase(),
      );
      setFilteredGoals(tempFilteredGoals);
    }
  }, [selectedGoalAreaOfFocus, goals]);

  return filteredGoals;
}

/**
 * This module was created from a previous implementation.
 *
 * TODO: Explore why we chose to fetch this. Could we use static name options?
 *
 * Or, shouldn't the service area already available in the goals we're iterating over? Why not use that?
 */
function useFetchedGoalAreaNamesForDropdown(): string[] | null {
  const stateInUS = useXNGSelector(selectStateInUS);

  const [goalAreaNamesForDropdown, setGoalAreaNamesForDropdown] = useState<string[]>([]);
  useEffect(() => {
    fetchAndSetGoalAreaNamesForDropdown();

    async function fetchAndSetGoalAreaNamesForDropdown() {
      const response = await API_STATESNAPSHOTS.v1StateSnapshotsGoalAreasOfFocusGet(stateInUS);
      const goalAreaOfFocusOptions = response.areasOfFocus ?? [];

      const goalAreaOfFocusNames = goalAreaOfFocusOptions
        .filter((aof) => aof !== undefined)
        .map((aof) => aof.name) as string[];

      setGoalAreaNamesForDropdown(goalAreaOfFocusNames);
    }
  }, []);

  return goalAreaNamesForDropdown;
}
