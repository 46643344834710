/* eslint-disable @typescript-eslint/no-explicit-any */
  
import { DataGrid, GridColDef, GridPagination, GridToolbarContainer } from '@mui/x-data-grid'; 
import './index.css'
import { Box, Checkbox, Link, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { commonColumnOptions, npiDataGridManager, NPIRowType } from '../../../utils/npi_data_grid_helper';
import usePrescribingProvider from '../../../hooks/usePrescribingProvider';
import { PrescribingProviderActionType } from '../../../context/prescribing_provider_actions';
import { PrescribingProviderRef } from '../../../../../../../profile-sdk';


interface NPISearchableDataGridProps{
   searchQuery:string
}

const SearchableDataGrid:React.FC<NPISearchableDataGridProps> = ({searchQuery}) => {
  const [isAllChecked,setAllChecked] = useState(false)
  const [rows,setRows] = useState<NPIRowType[]>([])
  const prescribingProviders = usePrescribingProvider()?.state?.data
  const columns = npiDataGridManager.mountNPIColumns().npiColumn
  const dispatch = usePrescribingProvider()?.dispatch

  useEffect(()=>{
    const rows = npiDataGridManager.mountNPIRows(prescribingProviders).npiRow
     if(rows){
       setRows(rows)
       setAllChecked(false)
     }
  },[prescribingProviders]) 

 

  useEffect(()=>{
    if(isAllChecked){
      const updatedRows = rows.map(row => ({...row, isEnabled: true}))
      setRows(updatedRows)
      const checkedPrescribingProviderRow = updatedRows.filter(row => row.isEnabled)
      const checkedPrescribingProvidersDetails:PrescribingProviderRef[] = []
      checkedPrescribingProviderRow.forEach((checkedPrescribingProviderRow,_i) => {        
        //get details of all checked prescribing providers
        const checkedPrescribingProviderDetails = npiDataGridManager.getPrescribingProviderByRowId(checkedPrescribingProviderRow.id)
        //set checked prescribing providers to state to perform delete
        if(checkedPrescribingProviderDetails){
           checkedPrescribingProvidersDetails.push(checkedPrescribingProviderDetails)
        }else{
          console.log("no prescribing data found on hashmap")
        }

      })
      dispatch({type: PrescribingProviderActionType.SET_PRESCRIBING_PROVIDER_TO_DELETE, payload: checkedPrescribingProvidersDetails})
    }else{
      setRows(prev => prev.map(item => ({...item, isEnabled: false})))
      dispatch({type: PrescribingProviderActionType.SET_PRESCRIBING_PROVIDER_TO_DELETE, payload: []})
    }
   },[isAllChecked])

  useEffect(()=>{
    if(searchQuery){ 
      //handle filtration here
      const found =  rows.filter(row => 
        npiDataGridManager.checkForMedicaIDMatch(row,searchQuery) || 
        npiDataGridManager.checkForNPIMatch(row,searchQuery) || 
        npiDataGridManager.checkForProviderFullNameMatch(row,searchQuery)
      )
      //udpate your state here 
      setRows(found)
    }
  },[rows, searchQuery])

  
 

 const handleRowClick = (row:any) => {
    //verify if all rows are selected
    const clickedRowId = row[0]
    const updatedRows = rows.map(row => {
      if(row.id === clickedRowId){
        return {...row, isEnabled: !row.isEnabled}
      }
      return row
    })
    setRows(updatedRows)
    const enabledAllPrescribingProviderRows = updatedRows.filter(row=>!row.isEnabled).length === 0
    if(enabledAllPrescribingProviderRows){
      setAllChecked(true)
    }else if(updatedRows.filter(row=>!row.isEnabled).length === updatedRows.length){
      setAllChecked(false)
    } 
   
   //track down the currently clicked row which is id[] where id is row id
   if(Array.isArray(row) && row.length === 1){ 
       const checkedPrescribingProviderRows = updatedRows.filter(row => row.isEnabled) 
       const checkedPrescribingProvidersDetails:PrescribingProviderRef[] = []
       checkedPrescribingProviderRows.forEach((checkedPrescribingProviderRow,_i) => {
               //get details of all checked prescribing providers
               const checkedPrescribingProviderDetails = npiDataGridManager.getPrescribingProviderByRowId(checkedPrescribingProviderRow.id)
               if(checkedPrescribingProviderDetails) {
                 checkedPrescribingProvidersDetails.push(checkedPrescribingProviderDetails) 
               }else{
                console.log("no prescribing data found on hashmap")
              }
              })
       dispatch({type: PrescribingProviderActionType.SET_PRESCRIBING_PROVIDER_TO_DELETE, payload: checkedPrescribingProvidersDetails})
      }

 }
  
 

 const hanldeLinkClick = (e:React.MouseEvent<HTMLAnchorElement, MouseEvent>,rowId:number) => {
  e.preventDefault() 
  const prescribingProviderToBeEdited = npiDataGridManager.getPrescribingProviderByRowId(rowId) 
  dispatch({type:PrescribingProviderActionType.SET_PRESCRIBING_PROVIDER,payload:prescribingProviderToBeEdited})
 }

   const linkColumn = {
    field: 'link',
    headerName: '', // No header
    minWidth: 150, 
    renderCell: (params:{row:{id:number}}) =>{ 
      return( 
          <a href='' onClick={(e)=>hanldeLinkClick(e,params.row.id as unknown as number)}  target="_blank" rel="noopener noreferrer">
            Edit
          </a> 
    )}, 
    ...commonColumnOptions,
    align: "center"
  }
    const customAllColumn:GridColDef<(typeof rows)[number]> = {
    field: "",
    headerName: "",
    minWidth: 200,
    ...commonColumnOptions,
    align: "left",
    renderCell: (params) =>{  
      return(
        <Checkbox checked={params?.row?.isEnabled}   />
      )}, 
      renderHeader: () => 
       <Box sx={{fontSize:"14px",display:"flex",alignItems:"center",justifyContent:"center",fontWeight:400}}>
          <Checkbox checked={isAllChecked} indeterminate={rows.filter(rows=>!rows.isEnabled).length > 0 && rows.filter(rows=>!rows.isEnabled).length < rows.length} onChange={(e)=>setAllChecked(e.target.checked)} />
           <span> All</span>
        </Box>
      
  }
 
  return ( 
    <>
   {rows && columns &&  <DataGrid
        rows={rows}
        columns={[customAllColumn,...columns as any,linkColumn]}
        onRowSelectionModelChange={handleRowClick}
        sx={{
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          height:"270px"
        }}  
      
        pageSizeOptions={[5, 10, 20, 50, 100]}
        // checkboxSelection 
        slots={{footer:()=><GridToolbarContainer sx={{display:"flex",alignItems:"center",justifyContent:"center",backgroundColor:"rgba(244, 244, 244, 1)"}}><Typography sx={{color:"gray",fontSize:"12px"}}>Showing {rows.length} of {rows.length} results </Typography> <GridPagination /></GridToolbarContainer>}}
      
      /> }

    </>
  );
}

export default SearchableDataGrid