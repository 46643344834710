import { Grade } from "../profile-sdk";
import { placeholderForFutureLogErrorText } from "../temp/errorText";

export type StudentGradeAsString =
  | "Pre-K"
  | "Kindergarten"
  | "1st"
  | "2nd"
  | "3rd"
  | "4th"
  | "5th"
  | "6th"
  | "7th"
  | "8th"
  | "9th"
  | "10th"
  | "11th"
  | "12th"
  | "Other";

export function studentProfileGradeToString(grade: Grade): StudentGradeAsString | null {
  switch (grade) {
    case Grade.NUMBER_0:
      return "Pre-K";
    case Grade.NUMBER_1:
      return "Kindergarten";
    case Grade.NUMBER_2:
      return "1st";
    case Grade.NUMBER_3:
      return "2nd";
    case Grade.NUMBER_4:
      return "3rd";
    case Grade.NUMBER_5:
      return "4th";
    case Grade.NUMBER_6:
      return "5th";
    case Grade.NUMBER_7:
      return "6th";
    case Grade.NUMBER_8:
      return "7th";
    case Grade.NUMBER_9:
      return "8th";
    case Grade.NUMBER_10:
      return "9th";
    case Grade.NUMBER_11:
      return "10th";
    case Grade.NUMBER_12:
      return "11th";
    case Grade.NUMBER_13:
      return "12th";
    case Grade.NUMBER_14:
      return "Other";
    default:
      console.error(placeholderForFutureLogErrorText);
      return null;
  }
}
