import React, { createContext, useMemo, useReducer } from "react";
import useUserManagementContext from "../../../hooks/context/use_user_management_context";
import useApiQueryStaffDirectory from "../../../../../api/hooks/service_provider/use_api_query_staff_directory";
import remapStaffDirectorySortableColumnTypeKeyToEnum from "../utils/remap_staff_directory_sortable_column_type_key_to_enum";
import staffDirectoryHomePageReducer, { staffDirectoryHomePageStateInit } from "./reducers/staff_directory_home_page_reducer";
import StaffDirectoryHomePageContextType from "../types/staff_directory_home_page_context_type";

const StaffDirectoryHomePageProvider = ({ children }: { children: React.ReactNode }) => {
  const clientId = useUserManagementContext().store.userManagementData.client?.id;
  const stateInUs = useUserManagementContext().store.userManagementData.stateInUs;
  const selectedDistricts =
    useUserManagementContext().store.userManagementData.authorizedDistrictsFilterData
      .selectedDistricts;

  const [state, dispatch] = useReducer(
    staffDirectoryHomePageReducer,
    undefined,
    //getStaffDirectoryStateInitFnArgs(loggedInClient.client, stateInUs, userResponse), //used to get the initial argument for the staff directory init function
    staffDirectoryHomePageStateInit,
  );

  const apiQueryStaffDirectory = useApiQueryStaffDirectory({
    queryParams: {
      clientId: clientId,
      state: stateInUs,
      pageNumber: state.tableData.pagination.currentPage,
      pageSize: state.tableData.pagination.rowsPerPage,
      sortBy: remapStaffDirectorySortableColumnTypeKeyToEnum(state.tableData.sort.column),
      sortDirection: state.tableData.sort.direction,
      showInactives: state.tableData.filter.showInactiveStaff,
      searchTerm: state.tableData.filter.searchedProvider,
      districtFilter: selectedDistricts.name === "All" ? undefined : selectedDistricts.id,
    },
  });

  const contextValue = useMemo(() => ({
    store: state,
    dispatch,
    apiQueryStaffDirectory,
  }), [apiQueryStaffDirectory, state]);
  return (
    <StaffDirectoryHomePageContext.Provider
      value={contextValue}
    >
      {children}
    </StaffDirectoryHomePageContext.Provider>
  );
};

export const StaffDirectoryHomePageContext =createContext<StaffDirectoryHomePageContextType>(
  {} as StaffDirectoryHomePageContextType,
);

export default StaffDirectoryHomePageProvider;
