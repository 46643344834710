import { ActionType, FinancialDashboardAction } from "../financial_dashboard_actions";
import { FinancialDashboardState } from "../provider/financialDashboardProvider";

 

export const financialDashboardReducer = (state: FinancialDashboardState, action: FinancialDashboardAction): FinancialDashboardState => {
    switch (action.type) {
        case ActionType.LOADING:
            return {
                ...state,
                loading: true,
            };
        case ActionType.FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case ActionType.IDLE:
            return {
                ...state,
                loading: false,
            };
        case ActionType.SET_FINANCIAL_DASHBOARD_REPORT:
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};