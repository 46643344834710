import { Box } from "@mui/system";
import { useMemo } from "react";

/**
 * Either select a preset value or use a custom number which will render in rem units.
 */
export type MSBIconSize = "xl" | "lg" | "md" | "sm" | "xs" | "caret" | number;

function getSize(sz: MSBIconSize) {
  switch (sz) {
    case "xl":
      return "3.4375rem";
    case "lg":
      return "1.875rem";
    case "md":
      return "1.375rem";
    case "sm":
      return "1.25rem";
    case "xs":
      return ".875rem";
    case "caret":
      return ".5rem";
    default:
      return sz;
  }
}

interface MSBIcon {
  color?: string;
  size: MSBIconSize;
  i: JSX.Element;
  onClick?: any;
  disableRenderer?: boolean;
  left?: boolean;
  up?: boolean;
  down?: boolean;
}

export default function MSBIconRenderer(props: Readonly<MSBIcon>) {
  const size = typeof props.size === "number" ? `${props.size}rem` : getSize(props.size);
  const dir = useMemo(() => {
    if (props.down) return 90;
    if (props.left) return 180;
    if (props.up) return 270;
    return 0;
  }, [props]);

  return (
    <Box
      sx={{
        display: "flex" + "!important",
        justifyContent: "center" + "!important",
        alignItems: "center" + "!important",
        minWidth: size + "!important",
        minHeight: size + "!important",
        transform: `rotate(${dir}deg)` + "!important",
        svg: {
          width: size + "!important",
          height: size + "!important",
          color: props.color,
          "*": props.disableRenderer
            ? {
                stroke: props.color + "!important",
              }
            : {
                stroke: props.color + "!important",
                strokeWidth: 0 + "!important",
                fill: props.color + "!important",
              },
        },
        maxWidth: size + "!important",
        maxHeight: size + "!important",
      }}
      onClick={props.onClick}
    >
      {props.i}
    </Box>
  );
}
