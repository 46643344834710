/* eslint-disable @typescript-eslint/no-explicit-any */
import { PrescribingProviderRef as  PrescribingProvider} from "../../../../../profile-sdk"
export enum NPIColumns { 
    PROVIDERFULLNAME = 'Provider Full Name',
    NPI = 'NPI',
    STATEMEDICARENUMBER = 'State Medicaid Number'
  }
  
export  const commonColumnOptions = {
    sortable: false,
    filterable: false,
    hideable: false,
    align: "left",
    disableColumnMenu: true, 
    resizable: false,
  }
  
  export  interface NPIColumnType{
     field:string,
     headerName:string,
     minWidth?:number,
     editable?:boolean,
     renderCell?:any
     renderHeader?:any
     align?:string
     sortable?:boolean
     filterable?:boolean
     hideable?:boolean
     disableColumnMenu?:boolean
     resizable?:boolean
     renderFooter?:any
     renderFooterCell?:any
  }
  
  export  interface NPIRowType {
    [NPIColumns.PROVIDERFULLNAME]: string;
    [NPIColumns.NPI]: string;
    [NPIColumns.STATEMEDICARENUMBER]: string;
    id: number;
    isEnabled:boolean;
  }
  
  export  class NPIDataGridManager{
    private  disabledCommonNpiGridColumnFeatures ={
      sortable: false,
      filterable: false,
      hideable: false,
      align: "left",
      disableColumnMenu: true, 
      resizable: false,
      editable:false
    }
     private  editGridColumnFeatures = {
      sortable: false,
      filterable: false,
      hideable: false,
      align: "center",
      disableColumnMenu: true, 
      resizable: false,
     }
    public npiColumn:NPIColumnType[]|undefined = undefined
    public npiRow:NPIRowType[]|undefined = undefined
    public prescribingProviderLookup = new Map<number,PrescribingProvider>()
    constructor(){
        
    }
  
    static getInstance():NPIDataGridManager{
      return new NPIDataGridManager()
    }
  
    public mountNPIColumns():this{
        this.npiColumn = [
          {
            field: NPIColumns.PROVIDERFULLNAME,
            headerName: NPIColumns.PROVIDERFULLNAME,
            minWidth: 200,
            editable: false,
  
          },
          {
            field:NPIColumns.NPI,
            headerName:NPIColumns.NPI,
            minWidth: 200,
            editable: false,
          },
          {
            field:NPIColumns.STATEMEDICARENUMBER,
            headerName:NPIColumns.STATEMEDICARENUMBER,
            minWidth: 200,
            editable: false,
          }
        ]
        this.npiColumn = this.npiColumn.map((item)=>({...item,...this.disabledCommonNpiGridColumnFeatures}))
        return this
    }
  
    public mountNPIRows(prescribingProviders:PrescribingProvider[]):this{
  
      // this.npiRow =  Array.from({length:10}).map((item,index)=>{
      //   const dummyRow = this.generateDummyNPIGridRowData(index+1)
      //   this.prescribingProviderLookup.set(index+1,
      //     {id:dummyRow.id as unknown as string,
      //       firstName:dummyRow[NPIColumns.PROVIDERFULLNAME],lastName:""})
      //   return dummyRow
      // }) 

      if(prescribingProviders && Array.isArray(prescribingProviders)){
        this.npiRow = prescribingProviders.map((item,index)=>{
          this.prescribingProviderLookup.set(index+1,item)
          return this.generateNPIGridRowData(index+1,item.firstName + ' ' + item.lastName,item.npi as string,item.stateMedicaidNumber as string)
        })
      }
        
      return this
    }

    public getPrescribingProviderByRowId(rowId:number):PrescribingProvider|undefined{ 
      return this.prescribingProviderLookup.get(rowId)
    }

    public generateNPIGridRowData(id:number,providerFullName:string,npi:string,stateMedicareNumber:string):NPIRowType{
      return ({
        id,
        [NPIColumns.PROVIDERFULLNAME]: providerFullName,
        [NPIColumns.NPI]: npi,
        [NPIColumns.STATEMEDICARENUMBER]: stateMedicareNumber,
        isEnabled:false
      })
    }
  
    public generateDummyNPIGridRowData(id:number):NPIRowType{
      return ({
        id,
        [NPIColumns.PROVIDERFULLNAME]: 'fdfd',
        [NPIColumns.NPI]: 'ff',
        [NPIColumns.STATEMEDICARENUMBER]: '11',
        isEnabled:false
      })
    }

    public checkForProviderFullNameMatch (row:NPIRowType,searchQuery:string):boolean{
        return row[NPIColumns.PROVIDERFULLNAME].toLowerCase().includes(searchQuery.toLowerCase())
    }
    public checkForNPIMatch(row:NPIRowType,searchQuery:string):boolean{
        return row[NPIColumns.NPI].toLowerCase().includes(searchQuery.toLowerCase())
    }
    public checkForMedicaIDMatch(row:NPIRowType,searchQuery:string):boolean{
        return row[NPIColumns.STATEMEDICARENUMBER].toLowerCase().includes(searchQuery.toLowerCase())
    }
  }
  
  export  const npiDataGridManager = NPIDataGridManager.getInstance()

  
