import { Stack, Typography } from "@mui/material";
import { MainMenuV1 } from "../../../../design/high-level/navbar/menus/_main_menu_components";
import { XNGIconRenderer } from "../../../../design/icons";
import usePalette from "../../../../hooks/usePalette";
import { useNavigate } from "react-router";
import { ROUTES_XLOGS } from "../../../../constants/URLs";
import { GetUnpostedSessionCountResponse } from "../../../../session-sdk";
import React from "react";
import { MSBIconRenderer, MSBICONS } from "../../../../fortitude";

type Props = {
  unpostedSessionsCount: GetUnpostedSessionCountResponse | undefined;
  onClose: () => void;
};

/**
 * Renders the content of the unposted sessions slide in the navigation menu.
 * @returns JSX element that displays the unposted sessions slide content.
 */
const UnpostedSessionsSlideContent = (props: Props) => {
  const palette = usePalette();
  const navigate = useNavigate();
  const { onClose } = props;
  const { AlarmClock, SmallCheck, LoadingAnimation } = MSBICONS;
  const unpostedSessionsCountObject = props.unpostedSessionsCount;
  const myUnpostedCount = props.unpostedSessionsCount?.myUnpostedCount;
  const assistantUnpostedCount = props.unpostedSessionsCount?.assistantUnpostedCount;
  const dataEntryUnpostedCount = props.unpostedSessionsCount?.dataEntryUnpostedCount;

  const getMainMenuTextColor = (count: number | undefined) => {
    if (count && count > 0) {
      return palette.danger[4];
    }
    return palette.primary[1];
  };

  const getMainMenuTextIcon = (count: number | undefined): React.ReactNode => {
    if (count && count > 0) {
      return <XNGIconRenderer size="lg" i={<AlarmClock />} color={palette.danger[4]} />;
    }
    return <XNGIconRenderer size="md" i={<SmallCheck />} color={palette.primary[1]} />;
  };

  return (
    <Stack>
      {/* Header */}
      <MainMenuV1.HeaderTypography>Unposted Sessions</MainMenuV1.HeaderTypography>

      {/* My Sessions */}
      <MainMenuV1.IconButton
        onClick={() => {
          navigate(ROUTES_XLOGS.unposted_sessions.mySessions);
          onClose();
        }}
        overline="MY SESSIONS"
        i={getMainMenuTextIcon(myUnpostedCount)}
      >
        <Typography
          variant="body1"
          sx={{
            textDecoration: "underline",
            fontWeight: 700,
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
          color={getMainMenuTextColor(myUnpostedCount)}
        >
          {!unpostedSessionsCountObject ? (
            <MSBIconRenderer i={<LoadingAnimation />} size="sm" />
          ) : (
            myUnpostedCount ?? 0
          )}{" "}
          Sessions due
        </Typography>
      </MainMenuV1.IconButton>

      {/* My Assistant Sessions */}
      <MainMenuV1.IconButton
        onClick={() => {
          navigate(ROUTES_XLOGS.unposted_sessions.assistantSessions);
          onClose();
        }}
        overline="MY ASSISTANT SESSIONS"
        i={getMainMenuTextIcon(assistantUnpostedCount)}
      >
        <Typography
          variant="body1"
          sx={{
            textDecoration: "underline",
            fontWeight: 700,
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
          color={getMainMenuTextColor(assistantUnpostedCount)}
        >
          {!unpostedSessionsCountObject ? (
            <MSBIconRenderer i={<LoadingAnimation />} size="sm" />
          ) : (
            assistantUnpostedCount ?? 0
          )}{" "}
          Sessions due
        </Typography>
      </MainMenuV1.IconButton>

      {/* My Data Entry Sessions */}
      <MainMenuV1.IconButton
        onClick={() => {
          navigate(ROUTES_XLOGS.unposted_sessions.decSessions);
          onClose();
        }}
        overline="MY DATA ENTRY SESSIONS"
        i={getMainMenuTextIcon(dataEntryUnpostedCount)}
      >
        <Typography
          variant="body1"
          sx={{
            textDecoration: "underline",
            fontWeight: 700,
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
          color={getMainMenuTextColor(dataEntryUnpostedCount)}
        >
          {!unpostedSessionsCountObject ? (
            <MSBIconRenderer i={<LoadingAnimation />} size="sm" />
          ) : (
            dataEntryUnpostedCount ?? 0
          )}{" "}
          Sessions due
        </Typography>
      </MainMenuV1.IconButton>
    </Stack>
  );
};

export default UnpostedSessionsSlideContent;
