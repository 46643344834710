import { FormControl, FormControlLabel, FormControlLabelProps, Switch } from "@mui/material";
import Box from "../components-dev/BoxExtended";
import usePalette from "../../hooks/usePalette";
import { Typography } from "@mui/material";
import { getSizing } from "../sizing";

interface IXNGToggle {
  value: boolean;
  onToggle: (e?: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  variant?: "on-primary" | "on-white" | undefined;
  formControlLabelProps?: Partial<FormControlLabelProps>;
}
function XNGToggle(props: IXNGToggle) {
  const palette = usePalette();

  const thumbColor_checked =
    props.variant === "on-primary" || props.variant === "on-white" ? "white" : "white";
  const trackColor_checked = props.variant === "on-primary" ? palette.primary[4] : "";
  const trackColor_unChecked = props.variant === "on-primary" ? palette.disabled : "";

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: getSizing(1), cursor: "pointer" }}>
      <Box
        sx={{
          // give pill shape
          "& .MuiSwitch-track": {
            borderRadius: 999,
            color: trackColor_unChecked || palette.contrasts[2],
            bgcolor: trackColor_unChecked || palette.contrasts[2],
            opacity: 1,
          },
          ".MuiSwitch-root": {
            padding: "7px",
            width: "56px",
            height: "36px",
          },
          "& .MuiSwitch-thumb": {
            width: "18px",
            height: "18px",
            bgcolor: thumbColor_checked,
          },
          "& .Mui-disabled": {
            opacity: 0.65,
          },
          // add color
          "& .Mui-checked": {
            color: thumbColor_checked || palette.primary[2],
          },
          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            bgcolor: trackColor_checked || palette.primary[3],
            opacity: 1,
          },
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={props.value}
              onChange={(e) => (props.onToggle ? props.onToggle(e) : {})}
            />
          }
          label={
            <Typography className="noselect" noWrap>
              {props.label}
            </Typography>
          }
          labelPlacement="start"
          {...props.formControlLabelProps}
        />
      </Box>
    </Box>
  );
}

export default XNGToggle;
